import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from '../Navbar.js';
import { ChevronRightIcon } from '@heroicons/react/16/solid';



function HeaderPost(args) {

  return (
    <>
      <div className="bg-gradient-to-r from-teal-200 to-teal-500">
        <div className="container">
          <Navbar user={args.user}/>
          <div className="px-6 py-8 sm:pt-12 pb-14 lg:px-8">
            <div className="mx-auto">
              <nav aria-label="Breadcrumb" className="flex justify-start">
                <ol role="list" className="flex items-center space-x-4 p-0 flex-wrap">
                  <li>
                    <div>
                      <Link to="/" className="text-gray-400 hover:text-gray-500">
                        Clotere
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                      <Link
                        to="/fiches-pratiques-notaire"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Fiches pratiques notaire
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />

                      {args.titre}
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl max-w-[80%]">
                {args.titre}
              </h1>
              <div className="flex flex-row gap-3 align-center">
              <p class="text-base text-gray-500 dark:text-gray-400">
                <img alt="Clotere" src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg" className="inline-block size-6 rounded-full mr-2"/>
                Auteur : Clotere</p>
              <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2024-11-04" title="4 novembre 2024">Mis à jour le 04 novembre 2024</time></p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default HeaderPost;