import React from "react";
import { Link } from "react-router-dom";



function BlocCtaVendeur() {
    return (
    <div className="relative bg-white py-16">
      <div aria-hidden="true" className="absolute inset-x-0 top-0 hidden h-1/2 bg-gray-50 lg:block" />
      <div className="mx-auto max-w-7xl bg-emerald-600 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
            <div aria-hidden="true" className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" />
            <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:p-0">
              <div className="aspect-h-6 aspect-w-10 sm:aspect-h-1 sm:aspect-w-2 lg:aspect-w-1 hidden sm:block">
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1485217988980-11786ced9454?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  className="rounded-3xl object-cover object-center shadow-2xl"
                />
              </div>
            </div>
          </div>

          <div className="relative bg-gradient-to-r from-teal-200 to-teal-500 lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
            <div aria-hidden="true" className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block">
              <svg
                fill="none"
                width={404}
                height={384}
                viewBox="0 0 404 384"
                aria-hidden="true"
                className="absolute bottom-full left-full -translate-x-2/3 translate-y-1/3 transform xl:bottom-auto xl:top-0 xl:translate-y-0"
              >
                <defs>
                  <pattern
                    x={0}
                    y={0}
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-indigo-500" />
                  </pattern>
                </defs>
                <rect fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" width={404} height={384} />
              </svg>
              <svg
                fill="none"
                width={404}
                height={304}
                viewBox="0 0 404 384"
                aria-hidden="true"
                className="absolute top-full -translate-x-1/3 -translate-y-1/3 transform xl:-translate-y-1/2"
              >
                <defs>
                  <pattern
                    x={0}
                    y={0}
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-indigo-500" />
                  </pattern>
                </defs>
                <rect fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" width={404} height={384} />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md space-y- px-6 py-5 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
              <h2 id="join-heading" className="text-3xl font-bold tracking-tight text-white">
              Vendez seul comme un pro
              </h2>
              <p className="text-lg text-white">
              Tous les outils dont vous avez besoin pour vendre votre bien immobilier seul ! Recevez votre offre d'achat certifiée par un notaire et constituer votre dossier de vente facilement pour signer plus vite ! 
              </p>
              <div className="flex flex-row gap-3 flex-wrap">
              <Link to="/vendeur/onboard" className="btn-primary  no-underline">
                Commencer mon dossier de vente
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default BlocCtaVendeur;