import React from "react";
import { Card} from "reactstrap";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';


export const CardS = styled(Card)`
border-radius: 20px;
border: 0;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:24px;
 }
 @media all and (max-width: 768px) {
    flex-direction:column!important;
    .card-title{
        font-size:18px;
     }
  }
`;


function CardClotereSidebar() {
    const navigate = useNavigate();
    
    return (
        <div className="relative bg-[#b4f6c7] rounded-3xl mt-6 p-2">
            <div aria-hidden="true" className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block">

                <svg
                    fill="none"
                    width={200}
                    height={150}
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                    className="absolute top-full -translate-x-1/3 -translate-y-1/3 transform xl:-translate-y-1/2"
                >
                    <defs>
                        <pattern
                            x={0}
                            y={0}
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-indigo-500" />
                        </pattern>
                    </defs>
                    <rect fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" width={404} height={384} />
                </svg>
            </div>
            <div className="relative mx-auto p-4">
              <h4 className="font-bold tracking-tight text-black mb-1">
              Débutez simplement votre démarche chez le notaire
              </h4>
              <p className="text-sm pt-2">
              Constituez et suivez simplement votre dossier notaire avec Clotere.
              </p>
              <Link to="/faire-une-donation" className="btn-main no-underline text-white mb-2">
                Faire une donation
            </Link>
            <Link to="/inscription/achat" className="btn-main no-underline text-white  mb-2">
                Acheter son logement
            </Link>
            <Link to="/inscription/vente" className="btn-main no-underline text-white">
                Vendre son bien
            </Link>
              </div>
            
        </div>
    );
}

export default CardClotereSidebar;