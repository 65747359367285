import React from "react";


function CardHelp(args) {
    return (
        <>
            <div className="bg-gradient-to-r from-teal-400 to-teal-300 rounded-xl mt-6">
                <div className="mx-auto max-w-7xl px-6 py-12 sm:py-12 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Une question ?
                    </h2>
                    <p className="text-md leading-8 text-gray-600">
                        Votre conseiller est disponible pour répondre à toutes vos questions par chat, alors n'hésitez pas à lui écrire.
                    </p>
                    <div className=" flex items-center gap-x-6">
                        <a
                            href="https://clotere.webflow.io"
                            target="blank"
                            className="btn-main no-underline"
                        >
                            Consultez notre blog
                        </a>
                    </div>
                </div>
            </div>
        </>

    );
}

export default CardHelp;