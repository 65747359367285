import React from "react";
import styled from "styled-components";
/** composants **/
import HeaderAnnuaire from './HeaderAnnuaire2';
import PourquoiClotere from './PourquoiClotere';
import CtaNotaire from './CtaNotaire';
import ArticlesSimilaires from '../blog/ArticlesSimilaires';
import Footer from '../components/Footer';
import { Avantages } from "./Avantages";

export const HomeS = styled.div`

`;



function HomeAnnuaire(args) {
    return (
        <>
            <HeaderAnnuaire/>
            <Avantages/>
            <ArticlesSimilaires title="Tous nos articles sur les notaires, leurs rôles et leurs frais."/>
           <PourquoiClotere/>
           <CtaNotaire/>
            <Footer/>
        
        </>
    );
}

export default HomeAnnuaire;