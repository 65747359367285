import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Offcanvas,
  ListGroup,
  ListGroupItem,
  OffcanvasHeader,
  OffcanvasBody,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  TitlePage,
  TitlePageApp,
  TitleSection,
  Panel,
  AlertCta,
} from "../style/Layout";
import { CalendarDaysIcon,  InformationCircleIcon} from '@heroicons/react/20/solid'
import TimelineListItem from "../components/TimelineListItem";
import Articles from "../components/Articles";
import FaqVendeur from "../components/FaqVendeur";
import FaqAcheteur from "../components/FaqAcheteur";
import TimelineChronologie from "../components/TimelineChronologie";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import CardHelp from "../components/CardHelp";
import AideCompromis from "../components/AideCompromis";
import AideActe from "../components/AideActe";
import styled from "styled-components";
import backWelcome from "../img/back-alert-welcome.png";
import photoNotaire from "../img/photo-notaire.png";
import icnDocVente from "../img/icn-doc-vente.svg";
import { PopupButton } from '@typeform/embed-react';
import CardNotaireDashboard from '../components/CardNotaireDashboard';

function Dashboard(args) {
  const [canvas, setCanvas] = useState(false);
  const [canvasCompromis, setCanvasCompromis] = useState(false);
  const [canvasActe, setCanvasActe] = useState(false);

  const toggle3 = () => setCanvas(!canvas);
  const toggleCompromis = () => setCanvasCompromis(!canvasCompromis);
  const toggleActe = () => setCanvasActe(!canvasActe);


  const [pdfName, setPdfName] = useState(null);
  const toggleModal = () => setPdfName(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //console.log("args dahboasrd", args);


  if (args.info === "newUser") {
    return (
      <>
        <Container>
          <Row>
            <TitlePageApp>
              <Col md="12">
                <TitlePage>
                  Bonjour <span role="img">👋</span>{" "}
                </TitlePage>
                <p>
                  Votre transaction est en attente de validation. Nous vous
                  enverrons un email lorsquelle sera validée.{" "}
                </p>
              </Col>
            </TitlePageApp>
            <Col md="12">
              <TitleSection className="mt-5">
                Suivi de votre transaction
              </TitleSection>
              <Panel>
                <ListGroupActionAmener numbered>
                  <ListGroupItem className="done">
                    <div className="d-flex flex-column">
                      <h4>Création de votre transaction immobilière</h4>
                      <p>Aujourd'hui</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="encours">
                    <div className="d-flex flex-column">
                      <h4>Vérification de votre transaction immobilière</h4>
                      <p>En cours</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="avenir">
                    <div className="d-flex flex-column">
                      <h4>
                        Ouvertures des accès aux utilisateurs (acheteur(s),
                        vendeur(s), agent immobilier)
                      </h4>
                      <p>A venir</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="avenir">
                    <div className="d-flex flex-column">
                      <h4>Prise en charge de l'affaire par votre notaire</h4>
                      <p>A venir</p>
                    </div>
                  </ListGroupItem>
                </ListGroupActionAmener>
              </Panel>
            </Col>
          </Row>
        </Container>
      </>
    );
  } else if (args !== null) {
    return (
      <>
        <Container>
          <Row className="d-flex align-self-start">
            <TitlePageApp>
              <Col md="7">
                <TitlePage>Bonjour {args.user.prenom} 👋</TitlePage>
                <p>
                  Voici l'avancement de votre transaction :{" "}
                  <b>{args.transaction.nom}</b>{" "}
                </p>
              </Col>
            </TitlePageApp>

            <Col md="12" className="mt-3">
              {args.transaction.statut_marketplace_notaire === "en recherche de notaire" &&
                <>
                  <AlertCta color="primary">
                    <div className="bloc">
                      <h3>Trouvez le notaire idéal</h3>
                      <ul>
                        <li>Suivez l'avancement depuis votre espace Clotere</li>
                        <li>Transmettez vos documents de manière sécurisée</li>
                        <li>Bénéficiez de l'accompagnement de votre conseiller</li>
                      </ul>
                      <Link to="/app/notaires">Trouver mon notaire</Link>
                    </div>

                    <div className="blog-img"><img src={photoNotaire} alt="trouver votre notaire" /></div>

                  </AlertCta>
                </>
              }


              {/**Informations sur l'attribution de l'affaire au notaire */}
              {args.transaction.statut_marketplace_notaire === "vérification avant attribution"  &&
                <>
                  <AlertWelcome>
                    <p><span role="img">👋</span> Nous avons averti votre notaire de votre choix, il va bientôt prendre en charge votre affaire.
                    </p>
                  </AlertWelcome>
                </>
              }
              {args.transaction.statut_marketplace_notaire === "en cours d'attribution"  &&
                <>
                  <AlertWelcome>
                    <p><span role="img">👋</span> Nous avons averti votre notaire de votre choix, il va bientôt prendre en charge votre affaire.
                    </p>
                  </AlertWelcome>
                </>
              }
              {args.transaction.statut_marketplace_notaire === "notaire trouvé" &&
                <>
                  <TitleSection>Votre notaire</TitleSection>
                  <p className='mt-1 text-sm text-gray-500'><span role="img">👋</span> Votre dossier a bien été pris en charge par votre notaire, vous serez dorénavant notifié de toutes les évolutions de votre dossier.</p>
                  <CardNotaireDashboard nom={args.transaction.nom_from_notaire} prenom={args.transaction.prenom_from_notaire} email={args.transaction.email_from_notaire} telephone={args.transaction.telephone_from_notaire} adresse={args.transaction.adresse_from_notaire} cp={args.transaction.code_postal_from_notaire} ville={args.transaction.ville_from_notaire} photo={args.transaction.photo_profil_from_notaire} />

                </>
              }

              {/** action à mener**/}
              {args.action !== undefined && args.action.length > 0 ? (
                <>
                  <TitleSection>Vos actions à mener</TitleSection>
                  <Panel>
                    <ListGroupActionAmener numbered>
                      {args.action.map((col, i) => (
                        <>
                          {args.action[i].nom === "Ajout document" ? (
                            <>
                              <TimelineListItem
                                type="document"
                                statut={args.action[i].statut}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                          {args.action[i].nom ===
                            "Questionnaire de connaissance" ? (
                            <>
                              <TimelineListItem
                                user={args.user}
                                type="questionnaire"
                                statut={args.action[i].statut}
                                action={args.action[i].id}
                              />
                            </>
                          ) : (
                            <> </>
                          )}

                          {args.action[i].nom === "Rib" ? (
                            <>
                              <TimelineListItem
                                type="rib"
                                statut={args.action[i].statut}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </ListGroupActionAmener>
                  </Panel>
                </>
              ) : (
                <></>
              )}


              {/**composant document de vente (ne s'affiche que si statut notaire trouvé) */}
              {args.transaction.statut_marketplace_notaire === "notaire trouvé" &&
                <>
                  {args.evenement !== undefined && args.evenement.length > 0 ? (
                    <>
                      <TitleSection className="mt-5">
                        Vos documents de vente
                      </TitleSection>
                      <Row>

                        {args.evenement.map((col, i) => (
                          <>
                            <Col md="6" xs="12">
                              <div className="lg:col-start-3 lg:row-end-1">
                                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                                  <dl className="flex flex-wrap">
                                    <div className="flex-auto pl-6 pt-6">
                                      <img
                                        src={icnDocVente}
                                        alt="document-vente"
                                        className="mb-2 h-16"
                                      />
                                      <dd className="mt-1 text-xl font-semibold leading-6 text-gray-900">
                                        {args.evenement[i].fields.type === "promesse de vente" && <>Promesse de vente</>}
                                        {args.evenement[i].fields.type === "acte authentique de vente" && <>Acte authentique de vente</>}

                                      </dd>
                                    </div>
                                    <div className="flex-none self-end px-6 pt-4">
                                      {args.evenement[i].fields.etat === "pas fait" && <><dd className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">Non débuté</dd></>}
                                      {args.evenement[i].fields.etat === "en cours" && <><dd className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">En cours</dd></>}
                                      {args.evenement[i].fields.etat === "erreurs signalées" && <><dd className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">Erreurs signalées</dd></>}
                                      {args.evenement[i].fields.etat === "fait" && <><dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Rédigé</dd></>}


                                    </div>
                                    <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                      <dt className="flex-none">
                                        <span className="sr-only">Client</span>
                                        <InformationCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                      </dt>

                                      {args.evenement[i].fields.etat === "pas fait" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm font-medium leading-6 text-gray-500">Non débuté</dd>
                                          </div>
                                        </>}

                                      {args.evenement[i].fields.etat === "fait" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm font-medium leading-6 text-gray-900">Votre document est prêt 🥳 !</dd>
                                            <a href={`/app/relecture/${args.evenement[i].id}`} className="btn-main no-underline w-content">Relire le document</a>
                                          </div>
                                        </>}
                                      {args.evenement[i].fields.etat === "erreurs signalées" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm font-medium leading-6 text-gray-900">Des erreurs ont été signalées sur ce document.</dd>
                                            <a href={`/app/relecture/${args.evenement[i].id}`} className="btn-main no-underline w-content">Relire le document</a>
                                          </div>
                                        </>}
                                    </div>
                                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                      <dt className="flex-none">
                                        <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                      </dt>
                                      {args.evenement[i].fields.statut_from_rdv[0] === "a programmer" && <dd className="text-sm leading-6 text-gray-500">Aucun rendez-vous prévu pour le moment</dd>}
                                      {args.evenement[i].fields.statut_from_rdv[0] === "en cours de prog" &&
                                        <div className="flex flex-column">
                                          <dd className="text-sm leading-6 text-gray-900"> En cours de programmation</dd>
                                          <PopupButton
                                            id={args.evenement[i].fields.lien_typeform_reservtion[0]}
                                            size={100}
                                            hidden={{ id_user: args.user.airtable_id, nom_transaction: args.transaction.id }}
                                            autoClose={false}
                                            style={{ border: '0', background: 'none' }}
                                          >
                                            <button className="btn-main" >Indiquez vos disponibilités</button>
                                          </PopupButton>
                                        </div>
                                      }
                                      {args.evenement[i].fields.statut_from_rdv[0] === "programme" &&
                                        <>
                                          <div className="flex flex-column">
                                            <dd className="text-sm leading-6 text-gray-900"> Rendez-vous prévu le :</dd>
                                            <dd className="inline-flex items-center rounded-md bg-emerald-50 px-2 py-1 text-md font-bold text-emerald-700 ring-1 ring-inset ring-emerald-600/20">{args.evenement[i].fields.date_from_rdv[0]}</dd>
                                          </div>
                                        </>
                                      }


                                      {args.evenement[i].fields.etat === "information(s) manquante(s)" ? (<>Des informations sont manquantes</>) : (<>{" "}</>)}
                                      {args.evenement[i].fields.etat === "a signer" ? (<>Votre document est prêt à être signé !</>) : (<>{" "}</>)}

                                    </div>
                                  </dl>
                                  <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                                  {args.evenement[i].fields.type === "promesse de vente" && 
                                  <>
                                  <a className="text-sm font-semibold leading-6 text-gray-900" onClick={toggleCompromis}>Tout comprendre sur la Promesse de vente <span aria-hidden="true">&rarr;</span></a>
                                  </>}

                                  {args.evenement[i].fields.type === "acte authentique de vente" && 
                                  <>
                                  <a className="text-sm font-semibold leading-6 text-gray-900" onClick={toggleActe}>Tout comprendre sur l'acte authentique de vente <span aria-hidden="true">&rarr;</span></a>
                                  </>}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              }

              {/** help section **/}
              <TitleSection className="mt-5">Respirez on vous explique tout 🧘 </TitleSection>
              <Row>
                <Col md='8'>
                {args.user.role === "vendeur" &&
                <FaqVendeur/>
                }
                {args.user.role === "acheteur" &&
                <FaqAcheteur/>
                }
                  
                </Col>
                <Col md='4'>
                <TimelineChronologie/>
                </Col>
              </Row>
              
              <TitleSection className="mt-5">Votre notaire en clair 🔥 <br /></TitleSection>
              <Articles />
              <Row><Col md="12" align="center" className="mt-4 mb-4"><button className="btn-main" color="primary" href="https://clotere.webflow.io/categories/notaire-immobilier?utm_source=app-client" target="blank">Tous nos articles</button></Col></Row>



              <CardHelp email={args.user.email} />
            </Col>
          </Row>
        </Container>
        {/** Toutes les modals et canvas */}
        <Offcanvas
          isOpen={canvas}
          toggle={toggle3}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggle3}>
            Vous n'êtes plus disponible ?
          </OffcanvasHeader>
          <OffcanvasBody>
            <p>
              Pour indiquer les raisons de votre indisponibilités veuillez
              contacter votre conseillé directement.
            </p>
          </OffcanvasBody>
        </Offcanvas>
        <Offcanvas
          isOpen={canvasCompromis}
          toggle={toggleCompromis}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggleCompromis}>
            Qu'est-ce que la promesse de vente ?
          </OffcanvasHeader>
          <AideCompromis />
        </Offcanvas>
        <Offcanvas
          isOpen={canvasActe}
          toggle={toggleActe}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggleActe}>
            Qu'est-ce que l'acte authentique de vente ?
          </OffcanvasHeader>
          <AideActe />
        </Offcanvas>

        <Modal isOpen={pdfName != null} toggle={toggleModal} size="lg" centered>
          {pdfName != null ? (
            pdfName.type === "application/pdf" ? (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfName.url}
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,
                  ]}
                />
              </Worker>
            ) : (
              <img alt="document" src={pdfName.url}></img>
            )
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

const ListGroupActionAmener = styled(ListGroup)`
  border-radius: 0;
  border: 0;
  .list-group-item {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 30px;
    &:before {
      content: counters(section, "") "";
      counter-increment: section;
      display: inline;
      border: 1px solid #ddd;
      font-weight: 500;
      border-radius: 100px;
      padding: 10px 17px;
      margin-right: 30px;
    }
    &.done {
      &:before {
        content: "✔️";
      }
    }
    h4 {
      color: #1d2b28;
    }
    color: #84847c;
  }
  @media all and (max-width: 768px) {
    .list-group-item {
      flex-direction: column;
      &:before {
        margin-bottom: 1rem;
      }
    }
  }
`;

const AlertWelcome = styled(Alert)`
  background-image: url(${backWelcome});
  background-size: cover;
  background-repeat: no-repeat;
  color: white !important;
  border: 0 !important;
  border-radius: 16px !important;
  margin-bottom: 3rem;
  padding: 3rem !important;
  p {
    margin-bottom: 0;
    font-size: 18px;
  }
  a {
    color: white !important;
  }
`;

const PanelDocVente = styled(Panel)`

.titre{
    align-items:center;
    img{margin-right:8px;}
    h4{font-size:22px}
}
.list{
    margin-top:1rem;
    margin-left:1rem;
    .list-group{
        border:0;
        .list-group-item{
            border:0;
            display:flex;
            flex-direction:row;
            align-items:flex-start;
            img{margin-right:1rem;}
            p{
                span{opacity:.5;display:block;}
            }
            .date{
                background:${(props) => props.theme.colors.linearBackground};
                padding:8px;
                text-align:center;
                font-weight:600;
                border-radius:8px;
            }
        }
    }
}
.tuto{
    .card{
        position:relative;
        padding:16px;
        border:0;
        border-radius:16px;
        background:${(props) => props.theme.colors.linearBackground};
        margin-right:2rem;
        margin-bottom:2rem;
        img{
            position: absolute;
            right: 20px;
            top: -25px;
            width: 50px;
        }
        .card-title{
            font-size:16px;
            color:${(props) => props.theme.colors.mainDark};
            font-weight:600;
        }
        ul{
            font-size:16px;
            color:#636060;
            li::marker {
                color: ${(props) => props.theme.colors.main};
                font-size:2rem;
        }
        @media all and (max-width: 768px) {
            margin-bottom:1.5rem;
        }
    }
    @media all and (max-width: 768px) {
        margin-right:0;
        margin-left:0;
    }
}

`;
export const Message = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.colors.linearBackground};
  text-align: center;
  border-radius: 6px;
`;

export const RowCandidatureNotaire = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  .card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd !important;
    width: 100%;
    margin-bottom: 1rem;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;



export default Dashboard;
