import React, { useState } from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  TitlePage,
  TitlePageApp,
  TitleSection,
  Panel,
  AlertCta,
} from "../style/Layout";
import TimelineChronologie from "../components/TimelineChronologie";
import FaqVendeur from "../components/FaqVendeur";
import CardHelp from "../components/CardHelp";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import photoNotaire from "../img/photo-notaire.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CheckIcon, EnvelopeIcon } from "@heroicons/react/24/solid";

function DashboardAnnonce(args) {
  const [copyStatus, setCopyStatus] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 1000); // Reset status after 2 seconds
  };

  // Inside your React component
  const handleCopyClick = () => {
    // This function will be called when the user clicks the copy button
    onCopyText(); // Call the copy function
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2500); // Hide the success message after 2.5 seconds
  };
  console.log("args dashboard annonce", args);

  function goPageNotaire() {
    navigate("/app/dashboard-annonce-notaire");
    window.location.reload(false);
  }

  return (
    <>
      <Container>
        <Row className="d-flex align-self-start">
          <TitlePageApp>
            <Col md="7">
              <TitlePage>Bonjour {args.user.prenom} 👋</TitlePage>
              <p>
                Retrouvez ici toutes les informations sur votre vente
              </p>
            </Col>
          </TitlePageApp>

          <Col md="12" className="mt-3">

            {/** lien annonce */}
            <TitleSection>Votre lien certifiée Clotere</TitleSection>
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="p-3">
                {isCopied && <div className="alert-success">👍 Lien copié</div>}
                <div className="flex flex-row items-center justify-start flex-wrap gap-3">
                  <div className="flex w-full sm:flex-1 rounded-md shadow-sm">

                    <input
                      disabled
                      value={args.annonce.lien_annonce}
                      className="input-clotere text-center text-white w-full font-medium bg-slate-700"
                    />
                  </div>
                  <div className="flex-none sm:w-1/4 inline-flex justify-center gap-2">
                    <a href={args.annonce.lien_annonce} target="blank" className="btn-main no-underline">Voir</a>
                    <CopyToClipboard text={args.annonce.lien_annonce} onCopy={handleCopyClick}>
                      <button href={args.annonce.lien_annonce} className="btn-main">Copier</button>
                    </CopyToClipboard>

                  </div>
                </div>
              </div>
            </div>

            {/** Vos messages / demande de visite */}
            <TitleSection className="mt-5">Vos demandes de visites</TitleSection>
            <div className="text-center overflow-hidden rounded-lg bg-slate-50 p-4">
              <EnvelopeIcon className="size-10 text-gray-500 mx-auto" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">Aucun message ou demande de visite</h3>
              <p className="mt-1 text-sm text-gray-500">Vous serez avertie par email lorsqu'un acheteur vous contactera.</p>
            </div>

            {/** help section **/}
            <TitleSection className="mt-5">Respirez on vous explique tout 🧘 </TitleSection>
            <Row>
              <Col md='8'>
                <FaqVendeur />
              </Col>
              <Col md='4'>
                <TimelineChronologie/>
                </Col>
            </Row>

            {/** trouver un notaire  */}
            <AlertCta className="bg-gradient-to-r from-teal-200 to-teal-500 mt-5" color="primary">
              <div className="bloc">
                <h3>Trouvez le notaire idéal</h3>
                <ul>
                  <li><CheckIcon className="size-6 inline-flex" /> Suivez l'avancement depuis votre espace Clotere</li>
                  <li><CheckIcon className="size-6 inline-flex" /> Transmettez vos documents de manière sécurisée</li>
                  <li><CheckIcon className="size-6 inline-flex" /> Bénéficiez de l'accompagnement de votre conseiller</li>
                </ul>
                <Link to="/app/dashboard-annonce-notaire" onClick={goPageNotaire} className="btn-primary no-underline">Trouver mon notaire</Link>
              </div>

              <div className="blog-img"><img src={photoNotaire} alt="trouver votre notaire" /></div>

            </AlertCta>



            <CardHelp email={args.user.email} />
          </Col>

        </Row>
      </Container>
    </>
  );

}



const PanelDocVente = styled(Panel)`

.titre{
    align-items:center;
    img{margin-right:8px;}
    h4{font-size:22px}
}
.list{
    margin-top:1rem;
    margin-left:1rem;
    .list-group{
        border:0;
        .list-group-item{
            border:0;
            display:flex;
            flex-direction:row;
            align-items:flex-start;
            img{margin-right:1rem;}
            p{
                span{opacity:.5;display:block;}
            }
            .date{
                background:${(props) => props.theme.colors.linearBackground};
                padding:8px;
                text-align:center;
                font-weight:600;
                border-radius:8px;
            }
        }
    }
}
.tuto{
    .card{
        position:relative;
        padding:16px;
        border:0;
        border-radius:16px;
        background:${(props) => props.theme.colors.linearBackground};
        margin-right:2rem;
        margin-bottom:2rem;
        img{
            position: absolute;
            right: 20px;
            top: -25px;
            width: 50px;
        }
        .card-title{
            font-size:16px;
            color:${(props) => props.theme.colors.mainDark};
            font-weight:600;
        }
        ul{
            font-size:16px;
            color:#636060;
            li::marker {
                color: ${(props) => props.theme.colors.main};
                font-size:2rem;
        }
        @media all and (max-width: 768px) {
            margin-bottom:1.5rem;
        }
    }
    @media all and (max-width: 768px) {
        margin-right:0;
        margin-left:0;
    }
}

`;
export const Message = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.colors.linearBackground};
  text-align: center;
  border-radius: 6px;
`;

export const RowCandidatureNotaire = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  .card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd !important;
    width: 100%;
    margin-bottom: 1rem;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;



export default DashboardAnnonce;
