import React from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/16/solid';

function CtaNotaire(args) {
    return (
        <>
            <nav aria-label="Breadcrumb" className="justify-center hidden sm:flex">
                <ol role="list" className="flex items-center space-x-4">
                  <li>
                    <div>
                      <Link to="/trouver-votre-notaire" className="text-gray-400 hover:text-gray-500">
                        Annuaire des notaires de France
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                      <Link
                        to="/fr/notaires"
                        className="ml-4 text-sm font-medium text-emerald-800 hover:text-gray-700"
                      >
                        Notaire à {args.page}
                      </Link>
                    </div>
                  </li>
                </ol>
              </nav>
        </>
    );
}

export default CtaNotaire;

