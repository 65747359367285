import React, { useEffect, useState } from 'react';
import { Col, Row, Nav, NavItem, NavLink, Navbar, TabContent, TabPane, NavbarBrand, Collapse, NavbarToggler } from 'reactstrap';
import { useMemberstack } from "@memberstack/react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import Loading from '../components/Loading';
//composants immobilier 
import Dashboard from './Dashboard';
import Bien from './Bien';
import Interlocuteurs from './Interlocuteurs';
import Documents from './Documents';
import Profil from './Profil';
//composants donation
import DashboardDonation from './DashboardDonation';
import ContactDonation from './ContactDonation';
import DocumentsDonation from './DocumentsDonation';
//composants annonce
import DashboardAnnonce from './DashboardAnnonce';
import NotaireAnnonce from './NotaireAnnonce';
import DocumentAnnonce from './DocumentAnnonce';


//style & icone
import icnDash from '../img/icn-dashboard.svg';
import icnBien from '../img/icn-home.svg';
import icnDoc from '../img/icn-doc.svg';
import icnUser from '../img/icn-user.svg';
import icnLogo from '../img/icn-logo.svg';
import Logo from '../img/logo-clotere.svg';
//tailwind
import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarItem,
    SidebarLabel,
    SidebarSection,
    SidebarSpacer,
} from '../components/catalyst/sidebar';
import { SidebarLayout } from '../components/catalyst/sidebar-layout';
import {
    ArrowRightStartOnRectangleIcon,
    ChevronUpIcon,
    BuildingOfficeIcon, UsersIcon, DocumentIcon
} from '@heroicons/react/16/solid'
import { HomeIcon } from '@heroicons/react/20/solid'
import { Avatar } from '../components/catalyst/avatar'
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../components/catalyst/dropdown'
import { UserGroupIcon } from '@heroicons/react/24/solid';


function Layout(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [user, setUser] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [donation, setDonation] = useState(null);
    const [annonce, setAnnonce] = useState(null);
    const [bien, setBien] = useState(null);
    const [evenement, setEvenement] = useState(null);
    const [action, setAction] = useState(null);
    const [activite, setActivite] = useState(null);
    const [rdv, setRdv] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const toggle = tab => { if (currentActiveTab !== tab) setCurrentActiveTab(tab); }
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    //active tabs with route
    useEffect(() => {
        if (window.location.pathname === "/app/dashboard") {
            setCurrentActiveTab("1")
        } else if (window.location.pathname === "/app/bien") {
            setCurrentActiveTab("2")
        } else if (window.location.pathname === "/app/interlocuteurs") {
            setCurrentActiveTab("3")
        } else if (window.location.pathname === "/app/documents") {
            setCurrentActiveTab("4")
        } else if (window.location.pathname === "/app/profil") {
            setCurrentActiveTab("5")
        } else if (window.location.pathname === "/app/dashboard-donation") {
            setCurrentActiveTab("dashboardDonation")
        } else if (window.location.pathname === "/app/contact-donation") {
            setCurrentActiveTab("contactDonation")
        } else if (window.location.pathname === "/app/document-donation") {
            setCurrentActiveTab("documentDonation")
        } else if (window.location.pathname === "/app/dashboard-annonce") {
            setCurrentActiveTab("dashboardAnnonce")
        } else if (window.location.pathname === "/app/dashboard-annonce-notaire") {
            setCurrentActiveTab("notaireAnnonce")
        } else {
            setCurrentActiveTab("1")
        }
    }, []);

    //get info memberstack
    useEffect(() => {
        fetchDataMemberstack();
        // eslint-disable-next-line
    }, []);


    //get info user airtable
    useEffect(() => {
        //avec les info memberstack on recupere les info user airtable
        //console.log("seulement si member est update");
        if (member !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/user/${member.metaData.airtable_id}`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    console.log("this user info", res);
                    setUser(res.fields);
                    //redirection sur le dashboard donation si besoin
                    if (window.location.pathname === "/app/dashboard") { setCurrentActiveTab("dashboardDonation") }
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [loading]);



    //get info transaction ou donation
    useEffect(() => {
        //on recupere toutes les informations du dossier
        if (user !== null && member.metaData.airtable_id !== undefined) {

            if (user.role === "donateur" || user.role === "beneficiare") {
                console.log("donation");
                //La donation
                const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/donation/${user.donation[0]}`;
                fetch(
                    URL,
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                            'content-type': 'application/x-www-form-urlencoded',
                            "Accept": "application/json, text/plain, /"
                        },
                    })
                    .then((res) => res.json())
                    .then((res) => {
                        console.log("donation info", res);
                        setDonation(res.fields);
                    })
                    .catch((error) => console.log("donation info error", error),);
            } else if (user.annonce) {
                //vendeur avec annonce
                const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/annonce/${user.annonce[0]}`;
                fetch(
                    URL,
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                            'content-type': 'application/x-www-form-urlencoded',
                            "Accept": "application/json, text/plain, /"
                        },
                    })
                    .then((res) => res.json())
                    .then((res) => {
                        console.log("annonce info", res);
                        setAnnonce(res.fields);
                    })
                    .catch((error) => console.log("annonce info error", error),);
            } else {
                //La transaction
                const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/transaction/${user.transaction_id}`;
                fetch(
                    URL,
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                            'content-type': 'application/x-www-form-urlencoded',
                            "Accept": "application/json, text/plain, /"
                        },
                    })
                    .then((res) => res.json())
                    .then((res) => {
                        console.log("transaction info", res);
                        setTransaction(res.fields);
                    })
                    .catch((error) => console.log("transaction info error", error),);
            }
        }
    }, [user]);

    //SUITE DES REQUÊTES QUE SI IMMOBILIER
    //get info bien
    useEffect(() => {
        //on recupere toutes les informations du dossier
        if (transaction !== null && member.metaData.airtable_id !== undefined) {
            //Le bien
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/bien/${transaction.bien}`;
            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        "Accept": "application/json"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("bien info", res);
                    setBien(res.fields);
                })
                .catch((error) => console.log("bien info error", error),);
        }

        // eslint-disable-next-line
    }, [transaction]);

    //get info event
    useEffect(() => {
        //on recupere les event de la transaction
        if (transaction !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event?filterByFormula=SEARCH("${transaction.id}",{transaction})`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("all event transaction", res.records);
                    setEvenement(res.records);
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [transaction]);

    //get info action
    useEffect(() => {
        //on recupere les action de la transaction

        if (transaction !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/actions?filterByFormula=SEARCH("${transaction.id}",{transaction})`;
            //const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/actions?filterByFormula=SEARCH("${transaction.id}",{transaction},"${user.role}",{acteur})`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("all event action", res.records);
                    const actionNonTrie = res.records;
                    actionNonTrie.sort((a, b) => (a.fields.ordre > b.fields.ordre) ? 1 : -1);


                    const actionVendeur = [];
                    const actionAcheteur = [];
                    for (let i = 0; i < actionNonTrie.length; i++) {
                        if (actionNonTrie[i].fields.acteur === "vendeur") {
                            actionVendeur.push({
                                nom: actionNonTrie[i].fields.nom,
                                statut: actionNonTrie[i].fields.statut,
                                id: actionNonTrie[i].fields.id
                            });
                        } else if (actionNonTrie[i].fields.acteur === "acheteur") {
                            actionAcheteur.push({
                                nom: actionNonTrie[i].fields.nom,
                                statut: actionNonTrie[i].fields.statut,
                                id: actionNonTrie[i].fields.id
                            });
                        }
                    }
                    //console.log("action vendeur ", actionVendeur);
                    //console.log("action acheteur ", actionAcheteur);
                    if (user.role === "vendeur") { setAction(actionVendeur); }
                    else if (user.role === "acheteur") { setAction(actionAcheteur); }

                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [transaction]);

    //get info activite
    useEffect(() => {
        //on recupere les activite de la transaction
        if (transaction !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/activite?filterByFormula=SEARCH("${transaction.id}",{transaction})`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("all event transaction", res.records);
                    setActivite(res.records);
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [transaction]);

    //get info rdv
    useEffect(() => {
        //on recupere les rdv de la transaction
        if (transaction !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/rdv?filterByFormula=SEARCH("${transaction.id}",{transaction})`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("all rdv transaction", res.records);
                    setRdv(res.records);
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [transaction]);

    //SUITE DES REQUÊTES QUE SI DONATION
    //get info action
    useEffect(() => {
        //on recupere les action de la transaction

        if (donation !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/actions?filterByFormula=SEARCH("${donation.id}",{donation})`;
            //const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/actions?filterByFormula=SEARCH("${transaction.id}",{transaction},"${user.role}",{acteur})`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    console.log("all event action", res.records);
                    const actionNonTrie = res.records;
                    actionNonTrie.sort((a, b) => (a.fields.ordre > b.fields.ordre) ? 1 : -1);


                    const actionDonateur = [];
                    const actionBeneficiaire = [];
                    for (let i = 0; i < actionNonTrie.length; i++) {
                        if (actionNonTrie[i].fields.acteur === "donateur") {
                            actionDonateur.push({
                                nom: actionNonTrie[i].fields.nom,
                                statut: actionNonTrie[i].fields.statut,
                                id: actionNonTrie[i].fields.id
                            });
                        } else if (actionNonTrie[i].fields.acteur === "beneficiaire") {
                            actionBeneficiaire.push({
                                nom: actionNonTrie[i].fields.nom,
                                statut: actionNonTrie[i].fields.statut,
                                id: actionNonTrie[i].fields.id
                            });
                        }
                    }
                    console.log("action donateur ", actionDonateur);
                    console.log("action beneficiaire ", actionBeneficiaire);
                    if (user.role === "donateur") { setAction(actionDonateur); }
                    else if (user.role === "beneficiaire") { setAction(actionBeneficiaire); }

                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [donation]);

    //get info event
    useEffect(() => {
        //on recupere les event de la transaction
        if (donation !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event?filterByFormula=SEARCH("${donation.id}",{donation})`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("all event donation", res.records);
                    setEvenement(res.records);
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [donation]);


    //console.log("member", member);
    //console.log("user", user);
    //console.log("transaction", transaction);
    //console.log("bien", bien);
    //console.log("activite", activite);
    //console.log("rdv",rdv);
    //console.log("action",action);

    const fetchDataMemberstack = () => {
        setLoading(true);
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
            .then((data) => {
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    /************ TRANSACTION IMMOBILIER *****************/
    if (user !== null & transaction !== null & bien !== null & evenement !== null & activite !== null & rdv !== null & action !== null) {
        return (
            <>
                <SidebarLayout
                    sidebar={<Sidebar>
                        <SidebarHeader>
                            <Dropdown>
                                <DropdownButton as={SidebarItem} className="mb-2.5">
                                    <Avatar src={icnLogo} alt="Clotere" />
                                    <SidebarLabel>Clotere</SidebarLabel>

                                </DropdownButton>
                            </Dropdown>
                        </SidebarHeader>
                        <SidebarBody>
                            <SidebarSection>
                                <SidebarItem
                                    to="/app/dashboard"
                                    onClick={() => { toggle('1'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/dashboard" ? "active" : ""} ${window.location.pathname === "/app" ? "active" : ""}`}
                                >
                                    <HomeIcon />
                                    <SidebarLabel>Tableau de bord</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    to="/app/bien"
                                    onClick={() => { toggle('2'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/bien" ? "active" : ""}`}
                                >
                                    <BuildingOfficeIcon />
                                    <SidebarLabel>Bien</SidebarLabel>
                                </SidebarItem>

                                <SidebarItem
                                    to="/app/interlocuteurs"
                                    onClick={() => { toggle('3'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/interlocuteurs" ? "active" : ""}`}
                                >
                                    <UsersIcon />
                                    <SidebarLabel> Interlocuteurs</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    to="/app/documents"
                                    onClick={() => { toggle('4'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/documents" ? "active" : ""}`}
                                >
                                    <DocumentIcon />
                                    <SidebarLabel> Document</SidebarLabel>
                                </SidebarItem>


                            </SidebarSection>
                            <SidebarSpacer />

                            <SidebarSection>
                                {/**
                                <SidebarItem href="/support">
                                    <QuestionMarkCircleIcon />
                                    <SidebarLabel>Support</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem href="/changelog">
                                    <SparklesIcon />
                                    <SidebarLabel>Nouveautes</SidebarLabel>
                                </SidebarItem>**/}
                            </SidebarSection>
                        </SidebarBody>
                        <SidebarFooter>
                            <Dropdown>
                                <DropdownButton as={SidebarItem}>
                                    <span className="flex min-w-0 items-center gap-3">
                                        <span className="min-w-0">
                                            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{user.prenom}</span>
                                            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">

                                                {user.email}
                                            </span>
                                        </span>
                                    </span>
                                    <ChevronUpIcon />
                                </DropdownButton>
                                <DropdownMenu className="min-w-64" anchor="top start">
                                    <DropdownItem
                                        onClick={() => { toggle('5'); }}
                                    >
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Profil</DropdownLabel>
                                    </DropdownItem>

                                    <DropdownDivider />
                                    <DropdownItem onClick={() =>
                                        memberstack.logout()
                                            .then(({ data, type }) => {
                                                window.location.replace('/');
                                            })}>
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Se deconnecter</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </SidebarFooter>
                    </Sidebar>}
                    navbar={<Navbar></Navbar>}
                >
                    {/** content */}
                    <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <Dashboard transaction={transaction} user={user} evenement={evenement} activite={activite} rdv={rdv} action={action} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <Bien user={user} bien={bien} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    <Interlocuteurs user={user} transaction={transaction} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    <Documents user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                    <Profil user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>

                </SidebarLayout>
            </>
        );
    }

    /************ DONATION *****************/
    else if (user !== null & donation !== null & action !== null & evenement !== null) {
        return (
            <>
                <SidebarLayout
                    sidebar={<Sidebar>
                        <SidebarHeader>
                            <Dropdown>
                                <DropdownButton as={SidebarItem} className="mb-2.5">
                                    <Avatar src={icnLogo} alt="Clotere" />
                                    <SidebarLabel>Clotere</SidebarLabel>

                                </DropdownButton>
                            </Dropdown>
                        </SidebarHeader>
                        <SidebarBody>
                            <SidebarSection>
                                <SidebarItem
                                    to="/app/dashboard-donation"
                                    onClick={() => { toggle('dashboardDonation'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/dashboard-donation" ? "active" : ""} ${window.location.pathname === "/app" ? "active" : ""}`}
                                >
                                    <HomeIcon />
                                    <SidebarLabel>Tableau de bord</SidebarLabel>
                                </SidebarItem>
                                {/**
                                <SidebarItem
                                    to="/app/contact-donation"
                                    onClick={() => { toggle('contactDonation'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/contact-donation" ? "active" : ""}`}
                                >
                                    <BuildingOfficeIcon />
                                    <SidebarLabel>Contact</SidebarLabel>
                                </SidebarItem>
                                 */}
                                <SidebarItem
                                    to="/app/document-donation"
                                    onClick={() => { toggle('documentDonation'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/documents-donation" ? "active" : ""}`}
                                >
                                    <DocumentIcon />
                                    <SidebarLabel> Documents</SidebarLabel>
                                </SidebarItem>


                            </SidebarSection>
                            <SidebarSpacer />
                            <SidebarSection>
                                {/**
                                <SidebarItem href="/support">
                                    <QuestionMarkCircleIcon />
                                    <SidebarLabel>Support</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem href="/changelog">
                                    <SparklesIcon />
                                    <SidebarLabel>Nouveautes</SidebarLabel>
                                </SidebarItem>**/}
                            </SidebarSection>
                        </SidebarBody>
                        <SidebarFooter>
                            <Dropdown>
                                <DropdownButton as={SidebarItem}>
                                    <span className="flex min-w-0 items-center gap-3">
                                        <span className="min-w-0">
                                            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{user.prenom}</span>
                                            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">

                                                {user.email}
                                            </span>
                                        </span>
                                    </span>
                                    <ChevronUpIcon />
                                </DropdownButton>
                                <DropdownMenu className="min-w-64" anchor="top start">
                                    <DropdownItem
                                        onClick={() => { toggle('5'); }}
                                    >
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Profil</DropdownLabel>
                                    </DropdownItem>

                                    <DropdownDivider />
                                    <DropdownItem onClick={() =>
                                        memberstack.logout()
                                            .then(({ data, type }) => {
                                                window.location.replace('/');
                                            })}>
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Se deconnecter</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </SidebarFooter>
                    </Sidebar>}
                    navbar={<Navbar></Navbar>}
                >
                    {/** content */}
                    <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="dashboardDonation">
                            <Row>
                                <Col sm="12">
                                    <DashboardDonation donation={donation} user={user} action={action} evenement={evenement} />
                                </Col>
                            </Row>
                        </TabPane>
                        {/**
                        <TabPane tabId="contactDonation">
                            <Row>
                                <Col sm="12">
                                    <ContactDonation user={user} donation={donation}/>
                                </Col>
                            </Row>
                        </TabPane>
                         */}
                        <TabPane tabId="documentDonation">
                            <Row>
                                <Col sm="12">
                                    <DocumentsDonation user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                    <Profil user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>

                </SidebarLayout>
            </>
        )
    }
    /************ ANNONCE VENDEUR *****************/
    else if (user !== null & annonce !== null) {
        return (
            <>
                <SidebarLayout
                    sidebar={<Sidebar>
                        <SidebarHeader>
                            <Dropdown>
                                <DropdownButton as={SidebarItem} className="mb-2.5">
                                    <Avatar src={icnLogo} alt="Clotere" />
                                    <SidebarLabel>Clotere</SidebarLabel>

                                </DropdownButton>
                            </Dropdown>
                        </SidebarHeader>
                        <SidebarBody>
                            <SidebarSection>
                                <SidebarItem
                                    to="/app/dashboard-annonce"
                                    onClick={() => { toggle('dashboardAnnonce'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/dashboard-annonce" ? "active" : ""} ${window.location.pathname === "/app" ? "active" : ""}`}
                                >
                                    <HomeIcon />
                                    <SidebarLabel>Tableau de bord</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    to="/app/dashboard-annonce-document"
                                    onClick={() => { toggle('documentAnnonce'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/dashboard-annonce-document" ? "active" : ""}`}
                                >
                                    <DocumentIcon />
                                    <SidebarLabel>Documents</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    to="/app/dashboard-annonce-notaire"
                                    onClick={() => { toggle('notaireAnnonce'); }}
                                    className={`nav-link  ${window.location.pathname === "/app/dashboard-annonce-notaire" ? "active" : ""}`}
                                >
                                    <UserGroupIcon />
                                    <SidebarLabel>Notaire</SidebarLabel>
                                </SidebarItem>


                            </SidebarSection>
                            <SidebarSpacer />
                            <SidebarSection>
                                {/**
                                <SidebarItem href="/support">
                                    <QuestionMarkCircleIcon />
                                    <SidebarLabel>Support</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem href="/changelog">
                                    <SparklesIcon />
                                    <SidebarLabel>Nouveautes</SidebarLabel>
                                </SidebarItem>**/}
                            </SidebarSection>
                        </SidebarBody>
                        <SidebarFooter>
                            <Dropdown>
                                <DropdownButton as={SidebarItem}>
                                    <span className="flex min-w-0 items-center gap-3">
                                        <span className="min-w-0">
                                            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{user.prenom}</span>
                                            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">

                                                {user.email}
                                            </span>
                                        </span>
                                    </span>
                                    <ChevronUpIcon />
                                </DropdownButton>
                                <DropdownMenu className="min-w-64" anchor="top start">
                                    <DropdownItem
                                        onClick={() => { toggle('5'); }}
                                    >
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Profil</DropdownLabel>
                                    </DropdownItem>

                                    <DropdownDivider />
                                    <DropdownItem onClick={() =>
                                        memberstack.logout()
                                            .then(({ data, type }) => {
                                                window.location.replace('/');
                                            })}>
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Se deconnecter</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </SidebarFooter>
                    </Sidebar>}
                    navbar={<Navbar></Navbar>}
                >
                    {/** content */}
                    <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="dashboardAnnonce">
                            <Row>
                                <Col sm="12">
                                    <DashboardAnnonce annonce={annonce} user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="documentAnnonce">
                            <Row>
                                <Col sm="12">
                                    <DocumentAnnonce annonce={annonce} user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="notaireAnnonce">
                            <Row>
                                <Col sm="12">
                                    <NotaireAnnonce annonce={annonce} user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                    <Profil user={user} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>

                </SidebarLayout>
            </>
        )
    }
    //si compte pas encore validé 
    else if (member !== null && member.metaData.airtable_id === undefined) {
        return (
            <LayoutS>
                <Row>
                    <ColMenu md="2">

                        {/** Navbar desktop **/}
                        <Nav vertical className='menuSidebar'>
                            <NavItem className='brand'>
                                <NavLink
                                    onClick={() => { toggle('1'); }}
                                >
                                    <img src={icnLogo} alt="Clotere" />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <Link
                                    to="/app/dashboard"

                                    className={`nav-link  ${window.location.pathname === "/app/dashboard" ? "active" : ""} ${window.location.pathname === "/app" ? "active" : ""}`}

                                    onClick={() => { toggle('1'); }}
                                >
                                    <img src={icnDash} alt="tableau de bord" /> Tableau de bord
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link disable
                                    to="/app/bien"
                                    className={`disabled-link nav-link  ${window.location.pathname === "/app/bien" ? "active" : ""}`}
                                    onClick={() => { toggle('2'); }} >
                                    <img src={icnBien} alt="bien" /> Bien
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    to="/app/interlocuteurs"
                                    className={`disabled-link nav-link  ${window.location.pathname === "/app/interlocuteurs" ? "active" : ""}`}
                                    onClick={() => { toggle('3'); }} >
                                    <img src={icnUser} alt="interlocuteurs" /> Interlocuteurs
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    to="/app/documents"
                                    className={`disabled-link nav-link  ${window.location.pathname === "/app/documents" ? "active" : ""}`}
                                    onClick={() => { toggle('4'); }} >
                                    <img src={icnDoc} alt="documents" /> Documents
                                </Link>
                            </NavItem>

                            <NavItem className='logout'>
                                <Link
                                    className="nav-link"
                                    onClick={() =>
                                        memberstack.logout()
                                            .then(({ data, type }) => {
                                                window.location.replace('/');
                                            })} >
                                    Se déconnecter
                                </Link>
                            </NavItem>
                        </Nav>

                        {/** Navbar mobile **/}
                        <Navbar>
                            <NavbarBrand className="me-auto">
                                <img src={Logo} alt="Clotere" className='imgBrand' />
                            </NavbarBrand>
                            <NavbarToggler onClick={toggleNavbar} className="me-2" />
                            <Collapse isOpen={!collapsed} navbar>
                                <Nav vertical>
                                    <NavItem>
                                        <Link
                                            to="/app/dashboard"
                                            className={`${window.location.pathname === "/app/dashboard" ? "active" : ""} ${window.location.pathname === "/app" ? "active" : ""}`}
                                            onClick={() => {
                                                toggle('1');
                                                toggleNavbar();
                                            }}
                                        >
                                            <img src={icnDash} alt="tableau de bord" /> Tableau de bord
                                        </Link>
                                    </NavItem>

                                    <NavItem className='logout'>
                                        <Link
                                            onClick={() =>
                                                memberstack.logout()
                                                    .then(({ data, type }) => {
                                                        window.location.replace('/');
                                                    })} >
                                            Se déconnecter
                                        </Link>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>

                    </ColMenu>
                    <ColContent md="10">
                        <TabContent activeTab={currentActiveTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <Dashboard info="newUser" />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </ColContent>
                </Row>
            </LayoutS>
        )
    }
    return (
        <>
            <Loading />
        </>
    )

}

export default Layout;

const LayoutS = styled.div`

`;

const ColMenu = styled(Col)`
  background-color:#FFF;
  min-height:100vh;
  ul{
    padding:20px;
    position: fixed;
    margin-left: 1rem;
    li.brand{
        background-color: transparent;  
        a img{width:40px!important;}
    }
  }
  a.nav-link{
    display:flex;
    flex-direction:row;
    align-items:center;
    color:#000;
    font-size:.9rem;
    transition:all ease .5s;
    cursor: pointer;
    border-radius:8px;
    margin-bottom:15px;
    padding:8px;
    &:hover,&.active{background-color:#EAF3F2;}
    svg{margin-right:8px;color:#5ba59d;}
  }
  .navbar{display:none;}
  @media all and (max-width: 768px) {
    ul.menuSidebar{ display:none;}
    .navbar{
        display:block;
        ul{padding:0;position:relative;}
        .navbar-collapse{padding:0;margin:0;visibility: visible;}
        .imgBrand{ width: auto;height: 30px;margin-bottom: 0;}
        
        li.nav-item{
            padding:1rem 0;border-bottom:1px solid #ddd;
            img{
                height:30px;
                margin-right:.5rem;
            }
            a{text-decoration:none;}
        }
    }
    min-height:auto!important;
  }
  .profilItem{
    position:absolute;
    bottom:1rem;
  }
  a.disabled-link {
    pointer-events: none;
    opacity: .2;
  }
`;

const ColContent = styled(Col)`
    padding : 3rem 5rem;
    @media all and (max-width: 768px) {
    padding : 2rem;
    }
`;