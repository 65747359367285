import React from "react";
import { Link } from "react-router-dom";
/** composants **/
import { Container, Row, Col } from "reactstrap";
import Footer from './components/Footer';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { Navbar } from './annuaire/Navbar';
import CtaNotaire from './annuaire/CtaNotaire';



function Cgu(args) {
    return (
        <>
            <div>
            <Container className="relative">
            <Navbar
                        user={args.user}
                        banner={
                            <Link
                                to="https://clotere.webflow.io"
                                target="_blank"
                                className="no-underline flex items-center gap-1 rounded-full bg-fuchsia-950/35 px-3 py-0.5 text-sm/6 font-medium text-white data-[hover]:bg-fuchsia-950/30"
                            >
                                👋 Votre notaire en clair ! Trouvez les réponses à vos questions sur notre blog
                                <ChevronRightIcon className="size-4" />
                            </Link>
                        }
                    />
                
                    <Row>
                        <Col md="12" align="center">
                        <h1 className="mt-5 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Conditions générales d'utilisation</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3"></Col>
                        <Col md="6" className="mb-5">
                            <p class="c12">
                                <span class="c24">INTRODUCTION</span>
                            </p>
                            <p class="c12">
                                <span class="c17">La soci&eacute;t&eacute; COFIDELITY, SAS demeurant 2 mail ciboulette 93300 AUBERVILLIERS, au capital de 1500&euro; euros immatricul&eacute;e au RCS de BOBIGNY sous le N&deg; SIRET 80320717400035 Code APE 6612 Z, Portails internet, d&eacute;sign&eacute; ci-apr&egrave;s l&rsquo;OP&Eacute;RATEUR, &eacute;dite, diffuse et exploite la plateforme internet de transaction immobili&egrave;re accessible &agrave; l&rsquo;adresse URL clotere.fr, d&eacute;sign&eacute;e ci-apr&egrave;s comme &quot; la PLATEFORME &quot;.</span>
                            </p>
                            <p class="c12">
                                <span class="c17">Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente et d&rsquo;Utilisation (CGV-CGU) ont pour objet de d&eacute;finir les conditions dans lesquelles les internautes et les utilisateurs utilisent la PLATEFORME clotere.fr.</span>
                            </p>
                            <p class="c12">
                                <span class="c17">L&rsquo;UTILISATEUR s&rsquo;engage &agrave; lire attentivement les pr&eacute;sentes CONDITIONS G&Eacute;N&Eacute;RALES de VENTE et d&rsquo;UTILISATION (CGV-CGU) et &agrave; les accepter ; l&rsquo;utilisation des services vaut acceptation des CGV-CGU ; leur acceptation vaut bon pour mandat donn&eacute; &agrave; l&rsquo;OP&Eacute;RATEUR. Ce dernier invite l&rsquo;UTILISATEUR &agrave; les t&eacute;l&eacute;charger et &agrave; en conserver une copie, imprim&eacute;e ou num&eacute;rique. Les pr&eacute;sentes CONDITIONS G&Eacute;N&Eacute;RALES de VENTE et d&rsquo;UTILISATION sont r&eacute;f&eacute;renc&eacute;es en bas de la page d&rsquo;entr&eacute;e dans PLATEFORME au moyen d&rsquo;un lien hypertexte et peuvent ainsi &ecirc;tre consult&eacute;es &agrave; tout moment.</span>
                            </p>
                            <p class="c12">
                                <span class="c30">Important TOUTE UTILISATION DE LA PLATEFORME CLOTERE IMPLIQUE OBLIGATOIREMENT L&rsquo;ACCEPTATION PR&Eacute;ALABLE ET SANS RESERVE, PAR L&rsquo;UTILISATEUR, DES PR&Eacute;SENTES CONDITIONS G&Eacute;N&Eacute;RALES DE VENTE ET D&rsquo;UTILISATION. SI VOUS NE SOUHAITEZ </span>
                                <span class="c26"></span>
                                <span class="c17">&nbsp;ACCEPTER CES CONDITIONS G&Eacute;N&Eacute;RALES DE VENTE ET D&rsquo;UTILISATION, VOUS NE DEVEZ PAS UTILISER LA PLATEFORME CLOTERE.</span>
                            </p>
                            <h2 class="c7 c23" id="h.vt6xak20ws84"><span class="c10"></span></h2>
                            <h2 class="c7" id="h.9pxzg31fywbu"><span class="c29">1. Glossaire</span></h2>
                            <p class="c12">
                                <span class="c0">CONDITIONS G&Eacute;N&Eacute;RALES de VENTE et d&rsquo;UTILISATION (ou CGV-CGU) : l&rsquo;ensemble des dispositions contractuelles du pr&eacute;sent document, par lesquelles l&rsquo;UTILISATEUR donne mandat &agrave; l&rsquo;OP&Eacute;RATEUR de r&eacute;aliser, pour son compte, les services propos&eacute;s par la PLATEFORME</span>
                            </p>
                            <p class="c12">
                                <b>IDENTIFIANTS</b> : ensemble de cl&eacute;s de connexion, compos&eacute; d&rsquo;une adresse de courrier &eacute;lectronique (e-mail) personnelle et valide, renseign&eacute;e par l&#39;UTILISATEUR au moment de son inscription et du mot de passe personnel et confidentiel affect&eacute; de mani&egrave;re unique &agrave; cette adresse e-mail, qui permettent d&#39;acc&eacute;der aux SERVICES de la PLATEFORME.
                            </p>
                            <p class="c12">
                                <b>INTERNAUTE</b> : personne physique acc&eacute;dant &agrave; la PLATEFORME CLOTERE.
                            </p>
                            <p class="c12">
                                <b>OP&Eacute;RATEUR</b> : soci&eacute;t&eacute; COFIDELITY SAS, &eacute;diteur de la PLATEFORME CLOTERE
                            </p>
                            <p class="c12">
                                <b>PLATEFORME CLOTERE, PLATEFORME ou CLOTERE</b> : site internet clotere.fr quelle que soit sa version, ainsi que les applications correspondantes, proposant les services aux UTILISATEURS.
                            </p>
                            <p class="c12">
                                <b>UTILISATEUR</b> : personne physique utilisant les SERVICES de la PLATEFORME CLOTERE.
                            </p>
                            <p class="c12">
                                <b>SERVICE(S)</b> : ensemble des fonctionnalit&eacute;s accessibles &agrave; partir du site clotere.fr
                            </p>
                            <h2 class="c7" id="h.ojkybvnzp6f"><span class="c1">2. Objet</span></h2>
                            <p class="c12">
                                <span class="c0">La PLATEFORME CLOTERE a pour objet et pour finalit&eacute;s de proposer des services de mise en relation, de gestion de dossier de transactions immobili&egrave;res &agrave; des UTILISATEURS, particuliers (consommateurs) ou professionnels. </span>
                            </p>
                            <p class="c12">
                                <span class="c0">Les UTILISATEURS b&eacute;n&eacute;ficient des services propos&eacute;s par l&rsquo;OP&Eacute;RATEUR, d&eacute;crits par les pr&eacute;sentes. Les pr&eacute;sentes CONDITIONS G&Eacute;N&Eacute;RALES de VENTE et d&rsquo;UTILISATION (ou CGV-CGU) ont pour objet de r&eacute;gir l&rsquo;utilisation de la PLATEFORME par l&rsquo;UTILISATEUR auquel l&rsquo;OP&Eacute;RATEUR a librement confi&eacute; des identifiants de connexion. L&rsquo;OP&Eacute;RATEUR se r&eacute;serve la possibilit&eacute; de modifier &agrave; tout moment les pr&eacute;sentes CGU. Les nouvelles dispositions seront port&eacute;es &agrave; la connaissance des UTILISATEURS au moins quinze (15) jours avant leur date d&rsquo;entr&eacute;e en vigueur et en application.</span>
                            </p>
                            <h2 class="c7" id="h.eon8z8wt4rzr"><span class="c1">3. Acc&egrave;s et utilisation de la PLATEFORME CLOTERE</span></h2>
                            <p class="c12">
                                <span class="c0">En acceptant les CONDITIONS G&Eacute;N&Eacute;RALES de VENTE et d&rsquo;UTILISATION (ou CGV-CGU), l&rsquo;UTILISATEUR donne mandat &agrave; l&rsquo;OP&Eacute;RATEUR, qui l&rsquo;accepte, de r&eacute;aliser pour son compte les SERVICES que l&rsquo;UTILISATEUR sollicite aupr&egrave;s de celui-ci. La mise &agrave; disposition de la PLATEFORME donne lieu &agrave; l&rsquo;ouverture d&rsquo;un espace individuel personnel, accessible au moyen d&rsquo;identifiants.</span>
                            </p>
                            <p class="c12">
                                <span class="c0">L&rsquo;UTILISATEUR garantit et s&rsquo;assure de l&rsquo;exactitude des informations personnelles le concernant ; il signale les mises &agrave; jour n&eacute;cessaires &agrave; l&rsquo;OP&Eacute;RATEUR.</span>
                            </p>
                            <p class="c12">
                                <span class="c0">Dans le cas o&ugrave; les informations relatives &agrave; l&rsquo;UTILISATEUR ne seraient pas actualis&eacute;es, compl&egrave;tes et/ou exactes, l&rsquo;OP&Eacute;RATEUR dispose du droit soit de suspendre temporairement soit de r&eacute;silier d&eacute;finitivement l&rsquo;acc&egrave;s de l&rsquo;UTILISATEUR, sans d&eacute;lai ni pr&eacute;avis, apr&egrave;s une demande de rectification demeur&eacute;e sans r&eacute;ponse.</span>
                            </p>
                            <p class="c12">
                                <span class="c0">L&rsquo;UTILISATEUR s&rsquo;oblige &agrave; tenir les identifiants rigoureusement secrets, &agrave; prendre toute mesure pour en pr&eacute;server la confidentialit&eacute; et &agrave; avertir l&rsquo;OP&Eacute;RATEUR en cas d&rsquo;une utilisation non autoris&eacute;e de son espace individuel, d&egrave;s qu&rsquo;il en aura connaissance. De convention expresse entre l&rsquo;UTILISATEUR et l&rsquo;OP&Eacute;RATEUR, toutes les op&eacute;rations effectu&eacute;es au moyen des identifiants de l&rsquo;UTILISATEUR sont r&eacute;put&eacute;es &eacute;maner de l&rsquo;UTILISATEUR.</span>
                            </p>
                            <p class="c12">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR peut refuser ou suspendre &agrave; l&rsquo;acc&egrave;s de la PLATEFORME &agrave; un UTILISATEUR, pour des motifs l&eacute;gitimes.</span>
                            </p>
                            <h2 class="c7 c23" id="h.obeahol0d96s"><span class="c1"></span></h2>
                            <h2 class="c7" id="h.8c46ax1w0hxb"><span class="c1">4. Services de la PLATEFORME CLOTERE</span></h2>
                            <p class="c13">
                                <span class="c19"></span>
                            </p>
                            <p class="c28">
                                <span class="c0">Les principaux services propos&eacute;s aux UTILISATEURS sont : </span>
                            </p>
                            <ul class="c9 lst-kix_dprriwsuo0pp-0 start">
                                <li class="c2 li-bullet-0"><span class="c0">Des informations relatives aux notaires de France</span></li>
                                <li class="c2 li-bullet-0"><span class="c0">La collecte de donn&eacute;es n&eacute;cessaires &agrave; l&rsquo;analyse et la gestion de dossier de transactions immobili&egrave;res</span></li>
                                <li class="c2 li-bullet-0"><span class="c0">L&rsquo;analyse de documents relatifs aux transactions immobili&egrave;res</span></li>
                                <li class="c2 li-bullet-0"><span class="c0">Des simulateurs de frais de notaire</span></li>
                            </ul>
                            <p class="c13">
                                <span class="c0"></span>
                            </p>
                            <p class="c28">
                                <span class="c0">La gestion des dossiers de transactions immobili&egrave;res n&eacute;cessitent que l&rsquo;UTILISATEUR donne des informations sur sa transaction immobili&egrave;re et transmettre des documents relatifs &agrave; cette transaction. Il autorise donc la PLATEFORME a collecter ces documents et &agrave; les transf&eacute;rer aux diff&eacute;rents INTERM&Eacute;DIAIRES de la PLATEFORME.</span>
                            </p>
                            <p class="c12">
                                <span class="c0">La gestion des transactions immobili&egrave;res permettent :</span>
                            </p>
                            <ul class="c9 lst-kix_qnksvjryr3r1-0 start">
                                <li class="c20 c2 li-bullet-0"><span class="c0">La pr&eacute;sentation, la proposition et l&rsquo;aide &agrave; la gestion de son dossier de vente immobili&egrave;re</span></li>
                                <li class="c20 c2 li-bullet-0"><span class="c0">La mise en relation des UTILISATEURS avec des notaires.</span></li>
                            </ul>
                            <h2 class="c7 c23" id="h.e29kbg6c38my"><span class="c1"></span></h2>
                            <h2 class="c7" id="h.p8j4drg3d9b7"><span class="c1">5. Obligations de l&rsquo;UTILISATEUR</span></h2>
                            <p class="c4">
                                <span class="c0">Chaque UTILISATEUR s&rsquo;engage &agrave; utiliser les services conform&eacute;ment &agrave; leur objet et leur destination, &agrave; ne pas porter atteinte &agrave; l&rsquo;ordre public et &agrave; se conformer aux lois et r&egrave;glements en vigueur, &agrave; respecter les droits des tiers et les dispositions des pr&eacute;sentes CGV-CGU.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">Notamment, chaque UTILISATEUR s&rsquo;engage &agrave; :</span>
                            </p>
                            <ul class="c9 lst-kix_smnfihkqjxoa-0 start">
                                <li class="c4 c5 li-bullet-0"><span class="c0">Se comporter de fa&ccedil;on loyale &agrave; l&rsquo;&eacute;gard de l&rsquo;OP&Eacute;RATEUR, &agrave; l&rsquo;&eacute;gard des autres UTILISATEURS ainsi qu&rsquo;&agrave; l&rsquo;&eacute;gard des tiers ;</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Communiquer des informations et des donn&eacute;es &agrave; l&rsquo;OP&Eacute;RATEUR de mani&egrave;re honn&ecirc;te et sinc&egrave;re,</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Utiliser la PLATEFORME conform&eacute;ment &agrave; son objet tel que d&eacute;crit dans les pr&eacute;sentes CGV-CGU ;</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Ne pas d&eacute;tourner la finalit&eacute; de la PLATEFORME en vue de commettre des crimes, d&eacute;lits ou contraventions r&eacute;prim&eacute;es par la Loi, notamment par le Code p&eacute;nal ;</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Ne pas chercher &agrave; porter atteinte, au sens des articles 323-1 et suivants du Code p&eacute;nal, aux syst&egrave;mes de traitement automatis&eacute;s de donn&eacute;es mis en &oelig;uvre par la PLATEFORME;</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Ne pas diffuser de donn&eacute;es ayant pour effet de diminuer, de d&eacute;sorganiser, de ralentir ou d&rsquo;interrompre le fonctionnement de la PLATEFORME ;</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Ne pas diffuser de message ou d&rsquo;information, visant soit l&rsquo;OP&Eacute;RATEUR, soit un tiers, qui serait constitutifs : de d&eacute;nigrement fautif, contraires &agrave; l&rsquo;ordre public, &agrave; caract&egrave;re injurieux ou diffamatoire, portant atteinte &agrave; l&rsquo;honneur ou &agrave; la r&eacute;putation d&rsquo;autrui, incitant &agrave; la discrimination, incitant &agrave; commettre des infractions, &agrave; exploiter tout outil logiciel ou autre permettant de porter atteinte aux droits d&#39;autrui et &agrave; la s&eacute;curit&eacute; des personnes et des biens.</span></li>
                            </ul>
                            <p class="c4">
                                <span class="c0">En pr&eacute;sence de deux l&rsquo;utilisateurs, co-acheteurs ou co-vendeurs, l&rsquo;UTILISATEUR qui proc&egrave;de &agrave; l&rsquo;inscription aux services de la PLATEFORME fait son affaire de recevoir mandat de l&rsquo;autre candidat co-acheteur ou co-vendeur pour renseigner de mani&egrave;re exacte et loyale les informations et les donn&eacute;es personnelles de ce dernier, qui les contr&ocirc;le et les valide.</span>
                            </p>
                            <p class="c3">
                                <span class="c0"></span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR attire l&rsquo;attention de l&rsquo;UTILISATEUR sur le fait que la cr&eacute;ation du dossier de transaction immobili&egrave;re pour le compte d&rsquo;un couple peut s&rsquo;effectuer &agrave; la demande d&rsquo;un seul UTILISATEUR. </span>
                            </p>
                            <p class="c4">
                                <span class="c0">Tout manquement au pr&eacute;sent article pourra entra&icirc;ner la suspension temporaire ou d&eacute;finitive de l&rsquo;acc&egrave;s de l&rsquo;UTILISATEUR concern&eacute; &agrave; la PLATEFORME.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;UTILISATEUR est seul responsable de l&rsquo;utilisation de la PLATEFORME par ses soins, ainsi que des cons&eacute;quences de cette utilisation. L&rsquo;UTILISATEUR est, seul, responsable des terminaux informatiques, ordinateurs, smartphones, tablettes, t&eacute;l&eacute;phones et autres instruments connect&eacute;s ou non, mobiles ou fixes, quelle que soit leur nature, choisis et utilis&eacute;s par ses soins pour acc&eacute;der au site de l&rsquo;OP&Eacute;RATEUR. De m&ecirc;me, il est seul responsable des syst&egrave;mes d&rsquo;exploitation choisis pour leur emploi, ainsi que du choix de services d&rsquo;acc&egrave;s au r&eacute;seau internet. L&rsquo;UTILISATEUR fait son affaire de l&rsquo;usage du r&eacute;seau internet, dont il d&eacute;clare comprendre les principes et les limites. Il lui revient de prot&eacute;ger ses terminaux et les contenus auxquels il acc&egrave;de, et de prendre toutes les mesures appropri&eacute;es pour les prot&eacute;ger d&rsquo;&eacute;ventuels virus. L&rsquo;UTILISATEUR assume personnellement le co&ucirc;t de ces dispositifs.</span>
                            </p>
                            <p class="c3">
                                <span class="c0"></span>
                            </p>
                            <h2 class="c7" id="h.pslmaptm7mbb"><span class="c1">6. Les obligations de l&rsquo;OP&Eacute;RATEUR</span></h2>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR propose aux UTILISATEURS un contenu informatif sous diff&eacute;rentes formes : notamment des articles, des infographies, des simulations financi&egrave;res, des listes de documents, des annuaires et coordonn&eacute;es de professionnels. Ces contenus visent &agrave; fournir aux UTILISATEURS des informations pratiques, &agrave; am&eacute;liorer leurs connaissances g&eacute;n&eacute;rales des actes notari&eacute;s et de les aider &agrave; pr&eacute;parer leurs dossiers de transaction immobili&egrave;re et &agrave; bien conduire leurs projets</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR s&eacute;lectionne puis d&eacute;livre ces informations &agrave; titre purement indicatif ; en aucun cas le contenu pr&eacute;sent&eacute; n&rsquo;a vocation &agrave; &ecirc;tre exhaustif. Compte tenu de l&rsquo;&eacute;volution permanente du secteur des notaires et de ses r&egrave;gles juridiques, l&rsquo;OP&Eacute;RATEUR ne garantit en aucune fa&ccedil;on ni l&rsquo;exactitude ni l&rsquo;actualisation des informations fournies. De plus, la pertinence de donn&eacute;es aux sujets des notaires s&rsquo;appr&eacute;cie individuellement, en regard de chaque situation particuli&egrave;re d&rsquo;un UTILISATEUR, notamment de son profil, de ses attentes, de ses besoins et de la nature de son projet d&rsquo;acquisition immobili&egrave;re.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">En particulier, les listes de notaires pr&eacute;sent&eacute;es par le site avec des indications de comp&eacute;tences et d&rsquo;utilisation de service Clotere par le professionnel, sont fournies &agrave; titre purement indicatif et informatif. Ces listes et recommandations de la plateforme ne constituent en aucun cas un choix &agrave; la place de L&rsquo;UTILISATEUR. Ce ne sont que des informations factuelles visant &agrave; aider la d&eacute;cision de l&rsquo;UTILISATEUR sur le choix d&rsquo;un professionnel du droit, en l&rsquo;occurence un notaire, &nbsp;pour g&eacute;rer sa transaction immobili&egrave;re, ou pour effectuer toutes d&eacute;marches &eacute;tant dans le champs de comp&eacute;tences d&rsquo;un notaire. Seul l&rsquo;UTILISATEUR fait un choix.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR met donc en garde chaque UTILISATEUR contre l&rsquo;usage inad&eacute;quat qui pourrait &ecirc;tre fait des informations g&eacute;n&eacute;rales et non individualis&eacute;es diffus&eacute;es par la PLATEFORME.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">Agissant en tant qu&rsquo;Interm&eacute;diaire en op&eacute;rations notariales de gestion de dossier de transaction immobili&egrave;res, l&rsquo;OP&Eacute;RATEUR est soumis &agrave; une obligation g&eacute;n&eacute;rale de moyens et n&rsquo;est tenu d&rsquo;aucune obligation de r&eacute;sultat. Il d&eacute;livre les obligations pr&eacute;vues par la Loi sp&eacute;cifiquement &agrave; ses activit&eacute;s. Aucun UTILISATEUR, en aucun cas, ne pourra rechercher la responsabilit&eacute; de l&rsquo;OP&Eacute;RATEUR, ni de ses partenaires ni de ses pr&eacute;pos&eacute;s, au titre d&rsquo;informations indicatives propos&eacute;es par le site clotere.fr.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR fait sans cesse de son mieux pour assurer la disponibilit&eacute; des services et l&rsquo;int&eacute;grit&eacute; des communications, sans d&eacute;livrer aucune garantie sur ces points.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR assure la maintenance &eacute;volutive de la PLATEFORME et informe les UTILISATEURS par tout moyen pr&eacute;alablement au passage d&rsquo;une nouvelle version. Il n&rsquo;existe aucun droit au maintien de versions ant&eacute;rieures de la PLATEFORME.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;acc&egrave;s de l&rsquo;UTILISATEUR aux services peut occasionnellement &ecirc;tre suspendu ou limit&eacute; pour permettre des r&eacute;parations, effectuer la maintenance technique, ou pour ajouter une nouvelle fonctionnalit&eacute; ou un nouveau service. L&rsquo;OP&Eacute;RATEUR fait de son mieux pour limiter la fr&eacute;quence et la dur&eacute;e de ces suspensions ou de ces limitations.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR s&rsquo;engage &agrave; mettre tout en &oelig;uvre pour :</span>
                            </p>
                            <ul class="c9 lst-kix_x04u4g8cszpq-0 start">
                                <li class="c4 c5 li-bullet-0"><span class="c0">Identifier les risques susceptibles d&rsquo;affecter la s&eacute;curit&eacute; des r&eacute;seaux,</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Assurer une s&eacute;curit&eacute; logique et physique de ses syst&egrave;mes d&rsquo;information ;</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">R&eacute;duire au minimum le risque d&#39;une infraction de s&eacute;curit&eacute;.</span></li>
                            </ul>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR dispose de la qualit&eacute; d&rsquo;&eacute;diteur, au sens de l&rsquo;article 6 III de la loi 2004-575 du 21 juin 2004 pour la confiance en l&rsquo;&eacute;conomie num&eacute;rique. Il &eacute;dite un service de communication en ligne &agrave; titre professionnel et d&eacute;termine les contenus mis &agrave; la disposition du public par ce service de communication.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR ne pourra voir sa responsabilit&eacute; engag&eacute;e :</span>
                            </p>
                            <ul class="c9 lst-kix_zhndkczf7ztv-0 start">
                                <li class="c4 c5 li-bullet-0"><span class="c0">en cas d&rsquo;interruption, momentan&eacute;e ou d&eacute;finitive, de l&rsquo;acc&egrave;s et de la disponibilit&eacute; du site clotere.fr, notamment pour des besoins de maintenance technique, d&rsquo;actualisation des informations publi&eacute;es ou de toute &eacute;volution n&eacute;cessitant l&rsquo;interruption du service ;</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">ce, quels que soient les dommages &eacute;ventuels caus&eacute;s &agrave; l&rsquo;UTILISATEUR qui r&eacute;sulterait des contenus, de l&rsquo;impossibilit&eacute; d&rsquo;acc&egrave;s, ou de l&rsquo;utilisation du site clotere.fr</span></li>
                            </ul>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR ne pourra &ecirc;tre tenu responsable de l&#39;inex&eacute;cution de l&#39;une de ses obligations du fait de la survenance d&#39;un cas de force majeure entendu comme un &eacute;v&eacute;nement : </span>
                            </p>
                            <ul class="c9 lst-kix_s8jxlguieu82-0 start">
                                <li class="c4 c5 li-bullet-0"><span class="c0">- &eacute;chappant au contr&ocirc;le de celui qui le subit, </span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">- qui ne pouvait &ecirc;tre raisonnablement pr&eacute;vu lors de la conclusion des CGU et </span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">- dont les effets ne peuvent &ecirc;tre &eacute;vit&eacute;s par des mesures appropri&eacute;es.</span></li>
                            </ul>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR est en r&egrave;gle du point de vue de ses diff&eacute;rentes obligations, qu&rsquo;il s&rsquo;agisse de son immatriculation (Registre du Commerce et des Soci&eacute;t&eacute;s, immatriculation administrative au Registre unique national des Interm&eacute;diaires), ou de ses d&eacute;clarations sociales et fiscales.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR est soumis au secret professionnel, applicable aux professionnels du notariat de l&rsquo;immobilier et du droit. Les informations ainsi que les donn&eacute;es personnelles communiqu&eacute;es par les UTILISATEURS ne sont jamais confi&eacute;es &agrave; des tiers. Pour l&rsquo;ex&eacute;cution des services de la PLATEFORME, &agrave; la demande des UTILISATEURS, certaines informations et donn&eacute;es personnelles font l&rsquo;objet d&rsquo;une communication &agrave; des prestataires ou &agrave; d&rsquo;autres professionnels du notariats et de l&rsquo;immobilier, eux-m&ecirc;mes soumis au secret professionnel et &agrave; une stricte interdiction d&rsquo;&eacute;changer ces donn&eacute;es.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR met &agrave; disposition de l&rsquo;UTILISATEUR un service d&rsquo;assistance disponible &agrave; l&rsquo;adresse mail contact@clotere.fr selon les plages horaires suivantes : du lundi au venderedi de 9h &agrave; 18h.</span>
                            </p>
                            <p class="c3">
                                <span class="c0"></span>
                            </p>
                            <h2 class="c7" id="h.g8xtx39g67sy"><span class="c1">7. Donn&eacute;es personnelles &amp; vie priv&eacute;e</span></h2>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR collecte et utilise les donn&eacute;es personnelles afin de fournir les services d&eacute;crits. La collecte et le traitement de ces donn&eacute;es sont indispensables &agrave; la d&eacute;livrance de ces services.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">La mission de l&rsquo;OP&Eacute;RATEUR est de vous pr&eacute;senter de la mani&egrave;re la plus simple l&rsquo;ensemble des actions, documents, t&acirc;ches &agrave; effectuer pour mener &agrave; bien votre transaction immobili&egrave;re. Dans ce cadre, vous allez &ecirc;tre amen&eacute; &agrave; nous communiquer des informations d&eacute;taill&eacute;es sur votre situation personnelle, financi&egrave;re et votre projet. Ces informations nous permettent d&#39;identifier le ou les professionnels les plus adapt&eacute;es &agrave; votre projet et de vous recommander certains d&rsquo;entre eux.Nous ne vous demandons pas d&#39;information qui n&#39;est pas directement utile &agrave; cette mission.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">Notamment :</span>
                            </p>
                            <ul class="c9 lst-kix_u1d2h8lvunm3-0 start">
                                <li class="c4 c5 li-bullet-0"><span class="c0">Nous ne transmettons aucune information aux notaires sans votre accord</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Nous ne vendons et ne transmettons vos informations &agrave; aucun partenaire commercial sans votre accord</span></li>
                                <li class="c4 c5 li-bullet-0"><span class="c0">Vous pouvez acc&eacute;der &agrave; vos donn&eacute;es et demander leur suppression &agrave; tout moment</span></li>
                            </ul>
                            <h4 class="c16" id="h.a2stbiorn9jn"><span class="c15">Propri&eacute;taire et Responsable du traitement</span></h4>
                            <p class="c16" id="h.v4visif49jxw">
                                <span class="c31">Cofidelity</span>
                                <span class="c22">&nbsp;SAS - </span>
                                <span class="c14">privacy@</span>
                                <span class="c21">clotere</span>
                                <span class="c14 c25">.fr</span>
                            </p>
                            <h4 class="c16" id="h.8xyurm69ne96"><span class="c15">D&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es</span></h4>
                            <p class="c4">
                                <span class="c14">dpo@clotere.fr</span>
                            </p>
                            <p class="c3">
                                <span class="c0"></span>
                            </p>
                            <h2 class="c7" id="h.64wflgny6h0j"><span class="c1">9. Droits et propri&eacute;t&eacute; intellectuelle</span></h2>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR dispose et conserve la propri&eacute;t&eacute; des connaissances, des savoir-faire, des proc&eacute;d&eacute;s, des informations, des donn&eacute;es techniques, industrielles ou commerciales, des fournitures, des images, dessins et photographies, des graphismes, des sons, des bases de donn&eacute;es, des formulaires, des logiciels et des autres cr&eacute;ations qu&rsquo;il utilise pour exploiter la PLATEFORME. Certaines photos ou illustrations utilis&eacute;es par la PLATEFORME sont issues de sources libres de droit comme flaticon, Unsplash.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">Les marques &quot; COFIDELITY &quot; et &quot; CLOTERE &quot;, incluant leurs &eacute;l&eacute;ments graphiques et leurs logos associ&eacute;s sont d&eacute;pos&eacute;s par COFIDELITY SAS. L&rsquo;OP&Eacute;RATEUR dispose du droit de les exploiter.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">Toute reproduction m&ecirc;me partielle de l&rsquo;un des &eacute;l&eacute;ments mentionn&eacute;s ci-dessus est interdite, sauf consentement &eacute;crit pr&eacute;alable de l&rsquo;OP&Eacute;RATEUR.</span>
                            </p>
                            <p class="c4">
                                <span class="c18">Liens de clotere.fr</span>
                                <span class="c0">&nbsp;vers des sites tiers. En compl&eacute;ment des contenus mis &agrave; disposition par l&rsquo;OP&Eacute;RATEUR, des liens vers des sites tiers peuvent &ecirc;tre propos&eacute;s aux utilisateurs &agrave; partir du site Clotere. En aucun cas l&rsquo;OP&Eacute;RATEUR ne recommande l&rsquo;application des conseils ni l&rsquo;utilisation des &eacute;ventuels services fournis par ces sites tiers, ces liens &eacute;tant mis &agrave; disposition de l&rsquo;UTILISATEUR &agrave; titre de simple information. L&rsquo;OP&Eacute;RATEUR ne contr&ocirc;le en aucune fa&ccedil;on le contenu de ces sites, ni leur exactitude, ni leur mise &agrave; jour, ni leur caract&egrave;re actuel et loyal, qui rel&egrave;vent de la seule et enti&egrave;re responsabilit&eacute; des personnes auteurs ou propri&eacute;taires de ces sites et ne sera pas tenu pour responsable de tout &eacute;ventuel contenu illicite mis &agrave; disposition par ces sites de tiers. L&rsquo;UTILISATEUR est seul responsable de la d&eacute;cision d&rsquo;activation de ces liens.</span>
                            </p>
                            <p class="c3">
                                <span class="c0"></span>
                            </p>
                            <h2 class="c7" id="h.rblf4ubr63ot"><span class="c1">10. Voies de recours, r&eacute;clamations et m&eacute;diation de la consommation</span></h2>
                            <p class="c4">
                                <span class="c0">Toute r&eacute;clamation peut &ecirc;tre adress&eacute;e :</span>
                            </p>
                            <ul class="c9 lst-kix_21wivtyh7u19-0 start">
                                <li class="c20 c2 li-bullet-0">
                                    <span class="c22">soit par voie postale &agrave; : COFIDELITY SAS, </span>
                                    <span class="c30">2 mail ciboulette 93300 AUBERVILLIERS</span>
                                </li>
                                <li class="c2 c20 li-bullet-0"><span class="c0">soit par courrier &eacute;lectronique (e-mail) &agrave; : contact@clotere.fr</span></li>
                                <li class="c20 c2 li-bullet-0"><span class="c0">soit par tout moyen &agrave; la convenance de l&rsquo;UTILISATEUR, procurant une date certaine &agrave; son message, avec la certitude de son bon acheminement vers l&rsquo;OP&Eacute;RATEUR.</span></li>
                            </ul>
                            <h2 class="c7" id="h.gm20aijpikls"><span class="c1">11. Droit applicable</span></h2>
                            <p class="c4">
                                <span class="c0">Les pr&eacute;sentes CGC-CGU sont soumises au droit fran&ccedil;ais. Elles sont r&eacute;dig&eacute;es en fran&ccedil;ais.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">Si l&rsquo;une quelconque des stipulations des pr&eacute;sentes CGV-CGU venait &agrave; &ecirc;tre d&eacute;clar&eacute;e invalide, nulle ou inapplicable au regard d&rsquo;une disposition l&eacute;gislative ou r&eacute;glementaire en vigueur et/ou d&rsquo;une d&eacute;cision de justice ayant autorit&eacute; de la chose jug&eacute;e, quelle qu&rsquo;en soit la raison, elle sera r&eacute;put&eacute;e non-&eacute;crite mais, &eacute;tant divisible, n&rsquo;affectera en rien ni la validit&eacute; ni l&rsquo;opposabilit&eacute; des autres clauses restantes qui demeureront pleinement applicables.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">L&rsquo;OP&Eacute;RATEUR dispose du droit de mettre &agrave; jour et modifier &agrave; tout moment les pr&eacute;sentes CGV-CGU &agrave; tout moment, notamment pour les adapter &agrave; toute &eacute;volution l&eacute;gislative, r&eacute;glementaire, ou professionnelle.</span>
                            </p>
                            <p class="c4">
                                <span class="c0">Les CGV-CGU applicables sont celles en vigueur au moment de l&rsquo;utilisation du site internet par l&rsquo;UTILISATEUR.</span>
                            </p>
                            <h2 class="c7" id="h.cq1auejq0q13"><span class="c1">13. Site clotere.fr</span></h2>
                            <p class="c4">
                                <span class="c0">Le site clotere.fr est &eacute;dit&eacute; par la Soci&eacute;t&eacute; COFIDELITY SAS. Directrice de la publication : Madame C&eacute;cile CHELIM. H&eacute;bergement : OVH 2 rue Kellermann BP 80157, Roubaix CEDEX 1, Tel : 07 57 83 13 64</span>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <CtaNotaire />
                <Footer />
            </div>

        </>
    );
}

export default Cgu;