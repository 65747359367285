import React from "react";

const list = [
  {
    name: 'Identité des parties'
  },
  {
    name: 'Description du ou des biens donnés',
  },
  {
    name: 'Conditions de la donation',
  },
  {
    name: 'Mention des droits de succession ou des avantages fiscaux',
  },
  {
    name: 'Engagement du donateur',
  },
  {
    name: 'Date et signatures',
  },
]

function ActeDonationContenu() {
  return (

    <div className="py-4 sm:py-4">
      <h4 className="text-base font-medium">Que contient l'acte de donation ?</h4>
      <ol class="space-y-5 pl-0">
      
          {list.map((item) => (
              <li key={item.name} class="text-body-color dark:text-dark-6 flex text-lg">
                <span
                  class="bg-primary mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded text-base text-white"
                  >
                <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
               d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2812 11.0312 17.2812C11.2187 17.2812 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
               fill="currentColor"
               />
         </svg>
                </span>
               {item.name}
            </li>
          ))}
        </ol>
    </div>
  );
}

export default ActeDonationContenu;