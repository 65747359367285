import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import { Container, Row, Col,Alert, ListGroup, ListGroupItem,  } from "reactstrap";
import styled from "styled-components";
import logoClotere from "./img/logo-clotere.svg";
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

const Logo = styled.img`
width:180px;

`;
const HomeS = styled.div`
min-height: 100vh;
.avantage,.formulaire{padding:6rem 4rem;min-height: 100vh;}
.formulaire{background-color:#fff!important;}
.mobile{display:none;}
.alert{border:0;margin:1rem 0;}
.btn-main{margin:0 auto;}
@media all and (max-width: 768px) {
    .avantage{
        display:none;
    }
    .mobile{
        display:block;
        
        img{display:none;}
    }
    .mobile,.formulaire{
        padding:2rem;
    }
    .formulaire{
        min-height: auto;
    }
`;
const ListGroupAvantages = styled(ListGroup)`
border:0;
li.list-group-item{
    border:0;
    margin:.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-weight:bold;
    span{
        margin-right:10px;
        background-color:#FFF;
        padding:1rem;
        border-radius:10px;
        margin-right:15px;
    }
}
`;




function Inscription(args, props) {
    const memberstack = useMemberstack();
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [member, setMember] = useState(null);
    const [inscriptionDone, setInscriptionDone] = useState(false);
    const [prenom, setPrenom] = useState(null);
    const [nom, setNom] = useState(null);
    const navigate = useNavigate();

    const { type } = useParams();
    //console.log("type",type);

    const sendToZapier = async () => {
        const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/298usv1/"; // 👈 Specify your Zapier Webhook URL here
        try {
          const response = await fetch(zapierURL, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({member,type,prenom,nom})
          });
          const resp = await response.json();
          console.log(resp);
        } catch (e) {
          console.log(e);
        }
      };

      useEffect(() => {
        sendToZapier(); 
    }, [inscriptionDone]);

      
    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    if (member) {
        if(type === "donation"){
            navigate("/app/onboard/donation");
        }else if (type === "achat"){
            navigate("/app/onboard/achat");
        }else if (type === "vente"){
            navigate("/app/onboard/vente");
        }else{
            navigate("/app/onboard");
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const nom = event.target.nom.value;
        const prenom = event.target.prenom.value;
        const email = event.target.email.value;
        const password = event.target.password.value;
       {memberstack.signupMemberEmailPassword({
            email: email,
            password: password
            
        }).then(result => {
            console.log("result", result);
            setMember(result.data.member);
            setInscriptionDone(true);
        })
       .catch(er => {
            //console.log("erreur sent email", er);
            setFormError(true);
            setFormErrorMessage("Une erreur s'est produite veuillez recommencer");
        });
    }
}

    return (
        <>
            {!member && (
                <div>
                    <HomeS className="bg-gradient-to-t from-teal-100 to-teal-50">
                        <Row>
                            <Col md="5" className="avantage">
                                <Container>
                                    <div className="lg:pr-8 lg:pt-4">
                                        <div className="lg:max-w-lg">
                                            <Link to="/"><Logo alt="Clotere"
                                                src={logoClotere}></Logo></Link><br />
                                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Simplifiez votre passage chez le notaire</p>
                                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                                Suivez l'avancement de votre dossier chez le notaire à un seul et même endroit.
                                            </p>
                                            <dl className="grid grid-cols-1 gap-x-8 gap-y-8 lg:gap-y-8">
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Suivez l'avancement de votre dossier simplement.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Regroupez tous vos échanges au même endroit.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Trouvez un notaire plus rapidement.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Fixez vos rendez-vous simplement avec votre notaire.
                                                    </dt>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </Container>
                            </Col>

                            <Col md="7" xs="0" className="formulaire">
                                <Container>
                                        <div className="d-flex flex-column ">
                                            <Link to="/" className="block mx-auto sm:hidden mb-4"><Logo alt="Clotere" src={logoClotere} ></Logo></Link>
                                            <h3 className="mt-3 mb-3">Qui effectue la démarche sur <span className="textHighlight">Clotere</span> ?</h3>
                                            <p className="text-sm">Ces informations nous permettront de vous assister au cours de votre démarche, et seront nécessaires pour constituer votre dossier.</p>
                                        </div>
                                        <form action="#" method="POST" data-ms-form="signup" className="form-signup space-y-6" onSubmit={handleSubmit}>
                                        {formError ? (
                                                    <Alert color="danger">
                                                        {formErrorMessage}
                                                    </Alert>
                                                ) : (<>{" "}</>)}
                                            <div>
                                                <label htmlFor="nom" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Votre nom
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="nom"
                                                        name="nom"
                                                        type="text"
                                                        required
                                                        className="input-clotere"
                                                        data-ms-member="nom"
                                                        onChange={e => setNom(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="prenom" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Votre prénom
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="prenom"
                                                        name="prenom"
                                                        type="text"
                                                        required
                                                        className="input-clotere"
                                                        data-ms-member="prenom"
                                                        onChange={e => setPrenom(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Votre email
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        required
                                                        className="input-clotere"
                                                        data-ms-member="email"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Votre mot de passe
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="password"
                                                        name="password"
                                                        type="password"
                                                        required
                                                        className="input-clotere"
                                                        data-ms-member="password"
                                                        minlength="8"
                                                    />
                                                    <p id="email-description" className="mt-2 text-sm text-gray-500">
                                                        Minimum 8 caractères
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                            <button
                                                type="submit"
                                                className="btn-primary"
                                            >
                                                Valider
                                            </button>
                                            </div>
                                            
                                        </form>
                                </Container>
                            </Col>
                            <Col xs="12" className="avantage mobile">
                                <Container>
                                    <Logo alt="Clotere"
                                        src={logoClotere}></Logo>
                                    <br /><br />
                                    <h4>Clotere vous facilite le passage chez le notaire</h4>
                                    <ListGroupAvantages>
                                        <ListGroupItem>
                                            <span>🥳</span> Suivez votre transaction immobilière facilement
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>👌</span> Regroupez tous vos échanges au même endroit
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>🔥</span> Trouvez un notaire rapidement
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>📆</span> Fixer vos rendez-vous avec votre notaire
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>📨</span> Réalisez vos signatures d'acte en ligne de manière sécurisée
                                        </ListGroupItem>
                                    </ListGroupAvantages>
                                </Container>
                            </Col>
                        </Row>
                    </HomeS>
                </div>
            )}
        </>
    );
}

export default Inscription;