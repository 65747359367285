import React from "react";

import { Navbar } from './Navbar';
/** composants **/
import HeaderNew from './components/HeaderNew';
import CommentCaMarche from './components/CommentCaMarche';
import NotaireEnClair from "./blog/NotaireEnClair";
import CtaNotaire from './annuaire/CtaNotaire';
import Faq from './components/Faq';
import BlocCta from './components/BlocCta';
import Footer from './components/Footer';
import { useWindowWidth } from "./style/breakpoints";
import PourquoiClotereNew from "./annuaire/PourquoiClotereNew";
import { Helmet } from "react-helmet";



function Home(args) {
    const screenWidth = useWindowWidth();
    return (
        <>
        <Helmet>
            <title>Clotere.fr simplifie votre passage chez le notaire | Donation, achat vente immobilière</title>
            <meta name="robots" content="index,follow"/>
            <meta name="googlebot" content="index,follow"/>
            <meta name='description' content='Clotere.fr vous permet de gérer et simplifier vos démarches chez le notaire en ligne : faire une donation, trouver un notaire pour acheter un bien immobilier, vendre son bien immobilier, trouver un notaire, etc' />
            <meta property="og:url" content="https://clotere.fr"/>
            <meta property="og:site_name" content="Clotere"/>
            <meta property="og:image" content="https://clotere.fr/facebook-share-webp"/>
            <meta property="og:type" content="website"/>
            </Helmet> 
        <div className={`bg-[#43cdbb0f] ${screenWidth >= 1440 ? "w-full" : ""} ${screenWidth < 1440 ? "min-w-[412px]" : ""} ${screenWidth < 1440 ? "flex-col" : (screenWidth >= 1440) ? "flex-row" : ""} ${screenWidth < 1440 ? "items-center" : ""} ${screenWidth < 1440 ? "px-0 py-[47px]" : ""} ${screenWidth < 1440 ? "overflow-hidden" : ""} ${screenWidth >= 1440 ? "justify-center" : ""} ${screenWidth < 1440 ? "relative" : ""}`}>
            <Navbar user={args.user} />
            <HeaderNew/>
            <PourquoiClotereNew/>
            <CommentCaMarche/>
            <NotaireEnClair  />
            <BlocCta/>
            <Faq/>
            <CtaNotaire/>
            <Footer/>
        </div>
        </>
    );
}

export default Home;