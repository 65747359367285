import React from "react";
/** style  */
import { Container } from "../annuaire/Container";


const posts = [
  {
    id: 1,
    title: 'Les frais de notaire sont-ils négociables ?',
    href: 'https://clotere.com/les-frais-de-notaire-sont-ils-negociables/',
    description:"Allant jusqu'à 6% du prix d'achat de votre bien, les frais de notaire représente un poste de dépense important. Bien anticipé ce coût est important, on vous explique tout sur ces frais de notaire.",
     imageUrl:
      'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/frais-notaire-negociable.png?resize=600%2C403&ssl=1',
    category: { title: 'Comprendre les frais de notaire dans l\'immobilier', href: '#' },
  },
  {
    id: 2,
    title: 'Les clauses suspensives, finalement qu\'est-ce que c\'est ?',
    href: 'https://clotere.com/notaire-immobilier/les-clauses-suspensives-qu-est-ce-que-c-est/',
    description:"Si vous achetez un bien immobilier vous entendrez forcément parlé des clauses suspensives 🤔 ! Ne vous inquiétez pas c'est très simple, on vous explique tout dans cet article.",
     imageUrl:
      'https://clotere.com/wp-content/uploads/2024/10/clauses-suspensives.webp',
    category: { title: 'Les clauses suspensives, finalement qu\'est-ce que c\'est ?', href: '#' },
  },
  {
    id: 3,
    title: 'Comment choisir son notaire pour son achat immobilier ?',
    href: 'https://clotere.com/comment-trouver-un-notaire-achat-immobilier/',
    description:"Acquérir un bien immobilier requiert toujours l’intervention d’un notaire, au moins pour réaliser l’acte de vente. Comment faire le choix d’un notaire pour un achat immobilier ? Découvrez nos conseils.",
     imageUrl:
      'https://i0.wp.com/clotere.com/wp-content/uploads/2023/03/comment-choisir-son-notaire.webp?resize=600%2C399&ssl=1',
    category: { title: 'Comment choisir son notaire pour son achat immobilier ?', href: '#' },
  },

]

function NotaireEnClair(args) {

  return (
    <div className="bg-white">
    <Container>
    <div className="py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            Le notaire en clair !
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Améliorer le passage chez le notaire ça passe ausi et surtout par l'explication !<br/>
            Notre blog vous guide dans vos démarches chez le notaire 👇
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
            >
              <img alt="" src={post.imageUrl} className="absolute inset-0 -z-10 h-full w-full object-cover" />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                
                <div className="-ml-4 flex items-center gap-x-4">
                  <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  <div className="flex gap-x-2.5">
                    <img alt="" src="http://localhost:3000/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                    Clotere
                  </div>
                </div>
              </div>
              <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                <a href={post.href}  target="blank" className="text-white no-underline">
                  <span className="absolute inset-0" />
                  {post.title}
                </a>
              </h3>
            </article>
          ))}
        </div>
      </div>
    </div>
    </Container>
    </div>
  );
}

export default NotaireEnClair;