import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Form, Label, Input, Alert, ListGroup, ListGroupItem, Offcanvas, OffcanvasHeader, OffcanvasBody, } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import logoClotere from "./img/logo-clotere.svg";
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

const Logo = styled.img`
width:180px;

`;
const HomeS = styled.div`
min-height: 100vh;
.avantage,.formulaire{padding:6rem 4rem;min-height: 100vh;}
.formulaire{background-color:#fff!important;}
.mobile{display:none;}
.alert{border:0;margin:1rem 0;}
.btn-main{margin:0 auto;}
@media all and (max-width: 768px) {
    .avantage{
        display:none;
    }
    .mobile{
        display:block;
        
        img{display:none;}
    }
    .mobile,.formulaire{
        padding:2rem;
    }
    .formulaire{
        min-height: auto;
    }
`;
const ListGroupAvantages = styled(ListGroup)`
border:0;
li.list-group-item{
    border:0;
    margin:.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-weight:bold;
    span{
        margin-right:10px;
        background-color:#FFF;
        padding:1rem;
        border-radius:10px;
        margin-right:15px;
    }
}
`;
const InfoMdp = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
gap:20px;
font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    opacity:.7;
svg{font-size: 1.5em!important;}

`;



function ConnexionLink(args, props) {
    const memberstack = useMemberstack();
    const [formError, setFormError] = useState(false);
    const [token, setToken] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [member, setMember] = useState(null);
    const [emailSent, setEmailSent] = useState(null);
    const navigate = useNavigate();
    const [canvas, setCanvas] = useState(false);

    const toggleEnSavoirPlus = () => setCanvas(!canvas);

    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    if (member) {
        navigate("/app/dashboard");
    }

    const onChangeCode = (event) => {
        const value = event.target.value;
        //console.log("code",value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        //console.log("email 1",email)

        // Send magic link email 
        memberstack.sendMemberLoginPasswordlessEmail({
            email
        }).then(() => {
            // Email sent 
            setEmailSent(email);
            setToken(true);
            //console.log("token d'envoi",token)
        }).catch(er => {
            // Handle error 
            //console.log("erreur sent email", er);
            setFormError(true);
            setFormErrorMessage(er.message);
        });

    }

    const handleSubmit2 = (event, emailSent) => {
        event.preventDefault();
        const to = event.target.token.value;
        const emailre = event.target.emailre.value;
        //console.log("email & code",emailre,to)

        // Sign up member after they click email link 
        memberstack.loginMemberPasswordless({
            passwordlessToken: to,
            email: emailre
        }).then(result => {
            //console.log("result", result);
            setMember(member);
            navigate("/app/dashboard");
            // Use accessToken from result 
        }).catch(error => {
            // Handle invalid or expired token 
            // Handle error 
            //console.log("erreur sent email", error);
            setFormError(true);
            setFormErrorMessage("Une erreur s'est produit veuillez recommencer");
        });
    }

    return (
        <>
            {!member && (
                <div>
                    <HomeS className="bg-gradient-to-t from-teal-100 to-teal-50">
                        <Row>
                            <Col md="5" className="avantage">
                                <Container>
                                    <div className="lg:pr-8 lg:pt-4">
                                        <div className="lg:max-w-lg">
                                            <Link to="/"><Logo alt="Clotere"
                                                src={logoClotere}></Logo></Link><br/>
                                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Simplifiez votre passage chez le notaire</p>
                                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                                Suivez l'avancement de votre dossier chez le notaire à un seul et même endroit.
                                            </p>
                                            <dl className="grid grid-cols-1 gap-x-8 gap-y-8 lg:gap-y-8">
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Suivez l'avancement de votre transaction immobilère simplement.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Regroupez tous vos échanges au même endroit.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Trouvez un notaire plus rapidement.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Fixez vos rendez-vous simplement avec votre notaire.
                                                    </dt>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </Container>
                            </Col>

                            <Col md="7" xs="0" className="formulaire">
                                <Container className="text-center">
                                
                                    <br /><br />
                                    {token === false ? (
                                        <>
                                            <div className="d-flex flex-column justify-content-center text-center">
                                            <Link to="/" className="block mx-auto sm:hidden mb-4"><Logo alt="Clotere" src={logoClotere} ></Logo></Link>
                                                <h3 className="mt-3 mb-3">Connectez-vous</h3>
                                            </div>
                                            <Form data-ms-form="passwordless-login" onSubmit={handleSubmit}>

                                                <>

                                                    {formError ? (
                                                        <Alert color="danger">
                                                            {formErrorMessage}
                                                        </Alert>
                                                    ) : (<>{" "}</>)}

                                                    <FormGroup>
                                                        <Label for="exampleEmail">
                                                            Email
                                                        </Label>
                                                        <Input
                                                            name="email" type="email" data-ms-member="email" required
                                                        />
                                                    </FormGroup>
                                                </>
                                                <FormGroup className="text-center">
                                                    <button className="btn-main" color="primary" type="submit" data-ms-passwordless-button="Confirm & Signup">Obtenir mon code de connexion</button>
                                                </FormGroup>
                                                <InfoMdp>
                                                    <FontAwesomeIcon icon={faInfoCircle} className="fa-3x iconflex" />
                                                    <div>Pour votre sécurité, Clotere n’utilise pas de mot de passe. <br />Vous recevrez un lien de connexion par email (<a onClick={toggleEnSavoirPlus}>en savoir plus</a>).
                                                    </div>
                                                </InfoMdp>
                                            </Form>
                                        </>
                                    ) : (<>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h3 className="mt-3 mb-3">Code de vérification</h3>
                                        </div>
                                        <Form data-ms-form="passwordless-login" onSubmit={handleSubmit2}>
                                            <FormGroup>
                                                <p>Saisissez le code envoyé à l'adresse <b>{emailSent}</b></p>
                                                <input name="emailre" value={emailSent} className="hidden" />
                                                <div data-ms-passwordless="step-2">
                                                    <Input onChange={onChangeCode} name="token" type="text" data-ms-member="token" placeholder="Saisissez votre code de vérification 167589" focus />

                                                </div>
                                                <Alert color="light"> <span role="icon">🫤</span> Vous ne trouvez pas votre code de vérification ? Assurez-vous de vérifier votre dossier spam</Alert>
                                            </FormGroup>

                                            <FormGroup>
                                                <button className="btn-main" color="primary" type="submit" data-ms-passwordless-button="VALIDER">Valider et se connecter</button>
                                            </FormGroup>
                                        </Form>
                                    </>
                                    )}

                                </Container>
                            </Col>
                            <Col xs="12" className="avantage mobile">
                                <Container>
                                    <Logo alt="Clotere"
                                        src={logoClotere}></Logo>
                                    <br /><br />
                                    <h4>Clotere vous facilite le passage chez le notaire</h4>
                                    <ListGroupAvantages>
                                        <ListGroupItem>
                                            <span>🥳</span> Suivez votre transaction immobilière facilement
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>👌</span> Regroupez tous vos échanges au même endroit
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>🔥</span> Trouvez un notaire rapidement
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>📆</span> Fixer vos rendez-vous avec votre notaire
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>📨</span> Réalisez vos signatures d'acte en ligne de manière sécurisée
                                        </ListGroupItem>
                                    </ListGroupAvantages>
                                </Container>
                            </Col>
                        </Row>
                    </HomeS>
                    <Offcanvas
                        isOpen={canvas}
                        toggle={toggleEnSavoirPlus}
                        {...args}
                        direction="end"
                        scrollable>
                        <OffcanvasHeader toggle={toggleEnSavoirPlus}>
                            Pourquoi n'ai-je pas de mot de passe Clotere ?
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <p>Clotere est une entreprise où la sécurité et la vie privée de nos utilisateurs est prise extrêmement au sérieux.
                                Nous savons que vous nous faites confiance en nous confiant des données personnelles et sensibles, et nous mettons tout en œuvre pour les protéger</p>

                            <p>
                                <b>L'objectif principal est qu'il soit impossible pour un intrus de se connecter à votre place</b> !
                            </p>
                            <p>
                                Pour nous en assurer, nous avons choisi de ne pas utiliser de mot de passe pour vous authentifier. Voici pourquoi :
                            </p>
                            <p>
                                <b>Les mots de passe sont un des plus gros risque de sécurité sur internet</b>, car vous êtes nombreux à les réutiliser sur plusieurs sites. On vous comprend : difficile de tous les retenir ! Mais il suffit qu'un seul de ces sites ait été hacké pour que votre précieux mot de passe soit connu de pirates.
                            </p>
                            <p>
                                Nous utilisons donc une solution passwordless, qui vous impose de cliquer sur un lien "magique" que nous vous envoyons par email à chaque connexion sur un nouvel appareil ou après 30 jours sur vos appareils déjà connus.
                            </p>
                        </OffcanvasBody>

                    </Offcanvas>
                </div>
            )}
        </>
    );
}

export default ConnexionLink;