import React, { useState, useEffect } from "react";
import {Container} from "reactstrap";
import { Navbar } from '../Navbar';
import Footer from '../components/Footer';
import BlocCta from '../components/BlocCta';
import CompositionFrais from "../img/composition-frais-notaire.png";
import { Link } from "react-router-dom";
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'



function CalculFraisNotaire(args) {
    const [fraisTotalNotaire, setFraisTotalNotaire] = useState(0);
    const [emolumentHT, setEmolumentHT] = useState(0);
    const [taxeDepartementale, setTaxeDepartementale] = useState(4, 5);
    const [taxeCommunale, setTaxeCommunale] = useState(1, 2);
    const [taxeFraisAssiette, setTaxeFraisAssiette] = useState(0, 107);
    const [taxeSecuriteImmo, setTaxeSecuriteImmo] = useState(0, 100);
    const [totalTaxes, setTotalTaxes] = useState(0);
    const [montant, setMontant] = useState(0);
    const [formalite, setFormalite] = useState(1360);
    const [typeProjet, setTypeProjet] = useState(null);
    const [departement, setDepartement] = useState(null);
    const [tranche1ok, setTranche1ok] = useState(false);
    const [tranche2ok, setTranche2ok] = useState(false);
    const [tranche3ok, setTranche3ok] = useState(false);
    const [tranche4ok, setTranche4ok] = useState(false);
    const [montantTranche1, setMontantTranche1] = useState(0);
    const [montantTranche2, setMontantTranche2] = useState(0);
    const [montantTranche3, setMontantTranche3] = useState(0);
    const [montantTranche4, setMontantTranche4] = useState(0);
    const [resultat, setResultat] = useState(false);
    const [publiciteFonciere, setPubliciteFonciere] = useState(300);
    const [fraisAnnexe, setFraisAnnexe] = useState(600);
    const [totalFraisAnnexes, setTotalFraisAnnexes] = useState(1700);
    const [tvaEmolument, setTvaEmolument] = useState(0);
    const [endCalcul, setEndCalcul] = useState(false);

    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const handleChangeSelect = (e) => {
        console.log(e);
        setTypeProjet(e.target.value);
    };

    const handleSubmit2 = (event) => {
        event.preventDefault();
        const montant = event.target.montant.value;
        const departement = event.target.departement.value;
        const tranche1 = 0;
        const tranche2 = 0;
        const tranche3 = 0;
        const tranche4 = 0;
        setMontant(montant);
        setDepartement(departement);

        //calcul des emoluments
        console.log("montant", montant);
        console.log("departement", departement);
        console.log("typeProjet", typeProjet);

        if (montant >= 6500) {
            console.log("sup 6500");
            const tranche1 = 252;
            //console.log("tranche 1",tranche1);
            setTranche1ok(true);
            setMontantTranche1(tranche1);

            if (montant >= 17000) {
                console.log("sup 17k");
                const tranche2 = tranche1 + 168;
                setTranche2ok(true);
                setMontantTranche2(168);

                if (montant >= 60000) {
                    console.log("sup 60");
                    const tranche3 = tranche2 + 458;
                    setTranche3ok(true);
                    setMontantTranche3(458);

                    const tranche4 = (montant - 60000) * (0.799 / 100);
                    setTranche4ok(true);
                    setMontantTranche4(tranche4);
                    setEndCalcul(true);

                } else if (montant < 60000) {
                    console.log("entre 17k & 60k");
                    const tranche3 = (montant - 17000) * 1.064 / 100;
                    //console.log("tranche 1",tranche1);
                    setTranche3ok(true);
                    setMontantTranche3(tranche3);
                    setEndCalcul(true);
                }



            } else if (montant < 17000) {
                console.log("entre 6500 & 17k");
                const tranche2 = (montant - 6500) * 1.596 / 100;
                //console.log("tranche 1",tranche1);
                setTranche2ok(true);
                setMontantTranche2(tranche2);
                setEndCalcul(true);
            }

        } else if (montant < 6500) {
            console.log("inf 6500");
            const tranche1 = montant * 3.870 / 100;
            setTranche1ok(true);
            setMontantTranche1(tranche1);
            setEndCalcul(true);
        }

        if (endCalcul === true) {
            console.log("end calcul");
            setEmolumentHT(montantTranche1 + montantTranche2 + montantTranche3 + montantTranche4);

            //calcul des taxes 
            setTaxeDepartementale(montant * 4.5 / 100);
            setTaxeCommunale(montant * 1.2 / 100);
            setTaxeFraisAssiette(montant * 0.107 / 100)
            setTaxeSecuriteImmo(montant * 0.1 / 100);
            setTotalTaxes(taxeDepartementale + taxeCommunale + taxeFraisAssiette + taxeSecuriteImmo);
            //calcul de la TVA
            if (emolumentHT !== 0) {
                setTvaEmolument(emolumentHT * (20 / 100));
            }

            if (emolumentHT !== 0 && totalTaxes !== 0) {
                setFraisTotalNotaire(emolumentHT + totalTaxes + formalite + tvaEmolument + formalite);
            }
            if (fraisTotalNotaire !== 0) {
                setResultat(true);
            }
        }

    }

    console.log("totalfrais", fraisTotalNotaire);
    return (
        <>
            <Navbar user={args.user} />

            <Container>
                {/** header */}
                <section class="flex flex-row flex-wrap justify-center bg-gradient-to-r from-teal-200 to-teal-500 p-10 rounded-lg">
                    <div className="w-full sm:basis-1/2 p-4">
                        <h1 className="font-normal text-transparent text-[50px] tracking-[0] leading-10 mb-8" style={{ lineHeight: "50px" }}>
                            <span className="font-medium text-black">
                                Calculez vos <span className="font-bold mt-2 text-indigo-800">frais de notaire</span></span>
                        </h1>
                        <p className="text-[16px]">
                            Les frais de notaire représente un cout non négligeable dans un budget d'acheteur immobilier, il vaut mieux les anticiper.
                            <br />
                            Estimez le montant de vos frais de notaire grâce à notre simulateur de frais de notaire.
                        </p>
                        <div className="flex flex-column justify-start gap-3">
                            <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0 text-lg"><b className="text-indigo-800">Estimez vos frais de notaire</b> simplement</p>
                                </div>
                            </div>
                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0 text-lg"><b className="text-indigo-800">Anticipez le versement des frais de notaire </b></p>
                                </div>
                            </div>
                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0 text-lg"><b className="text-indigo-800">Choisissez votre notaire</b> pour gérer votre affaire immobilière</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="w-full sm:basis-1/2 p-0 lg:p-5 ">
                        <div className="overflow-hidden rounded-lg bg-white p-24 shadow-md  px-8 pt-6 pb-8 mb-4">
                            {resultat === false &&
                                <>
                                    <form class="" onSubmit={handleSubmit2}>
                                        <div class="mb-2">
                                            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                                                Type d'achat
                                            </label>
                                            <ul class="grid w-full gap-3 md:grid-cols-3 p-0">
                                                <li>
                                                    <input type="radio" id="ancien" name="type" class="hidden peer" required onClick={() => { setTypeProjet("ancien"); }} />
                                                    <label for="ancien" class="inline-flex items-center justify-between w-full p-3 text-gray-500 font-semibold bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-indigo-800 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                        Ancien
                                                    </label>
                                                </li>
                                                <li>
                                                    <input type="radio" id="neuf" name="type" class="hidden peer" required onClick={() => { setTypeProjet("neuf"); }} />
                                                    <label for="neuf" class="inline-flex items-center justify-between w-full p-3 text-gray-500 font-semibold bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-indigo-800 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                        Neuf
                                                    </label>
                                                </li>
                                                <li>
                                                    <input type="radio" id="vea" name="type" class="hidden peer" required onClick={() => { setTypeProjet("vea"); }} />
                                                    <label for="vea" class="inline-flex items-center justify-between w-full p-3 text-gray-500 font-semibold bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-indigo-800 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                        Vente sur plan
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="mb-2">
                                            <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                                Montant de votre bien<sup>*</sup>
                                            </label>
                                            <input required name="montant" type="number" placeholder="350 000€" class="shadow-sm appearance-none border border-red-500 rounded w-full py-3 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>
                                        <div class="mb-2">
                                            <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                                Département du bien<sup>*</sup>
                                            </label>
                                            <input required name="departement" type="text" placeholder="" class="shadow-sm appearance-none border border-red-500 rounded w-full py-3 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>
                                        <div class="flex items-center justify-center">
                                            <button type="submit" class="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                Simulez vos frais de notaire
                                            </button>
                                        </div>
                                    </form>
                                </>
                            }
                            {resultat === true &&
                                <div className="text-center">
                                    <div className="mb-8 flex justify-center">
                                        <div className="relative font-medium rounded-full px-3 py-1 text-lg text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                            Vos <span className="text-indigo-800">frais de notaire</span> seront de
                                        </div>
                                    </div>
                                    <p className="mt-5 mb-5 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                                        {Math.trunc(fraisTotalNotaire)} €
                                    </p>
                                    <p>Pour une transaction d'un montant de <b>{montant} €</b>, un type de projet <b>{typeProjet}</b>, dans le département <b>{departement}</b><br />
                                        <a href="#" onClick={() => { setResultat(false); }}>Modifier</a> </p>

                                </div>
                            }

                        </div>
                    </div>

                </section>

            </Container>

            {/** détails des frais de notaire */}
            <section className="py-5 my-5 bg-slate-100 ">
                <Container>
                    <div className="w-full sm:basis-1/2  p-4 text-center">
                        <h2 className="font-bold">Détail du calcul de vos <span className="text-indigo-800">frais de notaire</span> </h2>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center items-center">
                        <div className="w-full sm:basis-1/2 p-4">
                            <div className="p-4 overflow-hidden rounded-lg bg-white  shadow-md m-auto w-full">
                                <dl className="space-y-6 divide-y divide-gray-900/10">
                                    <Disclosure as="div" className="">
                                        <dt>
                                            <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-xl font-semibold">
                                                    {Math.trunc(emolumentHT)} € <br />
                                                    <span className="text-base/7">Emoluments TTC du notaire</span>
                                                </span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    <p aria-hidden="true" className="m-0 font-normal group-data-[open]:hidden text-gray-400">Voir le détail</p>
                                                    <p aria-hidden="true" className="m-0 font-normal [.group:not([data-open])_&]:hidden text-gray-400">Masquer</p>
                                                </span>
                                            </DisclosureButton>
                                        </dt>
                                        <DisclosurePanel as="dd" className="mt-2 pr-12">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                            Tranche
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200">
                                                    {tranche1ok === true &&
                                                        <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-0">Tranche 1, de 0 à 6 500€</td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-right">{montantTranche1} €</td>
                                                        </tr>
                                                    }
                                                    {tranche2ok === true &&
                                                        <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-0">Tranche 2, de 6 500 à 17 000€</td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-right">{montantTranche2} €</td>
                                                        </tr>
                                                    }
                                                    {tranche3ok === true &&
                                                        <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-0">Tranche 3, de 17 000 à 65 000€</td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-right">{montantTranche3} €</td>
                                                        </tr>
                                                    }
                                                    {tranche4ok === true &&
                                                        <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-0">+ de 60 000€</td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-right">{Math.trunc(montantTranche4)} €</td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </DisclosurePanel>
                                    </Disclosure>
                                    <Disclosure as="div" className="pt-6">
                                        <dt>
                                            <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-xl font-semibold">
                                                    {Math.trunc(totalTaxes)} € <br />
                                                    <span className="text-base/7">Droits et taxes</span>
                                                </span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    <p aria-hidden="true" className="m-0 font-normal group-data-[open]:hidden text-gray-400">Voir le détail</p>
                                                    <p aria-hidden="true" className="m-0 font-normal [.group:not([data-open])_&]:hidden text-gray-400">Masquer</p>
                                                </span>
                                            </DisclosureButton>
                                        </dt>
                                        <DisclosurePanel as="dd" className="mt-2 pr-12">
                                            <p className="text-base/7 text-gray-600">total emolument</p>
                                        </DisclosurePanel>
                                    </Disclosure>
                                    <Disclosure as="div" className="pt-6">
                                        <dt>
                                            <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-xl font-semibold">
                                                    {Math.trunc(totalTaxes)} € <br />
                                                    <span className="text-base/7">Formalités et frais annexes</span>
                                                </span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    <p aria-hidden="true" className="m-0 font-normal group-data-[open]:hidden text-gray-400">Voir le détail</p>
                                                    <p aria-hidden="true" className="m-0 font-normal [.group:not([data-open])_&]:hidden text-gray-400">Masquer</p>
                                                </span>
                                            </DisclosureButton>
                                        </dt>
                                        <DisclosurePanel as="dd" className="mt-2 pr-12">
                                            <p className="text-base/7 text-gray-600">total emolument</p>
                                        </DisclosurePanel>
                                    </Disclosure>
                                    <Disclosure as="div" className="pt-6">
                                        <dt>
                                            <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base/7 font-semibold">Total des frais de notaire : <b>{fraisTotalNotaire}</b></span>
                                            </DisclosureButton>
                                        </dt>
                                    </Disclosure>
                                </dl>
                            </div>
                        </div>
                        <div className="w-full sm:basis-1/2 p-4">
                            <div className="flex flex-column justify-start text-left">
                                <h2 className="text-[28px] font-medium">Trouvez le bon notaire pour votre achat immobilier. </h2>
                                <ul className="text-[18px] leading-10">
                                    <li>• Il <b className="text-indigo-800">protège vos intêrets d'acheteur</b>, et garantie ainsi une transaction équitable.</li>
                                    <li>• Il <b className="text-indigo-800">garantit la légalité</b> de la transaction et, l’absence de litiges</li>
                                    <li>• Vous <b className="text-indigo-800">conseille</b> sur les aspects fiscaux et juridiques pour optimiser votre acquisition</li>
                                </ul>
                                <div className="flex flex-column">

                                    <p className="text-lg">Votre conseiller dédié Clotere voux accompagne gratuitement répondra à toutes vos questions. </p>
                                </div>
                                <div>
                                    <Link to="/inscription/achat" type="button" className="btn-lead-page mr-3">
                                        Débuter mon dossier (gratuitement)
                                        <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                    </Link>
                                    <Link to="/inscription/achat" type="button" className="btn-lead-page">
                                        Parler à un expert
                                        <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>


                </Container>
            </section>

            {/** section SEO */}
            <Container>
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">

                    <div className="w-full sm:basis-1/2  p-4 ">
                        <img alt="trouvez un notaire pour votre achat immobilier" src={CompositionFrais} className="rounded overflow-hidden" />
                    </div>
                    <div className="w-full sm:basis-1/2  p-4">
                        <h2 className="font-bold">De quoi se composent les <span className="text-[#1df36c]">frais de notaire</span></h2>
                        <p>Les frais de notaire  se divisent en trois grandes catégories&nbsp;:</p>
                        <ul>
                            <li><strong>• Les droits et taxes</strong> : Ces sommes sont directement versées à l'État ou aux collectivités territoriales.</li>
                            <li><strong>• Les débours</strong> : Ils couvrent les frais engagés par le notaire pour le traitement et la gestion du dossier (copies, documents administratifs, etc.).</li>
                            <li><strong>• Les émoluments ou honoraires</strong> : Il s'agit de la rémunération du notaire pour son intervention, notamment la rédaction de l'acte authentique.</li>
                        </ul>
                        <p>Contrairement à une idée reçue, les frais dits «&nbsp;de notaire&nbsp;» ne se limitent pas aux honoraires du professionnel. En réalité, ces derniers ne représentent qu'environ <strong>10 à 15&nbsp;%</strong> du total. La majeure partie des frais correspond aux taxes perçues par l'État et les collectivités locales.</p>
                        <p> Une petite partie de ces frais sont négociables, parlez en avec votre notaire.</p>
                        <Link to="/inscription/achat"
                            type="button"
                            className="no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Parler avec mon notaire
                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                        </Link>
                    </div>

                </section>
            </Container>

            {/** section faq frais de notaire */}
            <Container>
                <section className="">
                    <div className="bg-white">
                        <div className="mx-auto max-w-7xl px-6 py-24">
                            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                                <div className="lg:col-span-5">
                                    <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                                        Questions les plus fréquentes sur les frais de notaire.
                                    </h2>
                                    <p className="mt-4 text-pretty text-base leading-7 text-gray-600">
                                        Vous n'avez pas trouvé de réponse à vos questions ?
                                        Contactez-nous directement {" "}
                                        <a href="mailto:contact@clotere.fr" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                            contact@clotere.fr
                                        </a>{' '}

                                    </p>
                                </div>
                                <div className="mt-10 lg:col-span-7 lg:mt-0">
                                    <dl className="space-y-6 divide-y divide-gray-900/10">
                                        <Disclosure as="div" className="">
                                            <dt>
                                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                    <span className="text-xl font-semibold">
                                                        A quel moment régler les frais de notaire ?
                                                    </span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <PlusSmallIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                                                        <MinusSmallIcon aria-hidden="true" className="size-6 [.group:not([data-open])_&]:hidden" />
                                                    </span>
                                                </DisclosureButton>
                                            </dt>
                                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                                <p className="text-base leading-7 text-gray-600">
                                                    Les frais de notaire doivent être acquittés lors de la signature définitive de l’acte authentique de vente, généralement environ trois mois après la signature du compromis ou de la promesse de vente.
                                                    <br />
                                                    <b>👋 Attention cependant au séquestre</b>
                                                    <br />
                                                    Lors de la signature du compromis de vente, il est fréquent que l'acheteur verse un dépot de garantie, aussi appelé séquestre. Bien que ce versement ne soit pas obligatoire légalement, il est largement pratiqué. Ce dépôt correspond habituellement à<b> 5 à 10 % du prix total de vente</b>. <br />Si le séquestre a été versé, son montant sera déduit du solde à régler le jour de la signature de l’acte authentique.
                                                    <br />Le notaire, lorsqu’il reçoit ce dépôt, le place en sécurité sur un compte à la Caisse des Dépôts et Consignations.

                                                    En cas de différence entre le dépôt de garantie et le montant total dû, le notaire remboursera l’excédent. Vous recevrez également un récapitulatif détaillé des frais réellement engagés. Ce document, généralement transmis entre deux et six mois après la finalisation de la vente, permettra de comprendre la répartition exacte des frais.
                                                </p>
                                            </DisclosurePanel>
                                        </Disclosure>
                                        <Disclosure as="div" className="">
                                            <dt>
                                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900 mt-4">
                                                    <span className="text-xl font-semibold">
                                                        Les frais de notaire sont-ils négociables ?
                                                    </span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <PlusSmallIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                                                        <MinusSmallIcon aria-hidden="true" className="size-6 [.group:not([data-open])_&]:hidden" />
                                                    </span>
                                                </DisclosureButton>
                                            </dt>
                                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                                <p className="text-base leading-7 text-gray-600">
                                                    Bien que réglementés, il existe certaines marges de manœuvre pour réduire la facture.
                                                    <br />
                                                    La majorité des frais de notaire étant des taxes et impôts, une petite partie liée à la rémunération des notaires peut-être négociée.
                                                    <br /><br />
                                                    Depuis 2016, une réforme a introduit une légère flexibilité dans les émoluments du notaire pour les transactions immobilières de plus de 150 000 €. Dans ce cas, le notaire peut accorder une remise exceptionnelle pouvant aller jusqu’à 20 % sur ses honoraires.
                                                    
                                                </p>
                                            </DisclosurePanel>
                                        </Disclosure>
                                        <Disclosure as="div" className="">
                                            <dt>
                                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900 mt-4">
                                                    <span className="text-xl font-semibold">
                                                        Comment payer moins de frais de notaire ?
                                                    </span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <PlusSmallIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                                                        <MinusSmallIcon aria-hidden="true" className="size-6 [.group:not([data-open])_&]:hidden" />
                                                    </span>
                                                </DisclosureButton>
                                            </dt>
                                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                                <p className="text-base leading-7 text-gray-600">
                                                Une manière supplémentaire de réduire les frais consiste à <b>soustraire le coût du mobilier inclus dans le logement</b>. En effet, les frais de notaire s’appliquent uniquement à la valeur du bien immobilier hors équipements.
                                                <br /> Ainsi, si le logement dispose d’une salle de bains équipée ou d’une cuisine intégrant des appareils électroménagers, leur valeur peut être déduite du prix total, ce qui permet de diminuer la base de calcul des frais.
                                                </p>
                                            </DisclosurePanel>
                                        </Disclosure>
                                        <Disclosure as="div" className="">
                                            <dt>
                                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900 mt-4">
                                                    <span className="text-xl font-semibold">
                                                        Peux-t-on financer les frais de notaire avec un prêt immobilier ?
                                                    </span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <PlusSmallIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                                                        <MinusSmallIcon aria-hidden="true" className="size-6 [.group:not([data-open])_&]:hidden" />
                                                    </span>
                                                </DisclosureButton>
                                            </dt>
                                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                                <p className="text-base leading-7 text-gray-600">
                                                Lors de l’achat d’un bien immobilier avec un crédit, il est possible d’inclure divers frais dans le montant emprunté, tels que les intérêts, l’assurance emprunteur, les frais de notaire ou encore les frais d’agence immobilière.
                                                <br/><br/>
                                                Cependant, <b>les banques exigent de plus en plus souvent un apport personnel pour couvrir les frais de notaire et d’agence</b>. Réaliser une simulation avant l’achat permet d’évaluer précisément ces frais, facilitant ainsi la planification de votre projet immobilier et le calcul de l’apport nécessaire pour obtenir votre prêt.
                                                    </p>
                                            </DisclosurePanel>
                                        </Disclosure>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>

            {/** section ccm **/}
            <section id='section1' className="mb-20">
                <Container>
                    <div class="container mx-auto px-[15px] h-full">
                        <div class="flex flex-col justify-between items-start gap-[30px]">
                            <div class="py-2 px-4 rounded-[20px] text-center bg-emerald-500/30 text-emerald-500">
                                Trouvez un notaire acheteur simplement
                            </div>
                            <h3 class="text-black leading-[48px] text-[40px] lg:leading-[50px] lg:text-[45px] font-bold sm:max-w-[60%]">Trouver un notaire avec Clotere, comment ça marche ?</h3>

                            <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                        <p>Nous avons besoin de quelques informations sur vous et votre achat pour constituer votre dossier.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                            <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Complétez votre dossier</h3>
                                        <p>Ajoutez dans votre espace sécurisé les documents nécessaires à votre dossier chez le notaire.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                        <p>A vous de choisir votre notaire pour gérer votre achat immobilier. 100% à distance ou à proximité de chez vous c’est vous qui choisissez</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                    <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Obtenez vos actes notariés</h3>
                                        <p>Faites vos rendez-vous en visio ou présentiel, et obtenez directement notre plateforme vos documents notariés.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-5">
                            <div className="flex -space-x-2 overflow-hidden">
                                <img
                                    alt="trouver mon notaire acheteur"
                                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="notaire pour votre achat immobilier"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver mon notaire"
                                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver un notaire"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                            </div>
                            <div className="">
                                <p className="text-lg">Votre conseiller dédié Clotere se fera un plaisir de répondre à toutes vos questions, tout au long de votre démarche </p>
                            </div>

                        </div>
                        <Link to="/inscription/achat"
                            type="button"
                            className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Débuter mon dossier (gratuitement)
                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                        </Link>
                    </div>
                </Container>
            </section>

            <BlocCta />
            <Footer />

        </>
    );

}

export default CalculFraisNotaire;