import React from "react";
import styled from "styled-components";
import { Container } from "./Container";
import BgSmilePourquoi from "../img/smile-pourquoi-clotere.png"

export const PourquoiS = styled.div`

h2{
    font-size: 64px;
    font-weight: 500;
    line-height: 1.32em;
    }
    .tag-line,ul{
    font-weight: normal;
    font-size: 18px;
    position: relative;
    line-height: 1.7em;
    color: #73737b;
    }
ul{
    list-style-type: none;
    }
`;



function PourquoiClotereNew(args) {
  return (
    <>
      <Container>
        <div className="relative isolate overflow-hidden px-6 py-20 lg:overflow-visible lg:px-0">

          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">

            
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
            <div className="lg:pr-4">
              <img src={BgSmilePourquoi} />
            </div>
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <p className="text-base font-semibold leading-7 text-teal-600">Accédez simplement à votre notaire</p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Pourquoi choisir
                    Clotere ?</h1>
                  <p className="mt-6 text-lg leading-8 text-gray-700">
                    Chez Clotere nous avons décidé de <span className="font-bold text-teal-600">digitaliser votre passage chez le notaire</span> pour vous faire gagner du temps dans votre achat immobilier, votre succession...etc.
                    <br />Avec l'aide des notaires et des agents immobiliers nous vous proposons <span className="font-bold text-teal-600">une solution digitale simple, rapide et sécurisée</span>.
                  </p>
                  <div className="flex items-center gap-5">
                  <div className="flex items-center gap-2">
                    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.49967 1.83337V4.50004M8.49967 12.5V15.1667M3.78634 3.78671L5.67301 5.67337M11.3263 11.3267L13.213 13.2134M1.83301 8.50004H4.49967M12.4997 8.50004H15.1663M3.78634 13.2134L5.67301 11.3267M11.3263 5.67337L13.213 3.78671" stroke="#1E1E1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="text-black font-['Inter'] leading-[1.375rem]">Simple</div>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.83301 9.83337C5.83301 9.83337 6.83301 11.1667 8.49967 11.1667C10.1663 11.1667 11.1663 9.83337 11.1663 9.83337M6.49967 6.50004H6.50634M10.4997 6.50004H10.5063M15.1663 8.50004C15.1663 12.1819 12.1816 15.1667 8.49967 15.1667C4.81778 15.1667 1.83301 12.1819 1.83301 8.50004C1.83301 4.81814 4.81778 1.83337 8.49967 1.83337C12.1816 1.83337 15.1663 4.81814 15.1663 8.50004Z" stroke="#1E1E1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="text-black font-['Inter'] leading-[1.375rem]">Clair</div>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.16667 7.83337V5.16671C5.16667 4.28265 5.51786 3.43481 6.14298 2.80968C6.7681 2.18456 7.61595 1.83337 8.5 1.83337C9.38406 1.83337 10.2319 2.18456 10.857 2.80968C11.4821 3.43481 11.8333 4.28265 11.8333 5.16671V7.83337M3.83333 7.83337H13.1667C13.903 7.83337 14.5 8.43033 14.5 9.16671V13.8334C14.5 14.5698 13.903 15.1667 13.1667 15.1667H3.83333C3.09695 15.1667 2.5 14.5698 2.5 13.8334V9.16671C2.5 8.43033 3.09695 7.83337 3.83333 7.83337Z" stroke="#1E1E1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="text-black font-['Inter'] leading-[1.375rem]">Fiable</div>
                  </div>
                </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </Container>
    </>
  );
}

export default PourquoiClotereNew;

