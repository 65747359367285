import React from "react";
import { Link } from 'react-router-dom';

function RechercheParVille() {
    return (
        <>
        {/** section liens rapides */}
        <div className="pb-14 pt-24 sm:pb-22 sm:pt-14 md:pb-38 md:pt-22 text-center">
            
            <h2 className="text-[20px] sm:text-[23px]  mt-8 font-medium text-gray-950/75 ">
            Trouvez votre notaire dans les grandes villes de France
            </h2>
            <div class="flex flex-row gap-4 flex-wrap justify-center">
            <Link className="tag-recherche-notaire" to="/fr/notaires/ville/paris">Notaires à Paris</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/lyon">Notaires à Lyon</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/lille">Notaires à Lille</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/bordeaux">Notaires à Bordeaux</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/rennes">Notaires à Rennes</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/montpellier">Notaires à Montpellier</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/toulouse">Notaires à Toulouse</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/marseille">Notaires à Marseille</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/strasbourg">Notaires à Strasbourg</Link>

            </div>
          </div>
        </>
    );
}

export default RechercheParVille;