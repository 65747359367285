import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Form,
  Label,
  Input,
  Spinner,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { TitlePageBig, TitlePageApp, AlertNotif } from "../style/Layout";
import { ButtonPrimary, LinkS } from "../style/Button";
import CardDoc from "../components/CardDocument";
import IconPDF from "../img/icon-pdf.png";
import { FileUploader } from "react-drag-drop-files";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import styled from "styled-components";

import { useMemberstack } from "@memberstack/react";

function DocumentsDonation(args) {
  const [data, setData] = useState([]);
  const memberstack = useMemberstack();
  const [userInfo, setUserInfo] = useState(null);
  const [valueNomDoc, setValueNomDoc] = useState(null);

  const [idSelectedDoc, setIdSelectedDoc] = useState(null);
  const [listTabs, setListTabs] = useState([]);

  const [showSideBar, setShowSideBar] = useState(false);
  const toggleSideBar = () => {
    setShowSideBar(false);
    setDefaultValueType(null);
    setIdSelectedDoc(null);
    setFile(null);
    setValueNomDoc(null);
  };

  const [modalConfirm, setModalConfirm] = useState(false);

  const [docToDelete, setDocToDelete] = useState(null);

  const toggleModalConfirm = () => setModalConfirm(!modalConfirm);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  console.log("arg doc donation",args)
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const [storage, setStorage] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [defaultValueType, setDefaultValueType] = useState(null);

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
    console.log(file);
  };

  const fileTypes = ["PDF", "PNG", "JPG"];

  const firebaseConfig = {
    apiKey: "AIzaSyBx-T0tuEcSlSFsPF1zyeYQo3pZG-zytz8",
    authDomain: "clotere-33ee1.firebaseapp.com",
    projectId: "clotere-33ee1",
    storageBucket: "clotere-33ee1.appspot.com",
    messagingSenderId: "488961682641",
    appId: "1:488961682641:web:b89810aa37cda07d7169f0",
  };

  var appFirebase = null;

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line
      appFirebase = initializeApp(firebaseConfig);
      setStorage(getStorage(appFirebase));
    };

    fetchData();
  }, []);

  useEffect(() => {
    getUserDocuments();
  }, []);

  const getUserDocuments = async () => {
    var member = await memberstack.getCurrentMember();
    console.log("member doc",member);
    if (member !== null && member.data.metaData.airtable_id !== undefined) {
      const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/user/${member.data.metaData.airtable_id}`;

      var userData = await fetch(URL, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          "content-type": "application/x-www-form-urlencoded",
          Accept: "application/json, text/plain, /",
        },
      })
        .then((res) => res.json())
        .catch((error) => console.log(error));

      setUserInfo(userData.fields);
    }

    const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document?filterByFormula=({id_from_donation} = '${userData.fields.donation_id}')`;

    var dataTemp = await fetch(URL, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
        Accept: "application/json",
      },
    }).then((res) => res.json());

    var dataFilterTemp = null;
    let categoriesFiltrees = null;

    if (userData.fields.role === "donateur") {
      categoriesFiltrees = ["donateur"];
    } else if (userData.fields.role === "beneficiaire") {
      categoriesFiltrees = ["beneficiaire"];
    }

    if (categoriesFiltrees != null) {
      dataFilterTemp = dataTemp.records.filter((objet) => {
        if (objet.fields.categorie != null) {
          return categoriesFiltrees.includes(objet.fields.categorie[0]);
        } else {
          return false;
        }
      });
      console.log("data filter",dataFilterTemp)
    } else {
      dataFilterTemp = dataTemp.records;
    }

    
      setData(
        dataFilterTemp.map((item) => {
          const { fields } = item;
          if (fields.hasOwnProperty("document")) {
            fields.nomDocument = fields.document[0].filename;
            fields.typeDocument = fields.document[0].type;
            const documentUrl = fields.document[0].url;
            fields.document = documentUrl;
          }
          return fields;
        })
      );
    
  };

  useEffect(() => {
    if (data !== undefined && data !== null) {
      var listTabsTemp = [
        "tous les documents",
        "identite",
        "donation",
        "autre",
      ];
      var nomsDocuments = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        nomsDocuments.push(element.nom);
        // setDropdownCategorie(dropdownCategorie => [...dropdownCategorie, element.categorie[0]])
      }
      console.log(data)
      setListTabs(listTabsTemp);
      setCurrentActiveTab(listTabsTemp[0]);
      setDefaultValueType("autre");
    }
  }, [data]);

  const handleChangeSelect = (e) => {
    console.log(e);
    setDefaultValueType(e.target.value);
  };

  const handleChangeNom = (e) => {
    console.log(e);
    setValueNomDoc(e.target.value);
  };

  const handleDeleteDocument = async (doc) => {
    toggleModalConfirm();
    const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document/${doc.id}`;

    if (doc.type === "autre") {
      await fetch(URL, {
        method: "DELETE",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          Accept: "application/json",
          "content-type": "application/json",
        },
      }).then((res) => res.json());
    } else {
      await fetch(URL, {
        method: "PATCH",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            document: [],
            etat: "non ajouté",
            date_upload: "",
            qui_upload: "",
          },
        }),
      }).then((res) => res.json());
    }

    await getUserDocuments();

    setSuccess("Document supprimé avec succès !");

    setTimeout(() => {
      setSuccess(null);
    }, 5000);
  };

  const handleDelete = (event) => {
    // Empêcher la propagation de l'événement de clic
    event.stopPropagation();

    // Empêcher le comportement par défaut (ouvrir l'explorateur de fichiers)
    event.preventDefault(); // Empêche l'ouverture de l'explorateur de fichiers
    setFile(null);
  };

  const formattedDate = () => {
    var date = new Date();

    // Obtenir le jour, le mois, l'année, l'heure et les minutes
    var jour = date.getDate();
    var mois = date.getMonth() + 1; // Ajouter 1 car les mois sont indexés à partir de 0
    var annee = date.getFullYear();
    var heures = date.getHours();
    var minutes = date.getMinutes();

    // Formatage du jour et du mois pour qu'ils aient toujours 2 chiffres
    jour = (jour < 10 ? "0" : "") + jour;
    mois = (mois < 10 ? "0" : "") + mois;

    // Formatage de l'heure et des minutes pour qu'ils aient toujours 2 chiffres
    heures = (heures < 10 ? "0" : "") + heures;
    minutes = (minutes < 10 ? "0" : "") + minutes;

    // Création de la chaîne de caractères au format jj/mm/aaaa hh:mm
    return jour + "/" + mois + "/" + annee + " " + heures + ":" + minutes;
  };

  const handleUpload = async () => {
    try {
      setLoadingUpload(true);

      // ---------------------- //
      // Formattage de la date  //
      // ---------------------- //
      var date = formattedDate();

      // Upload du fichier sur firestore le temps de l'upload

      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      // Requete pour upload sur airtable
      if (defaultValueType !== "autre") {
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document/${idSelectedDoc}`;

        await fetch(URL, {
          method: "PATCH",
          headers: {
            Authorization:
              "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
            Accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              document: [
                {
                  url: url,
                  filename: file.name,
                },
              ],
              etat: "ajouté",
              date_upload: date,
              qui_upload: userInfo.airtable_id,
            },
          }),
        }).then((res) => res.json());
      } else {
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document`;

        await fetch(URL, {
          method: "POST",
          headers: {
            Authorization:
              "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
            Accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              document: [
                {
                  url: url,
                  filename: file.name,
                },
              ],
              etat: "ajouté",
              date_upload: date,
              nom: valueNomDoc,
              qui_upload: userInfo.airtable_id,
              type: "autre",
              categorie: [userInfo.role],
              transaction: [userInfo.transaction_id],
            },
          }),
        }).then((res) => res.json());
      }

      setSuccess("Document ajouté avec succès !");

      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (e) {
      console.log(e);
      setError("Erreur lors de l'upload de votre document.");

      setTimeout(() => {
        setError(null);
      }, 5000);
    }

    await getUserDocuments();
    setLoadingUpload(false);
    toggleSideBar();
  };

  const uploadWithoutPreSelection = async () => {
    try {
      setLoadingUpload(true);

      // Upload du fichier sur firestore le temps de l'upload

      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      var date = formattedDate();

      console.log("Date", date);
      const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document`;

      await fetch(URL, {
        method: "POST",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            document: [
              {
                url: url,
                filename: file.name,
              },
            ],
            etat: "ajouté",
            date_upload: date,
            nom: valueNomDoc,
            qui_upload: userInfo.airtable_id,
            type: defaultValueType,
            categorie: [userInfo.role],
            transaction: [userInfo.transaction_id],
          },
        }),
      }).then((res) => res.json());

      setSuccess("Document ajouté avec succès !");

      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (e) {
      console.log(e);
      setError("Erreur lors de l'upload de votre document.");

      setTimeout(() => {
        setError(null);
      }, 5000);
    }

    await getUserDocuments();
    setLoadingUpload(false);
    toggleSideBar();
  };

  const stackSelected = (
    <DragAndDropContainer style={{ width: "100%!important" }}>
      <img
        alt="IconPDF"
        style={{ height: "60px", marginBottom: "10px" }}
        src={IconPDF}
      />
      <div className="subtitle">Fichiers acceptés : PDF/JPG/PNG</div>
      <div className="subtitle">Taille maximum : 40MB</div>
      <br />
      <div style={{ display: "inline-flex" }}>
        <div className="file-name">
          {file !== undefined && file !== null && file.name}
        </div>
        <div
          onClick={toggleModalConfirm}
          style={{ textDecoration: "underline" }}
        >
          Supprimer
        </div>
      </div>
    </DragAndDropContainer>
  );

  const stackNotSelected = (
    <DragAndDropContainer style={{ width: "100%!important" }}>
      <img
        style={{ height: "60px", marginBottom: "10px" }}
        alt="drop"
        src={IconPDF}
      />
      <p style={{ fontWeight: "500" }}>Déposez votre document ici ou</p>
      <p className="title-underline">Sélectionnez un document</p>
      <div className="subtitle">Fichiers acceptés : PDF/JPG/PNG</div>
      <div className="subtitle">Taille maximum : 40MB</div>
    </DragAndDropContainer>
  );

console.log("doc file",file)

    return (
      <>
        <Modal isOpen={modalConfirm} toggle={toggleModalConfirm} {...args}>
        <ModalBody>Voulez-vous vraiment supprimer ce document ?</ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleModalConfirm}>
            Ne pas supprimer
          </Button>
          <ButtonPrimary
            color="primary"
            onClick={() => handleDeleteDocument(docToDelete)}
          >
            Confirmer
          </ButtonPrimary>{" "}
        </ModalFooter>
      </Modal>
      <Offcanvas
        isOpen={showSideBar}
        toggle={toggleSideBar}
        direction="end"
        scrollable
      >
        <OffcanvasHeader toggle={toggleSideBar}>
          Ajouter un document
        </OffcanvasHeader>
        <OffcanvasBody>
          {loadingUpload ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%!important",
              }}
            >
              <Spinner
                style={{ width: "5rem", height: "5rem" }}
                color="primary"
              />
            </div>
          ) : (
            <Form style={{ width: "100%!important" }}>
              <FormGroup>
                <FileUploader
                  style={{ width: "100%!important" }}
                  disabled={file != null}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  children={file != null ? stackSelected : stackNotSelected}
                ></FileUploader>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">De quel document s'agit-il ?</Label>

                <Input
                  disabled={idSelectedDoc !== null}
                  id="exampleSelect"
                  name="select"
                  type="select"
                  value={defaultValueType}
                  onChange={handleChangeSelect}
                >
                  {listTabs !== undefined && listTabs !== null ? (
                    listTabs
                      ?.filter((tab) => {
                        if (tab !== "tous les documents") {
                          return true;
                        } else {
                          return false; // ou simplement, return tab !== "tous les documents";
                        }
                      })
                      .map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))
                  ) : (
                    <></>
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <Input
                  onChange={handleChangeNom}
                  disabled={idSelectedDoc !== null}
                  name="nom"
                  type="nom"
                  value={valueNomDoc}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  onClick={
                    idSelectedDoc !== null
                      ? handleUpload
                      : uploadWithoutPreSelection
                  }
                  disabled={
                    file === null || valueNomDoc === null || valueNomDoc === ""
                  }
                  style={{ backgroundColor: "#1c6856" }}
                >
                  Ajouter
                </Button>
              </FormGroup>
            </Form>
          )}
        </OffcanvasBody>
      </Offcanvas>
      <Container>
        <Row className="d-flex align-self-start">
          <TitlePageApp>
            <Col md="7">
              <TitlePageBig className="mb-4">Vos documents</TitlePageBig>
            </Col>
            <Col md="5" className="text-end">
              <LinkS
                onClick={() => {
                  setShowSideBar(true);
                }}
              >
                + Ajouter un document
              </LinkS>
            </Col>
          </TitlePageApp>
          {/**Si erreur upload */}
          {error == null ? <></> : <Alert color="danger">{error} </Alert>}
          {/**Si succès upload */}
          {success == null ? <></> : <Alert color="success"> {success} </Alert>}
          <ColTabsDoc md="12" xs="12">
            <Nav pills>
              {listTabs.map((item, index) => (
                <NavItemNameCategorie key={index}>
                  <NavLink
                    onClick={() => {
                      toggle(item);
                    }}
                    className={`${currentActiveTab === item ? "active" : ""}`}
                  >
                    {item}
                  </NavLink>
                </NavItemNameCategorie>
              ))}
            </Nav>
          </ColTabsDoc>
          <Col md="12" xs="12">
            <TabContent className="mt-3" activeTab={currentActiveTab}>
              {listTabs.map((item, index) => (
                <TabPane key={index} tabId={item}>
                  {item === "identite" && 
                  <>
                    <AlertNotif color="primary">
                      <span role="img">👋</span>
                      <div>
                        <h6>Il s'agit de vos documents d'identité. </h6>
                      </div>
                    </AlertNotif>
                    </>
                  }
                  {item === "donation" && 
                  <>
                    <AlertNotif color="primary">
                      <span role="img">👋</span>
                      <div>
                        <h6>Il s'agit des documents relatifs à votre donation. </h6>
                      </div>
                    </AlertNotif>
                    </>
                  }
                  <Row>
                    <Col key={"dsjhnfsod"} sm="12">
                      <CardDoc
                        onAddDoc={(doc) => {
                          setIdSelectedDoc(doc.id);
                          setDefaultValueType(doc.type);
                          setValueNomDoc(doc.nom);
                          setShowSideBar(true);
                        }}
                        onDeleteDoc={(doc) => {
                          setDocToDelete(doc);
                          toggleModalConfirm();
                        }}
                        documents={data.filter((document) => {
                          if (
                            document.type === item ||
                            item === "tous les documents"
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
          </Col>
        </Row>
      </Container>
      </>
    );
  
}

export const Message = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.colors.linearBackground};
  text-align: center;
  border-radius: 6px;
`;

export const RowCandidatureNotaire = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  .card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd !important;
    width: 100%;
    margin-bottom: 1rem;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

const NavItemNameCategorie = styled(NavItem)`
  a.nav-link {
    text-transform: lowercase;
  }
  a.nav-link::first-letter {
    text-transform: uppercase;
  }
`;
const ColTabsDoc = styled(Col)`
  @media all and (max-width: 768px) {
    ul.nav-pills {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const DragAndDropContainer = styled.div`
  display: flex;
  justify-content: center; /* Centre les éléments horizontalement */
  align-items: center; /* Centre les éléments verticalement */
  border-style: dotted;
  border-color: #c3dbf7;
  border-width: 2px;
  border-radius: 5px;
  width: 100% !important;
  height: 250px;
  background-color: #eef6ff;
  flex-direction: column;

  .title-underline {
    color: #54958c;
    font-weight: 500;
    text-decoration: underline;
  }

  .subtitle {
    font-size: 15px;
  }

  .file-name {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: "40px";
  }
`;



export default DocumentsDonation;
