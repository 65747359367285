import styled from "styled-components";
import { Button, Dropdown } from "reactstrap";

export const ButtonPrimary = styled(Button)`
&.btn{
border:0;
  background-color:#000;
    border-radius:100px;
    color:#fff;
    font-size: .9rem;
    font-weight: 600;
    padding: 0.625rem 1.5rem;
    transition-delay: 0s;
    transition-duration: .2s;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    &:hover{
    background-color:  #17d65d;
    }
  img{
    width: 20px;
    margin-left: 0.6rem;
  }
}
&.btn-primary{
  background-color: ${props => props.theme.colors.black};!important;
  color:white;
  border-radius:100px;
  &:hover{
    background-color: ${props => props.theme.colors.mainDark};
  }
}
&.btn-white{
  background-color: ${props => props.theme.colors.white}!important;
  color:${props => props.theme.colors.black}!important;
}
  &.btn-dark{
  background-color: ${props => props.theme.colors.mainDark}!important;
  color:${props => props.theme.colors.white}!important;
}
  
  
  
`;

export const ButtonPrimarySmall = styled(ButtonPrimary)`
  font-size:16px!important;
  padding: 10px 18px!important;
`;
export const LinkS = styled.a`
border:0;
font-size:16px;
text-decoration:underline;
color:${props => props.theme.colors.black};
font-weight:600;
  &:hover {
    color: #006855;
    svg {
      transition: all 0.5s ease;
      transform: translateX(10px);
    }
  }
`;
export const LinkCard = styled.a`
  font-size:16px;
  color:#006855;
  transition: all 200ms ease-in-out;
  line-height: 100%;
  font-weight: 400;
  margin-right:.8rem;
`;

export const DropdownPrimary = styled(Dropdown)`
  button.dropdown-toggle{
    background-color: transparent;
border:0;
font-size:16px;
text-decoration:underline;
color:${props => props.theme.colors.black};
font-weight:600;
  }
`;

