import React from "react";

function EstimationFraisDonation(args) {
  return (
      <dl className="grid grid-cols-1 gap-3 sm:grid-cols-1 lg:grid-cols-1">
        <div className="relative overflow-hidden rounded-lg shadow sm:px-6 sm:pt-6 bg-gradient-to-r from-teal-200 to-teal-500 p-3">
            <dt>
              <p className=" truncate text-sm font-medium text-gray-500">Estimation de vos frais de donation</p>
            </dt>
            <dd className="flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900"> {args.frais} €</p>
             
              <div className="absolute inset-x-0 bottom-0  px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a href="https://clotere.com/le-calcul-des-frais-de-notaire-dune-donation/" target="blank" className="font-medium hover:text-indigo-500">
                    Comment sont calculés ces frais ?
                  </a>
                </div>
              </div>
            </dd>
          </div>
          <div className="relative overflow-hidden rounded-lg shadow sm:px-6 sm:pt-6 bg-gradient-to-r from-teal-200 to-teal-500 p-3">
            <dt>
              <p className=" truncate text-sm font-medium text-gray-500">Droit de donation</p>
            </dt>
            <dd className="flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{args.droit} €</p>
             
              <div className="absolute inset-x-0 bottom-0  px-4 py-4 sm:px-6">
                <div className="text-sm">
                A payer par le(s) bénéficiaire(s)
                </div>
              </div>
            </dd>
          </div>
      </dl>
  );
}

export default EstimationFraisDonation;