import React from "react";
import {  DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { DocumentIcon } from "@heroicons/react/24/solid";




function ModalDiagErp() {

    return (
        <>
           <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10  overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                    <DocumentIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-left sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                    L'État des Risques et Pollution  (ERP)
                                    </DialogTitle>
                                    <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        L'État des Risques et Pollution est un état informatif et un diagnostic immobilier obligatoire. Sa mission est d'informer le futur acquéreur ou locataire d'un logement <b>des risques naturels, miniers, technologiques, sismicité, radon et de pollution des sols</b> auxquels il pourrait être potentiellement exposé.
                                        
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </DialogPanel>
                    </div>
                </div>
        </>
    );
}

export default ModalDiagErp;