import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'


export default function CardNotaireDashboard(args) {
    //console.log("args", args)
    return (
        <ul className="mb-5 p-0">
            <li className="relative flex flex-wrap justify-between px-4 py-4 hover:bg-gray-50  overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                <div className="flex min-w-0 gap-x-4">
                    <span className="relative inline-block sm:block">
                        <img
                            alt=""
                            src={args.photo[0].url}
                            className="h-16 w-16 rounded-full"
                        />
                        <span className="absolute right-0 top-0 block h-4 w-4 rounded-full bg-green-400 ring-2 ring-white" />

                    </span>
                    <div className="min-w-0 flex-auto">
                        <p className="text-xl font-semibold leading-6 text-gray-900 m-0">

                            <span className="absolute inset-x-0 bottom-0" />
                            {args.prenom} {args.nom}
                            <span className="ml-3 inline-flex items-center rounded-md bg-purple-200 px-2 py-1 text-sm font-medium text-purple-600 ring-1 ring-inset ring-gray-500/10">
                                Notaire
                            </span>

                        </p>
                        <div className="flex flex-row flex-wrap items-center gap-4">
                            <p className="mt-1 flex flex-wrap text-md leading-5 text-gray-500  items-center gap-2 mb-0">
                                <EnvelopeIcon className="size-6 text-gray-600 " /> {args.email}
                            </p>
                            <p className="mt-1 flex flex-wrap text-md leading-5 text-gray-500 mb-0">
                                <PhoneIcon className="size-6 text-gray-600" /> {args.telephone}
                            </p>
                        </div>
                    </div>
                </div>
                <a className="btn-main no-underline mt-3 md:mt-0" href={`mailto:${args.email}`}>Contacter</a>
            </li>
        </ul>

    )
}