import React, { useState, useEffect} from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Alert } from "reactstrap";
import { CheckIcon, QuestionMarkCircleIcon, XCircleIcon,InformationCircleIcon } from "@heroicons/react/20/solid";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { initializeApp } from "firebase/app";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { Dialog} from '@headlessui/react';
import Loading from "./components/Loading";
import ContentModalDiagDpe from "./components/annonce/ModalDiagDpe";
import ContentModalDiagErp from "./components/annonce/ModalDiagErp";
import ContentModalDiagCarrez from "./components/annonce/ModalDiagCarrez";
//image
import logoPlateforme from './img/annonce/plateforme.webp';
import previewAnnonce from './img/annonce/screen-annonce.webp';


function BlocQuestion() {
    return (
        <Col md="12" xs="12" className="text-center mt-10">
            <span className="inline-flex items-center rounded-full bg-emerald-200 px-2 py-2 text-xs font-medium text-emerald-600">
                Vous avez une question ?  <a href="tel:0176390060" className="text-emerald-600">01 76 39 00 60</a>
            </span>
        </Col>
    );
}

function Loader() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 animate-spin">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </>
    );
}
function Erreur() {
    return (
        <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
                <div className="shrink-0">
                    <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Une erreur s'est produite veuillez recommencer</h3>
                </div>
            </div>
        </div>
    );
}


function Inscription(args, props) {
    const memberstack = useMemberstack();
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [member, setMember] = useState(null);
    const [nom, setNom] = useState(null);
    const [prenom, setPrenom] = useState(null);
    const [inscriptionDone, setInscriptionDone] = useState(null);
    const [url, setUrl] = useState(null);
    const [plateforme, setPlateforme] = useState(null);
    const [fonciere, setFonciere] = useState(null);
    const [charges, setCharges] = useState(null);
    const [loyer, setLoyer] = useState(null);
    const [locchargesmensuelles, setLocchargesmensuelles] = useState(null);
    const [classeEnergetique, setClasseEnergetique] = useState(null);
    const [ges, setGes] = useState(null);
    const [vendu, setVendu] = useState(null);
    const [dpe, setDpe] = useState(false);
    const [erp, setErp] = useState(false);
    const [carrez, setCarrez] = useState(false);
    const [copro, setCopro] = useState(null);
    const [procedure, setProcedure] = useState(false);
    const [procedureDescription, setProcedureDescription] = useState(null);
    const [creneau, setCreneau] = useState(null);
    const [onboard1, setOnboard1] = useState(true);
    const [onboard2, setOnboard2] = useState(false);
    const [onboard3, setOnboard3] = useState(false);
    const [onboard4, setOnboard4] = useState(false);
    const [onboard5, setOnboard5] = useState(false);
    const [onboard6, setOnboard6] = useState(false);
    const [onboardEnd, setOnboardEnd] = useState(false);
    const [loaderSubmit, setLoaderSubmit] = useState(false);
    const [lienAnnonce, setLienAnnonce] = useState(null);
    const [copyStatus, setCopyStatus] = useState(false); 
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
    //upload dpe
    const [storage, setStorage] = useState([]);
    const [fileDpe, setFileDpe] = useState(null);
    const [urlFirebaseDpe, setUrlFirebaseDpe] = useState(null);
    const [loaderDpe, setLoaderDpe] = useState(false);
    const [erreurUploadDPE, setErreurUploadDPE] = useState(false);
    //upload erp
    const [fileErp, setFileErp] = useState(null);
    const [urlFirebaseErp, setUrlFirebaseErp] = useState();
    const [loaderErp, setLoaderErp] = useState(false);
    const [erreurUploadERP, setErreurUploadERP] = useState(false);
    //upload carrez
    const [fileCarrez, setFileCarrez] = useState(null);
    const [urlFirebaseCarrez, setUrlFirebaseCarrez] = useState();
    const [loaderCarrez, setLoaderCarrez] = useState(false);
    const [erreurUploadCARREZ, setErreurUploadCARREZ] = useState(false);
    //modal diag
    const [openDpe, setOpenDpe] = useState(false);
    const [openErp, setOpenErp] = useState(false);
    const [openCarrez, setOpenCarrez] = useState(false);
    

    
    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 1000); // Reset status after 2 seconds
      };
    
      // Inside your React component
    const handleCopyClick = () => {
    
        // This function will be called when the user clicks the copy button
        onCopyText(); // Call the copy function
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2500); // Hide the success message after 2.5 seconds
      };

    //firebase pour upload diag
    const firebaseConfig = {
        apiKey: "AIzaSyBx-T0tuEcSlSFsPF1zyeYQo3pZG-zytz8",
        authDomain: "clotere-33ee1.firebaseapp.com",
        projectId: "clotere-33ee1",
        storageBucket: "clotere-33ee1.appspot.com",
        messagingSenderId: "488961682641",
        appId: "1:488961682641:web:b89810aa37cda07d7169f0"
    };
    var appFirebase = null;
    
    //firebase initialisation
    useEffect(() => {
        const fetchData = async () => {
            appFirebase = initializeApp(firebaseConfig);
            setStorage(getStorage(appFirebase));
        }
        fetchData()
    }, []);

    //upload DPE
    const onUploadDpe = (event) => {
        console.log("onchange");
        console.log(event.target.files[0].size);
        console.log(event.target.files[0].type);

        //controle 
        const fileSize = event.target.files[0].size;
        const fileMb = fileSize / 10024 ** 2;
        if (fileMb >= 2) { // 5 MB in bytes
            alert('Votre fichier est trop volumineux. Taille maximale 5MB.');
            event.target.value = ''; // clear the file input
        } else {
            //console.log("else");
            const handleUploadDpe = async () => {
                try {
                    setLoaderDpe(true);
                    // Upload du fichier sur firestore le temps de l'upload
                    const storageRef = ref(storage, event.target.files[0].name);
                    //console.log("storageRef",storageRef);

                    uploadBytes(storageRef, event.target.files[0])
                        .then((snapshot) => {
                            getDownloadURL(snapshot.ref)
                                .then((url) => {
                                    //console.log("firebase url dpe",url);
                                    setUrlFirebaseDpe(url);
                                    setFileDpe(event.target.files[0]);
                                    setLoaderDpe(false);
                                    console.log("file", event.target.files);
                                })
                                .catch((error) => {
                                    console.log("erreur url", error);
                                    setErreurUploadDPE(true);
                                    setLoaderDpe(false);
                                });
                        })
                        .catch((error) => {
                            console.log("erreur firebase", error);
                            setErreurUploadDPE(true);
                            setLoaderDpe(false);
                        })

                }
                catch (e) {
                    //console.log("Erreur lors de l'upload de votre document.",e)
                }
            }
            handleUploadDpe();
        }
    }
    //upload ERP
    const onUploadErp = (event) => {
        console.log("onchangeERP");
        console.log(event.target.files[0].size);
        console.log(event.target.files[0].type);

        //controle 
        const fileSize = event.target.files[0].size;
        const fileMb = fileSize / 10024 ** 2;
        if (fileMb >= 2) { // 5 MB in bytes
            alert('Votre fichier est trop volumineux. Taille maximale 5MB.');
            event.target.value = ''; // clear the file input
        } else {
            const handleUploadErp = async () => {
                try {
                    setLoaderErp(true);
                    // Upload du fichier sur firestore le temps de l'upload
                    const storageRef = ref(storage, event.target.files[0].name);

                    uploadBytes(storageRef, event.target.files[0])
                        .then((snapshot) => {
                            getDownloadURL(snapshot.ref)
                                .then((url) => {
                                    console.log("firebase url erp", url);
                                    setUrlFirebaseErp(url);
                                    setFileErp(event.target.files[0]);
                                    setLoaderErp(false);
                                    console.log("file erp", event.target.files);
                                })
                                .catch((error) => {
                                    console.log("erreur url erp", error);
                                    setErreurUploadERP(true);
                                    setLoaderErp(false);
                                });
                        })
                        .catch((error) => {
                            console.log("erreur firebase", error);
                            setErreurUploadERP(true);
                            setLoaderErp(false);
                        });

                }
                catch (e) {
                    //console.log("Erreur lors de l'upload de votre document.",e)
                }
            }
            handleUploadErp();
        }
    }
    //upload CARREZ
    const onUploadCarrez = (event) => {
        console.log("onchangeCARREZ");
        console.log(event.target.files[0].size);
        console.log(event.target.files[0].type);

        //controle 
        const fileSize = event.target.files[0].size;
        const fileMb = fileSize / 10024 ** 2;
        if (fileMb >= 2) { // 5 MB in bytes
            alert('Votre fichier est trop volumineux. Taille maximale 5MB.');
            event.target.value = ''; // clear the file input
        } else {
            const handleUploadCarrez = async () => {
                try {
                    setLoaderCarrez(true);
                    // Upload du fichier sur firestore le temps de l'upload
                    const storageRef = ref(storage, event.target.files[0].name);

                    uploadBytes(storageRef, event.target.files[0])
                        .then((snapshot) => {
                            getDownloadURL(snapshot.ref)
                                .then((url) => {
                                    console.log("firebase url carrez", url);
                                    setUrlFirebaseCarrez(url);
                                    setFileCarrez(event.target.files[0]);
                                    console.log("file carrez", event.target.files);
                                    setErreurUploadCARREZ(true);
                                    setLoaderCarrez(false);
                                })
                                .catch((error) => {
                                    console.log("erreur url erp", error);
                                    setErreurUploadCARREZ(true);
                                    setLoaderCarrez(false);
                                });
                        })
                        .catch((error) => {
                            console.log("erreur firebase", error);
                            setErreurUploadCARREZ(true);
                            setLoaderCarrez(false);
                        });
                }
                catch (e) {
                    //console.log("Erreur lors de l'upload de votre document.",e)
                }
            }
            handleUploadCarrez();
        }
    }
    

    //set member if logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
            if(member){
                setOnboard1(false); setOnboardEnd(true);
            }
    }, []);


    


    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const password = event.target.password.value;
        const nom = event.target.nom.value;
        const prenom = event.target.prenom.value;
        {
            memberstack.signupMemberEmailPassword({
                email: email,
                password: password
            }).then(result => {
                setNom(nom);
                setPrenom(prenom);
                //console.log("result", result);
                setMember(result.data.member);
                setInscriptionDone(true);
                setOnboard1(false);
                setOnboard2(true);
            })
                .catch(er => {
                    //console.log("erreur sent email", er);
                    setFormError(true);
                    setFormErrorMessage("Une erreur s'est produite veuillez recommencer");
                });
        }

    }

    const checkPlateforme = (event) => {
        const url = event.target.value;
        //on check a plateforme
        let leboncoin = 'leboncoin';
        let seloger = 'seloger';
        let pap = 'pap';
        let resultLebonCoin = url.includes(leboncoin);
        let resultSeloger = url.includes(seloger);
        let resultPap = url.includes(pap);
        if (resultLebonCoin === true) { setPlateforme("leboncoin"); console.log("plateforme", plateforme); }
        else if (resultSeloger === true) { setPlateforme("seloger"); console.log("plateforme", plateforme); }
        else if (resultPap === true) { setPlateforme("pap"); console.log("plateforme", plateforme); }
    }

    const handleSubmitLien = (event) => {
        event.preventDefault();
        const url = event.target.url.value;
        setUrl(url);
        console.log("url", url);

        setOnboard2(false);
        setOnboard3(true);
    }
    const handleSubmitInformation = (event) => {
        event.preventDefault();
        const fonciere = event.target.fonciere.value;
        const charges = event.target.charges.value;
        setFonciere(fonciere);
        setCharges(charges);
        if (vendu === "louer") {
            const loccharges = event.target.locchargesmensuelles.value;
            const loyer = event.target.loyer.value;
            setLoyer(loyer);
            setLocchargesmensuelles(loccharges);
        }
        setOnboard3(false);
        setOnboard4(true);
    }
    const handleSubmitDiagnostic = (event) => {
        setOnboard4(false);
        setOnboard5(true);
    }
    const handleSubmitCopro = (event) => {
        event.preventDefault();
        const copro = event.target.copro.value;
        setCopro(copro);
        if (procedure === true) {
            const procedureEncours = event.target.procedureEncours.value;
            setProcedureDescription(procedureEncours);
        }
        setOnboard5(false);
        setOnboard6(true);
    }
    const handleSubmitCreneau = (event) => {
        event.preventDefault();
        setOnboard6(false);
        setOnboardEnd(true);
        setLoaderSubmit(true);
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/annonce`;
        const postAnnonce = async () => {
            try {
              const response = await fetch(URL, {
                method: "POST",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    "Accept": "application/json",
                    'content-type': "application/json"
                },
                body: JSON.stringify({
                    "fields": {
                        "email_check":member.auth.email,
                        "url": url,
                        "titre": "A renseigner",
                        "plateforme": plateforme,
                        "taxe_fonciere": fonciere,
                        "charges_copro": charges,
                        "vendu": vendu,
                        "loyer_mensuel": loyer,
                        "charges_loc": locchargesmensuelles,
                        "creneau": creneau,
                        "copropriete": copro,
                        "procedure": procedure === true ? "oui" : "non",
                        "description_procedure": procedure === true ? procedureDescription : "",
                        "classe_energie": classeEnergetique,
                        "ges": ges,
                        "dpe": [{
                            "url": dpe === true ? urlFirebaseDpe : "",
                            "filename": dpe === true ? fileDpe.name : "",
                        }],
                        "erp": [{
                            "url": erp === true ? urlFirebaseErp : "",
                            "filename": erp === true ? fileErp.name : "",
                        }],
                        "carrez": [{
                            "url": carrez === true ? urlFirebaseCarrez : "",
                            "filename": carrez === true ? fileCarrez.name : "",
                        }]
                    }
                })
              });
              const resp = await response.json();
              console.log("res add annonce",resp);
              if(resp){
                console.log("ok");
              const idAnnonce = resp.id;
              const lien = `https://clotere.fr/${idAnnonce}`;
              setLienAnnonce(lien);
              const sendToZapier = async () => {
                const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2irgfsq/"; // 👈 Specify your Zapier Webhook URL here
                try {
                  const response = await fetch(zapierURL, {
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify({nom,prenom,member,idAnnonce,lien})
                  });
                  const res = await response.json();
                  console.log(res);
                  if(res){
                    setLoaderSubmit(false);
                    setOnboard6(false);
                    setOnboardEnd(true);
                  }
                } catch (e) {
                  console.log(e);
                }
              };
              sendToZapier();
              }
            } catch (e) {
              console.log(e);
            }
          };

          postAnnonce();
        
    }

    

    
    return (
        <>
            <div>
                <div className="bg-gradient-to-t from-teal-100 to-teal-50 min-h-screen flex align-middle justify-center flex-column">
                    {onboard1 === true &&
                        <Row>
                            <Col md="12" xs="12" className="formulaire">
                                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-96">
                                        <img
                                            alt="Clotere"
                                            src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg"
                                            className="mx-auto h-10 w-auto"
                                        />
                                        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                                            Rejoignez Clotere
                                        </h2>
                                    </div>

                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-96">
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmit}>
                                            {formError ? (
                                                <Alert color="danger">
                                                    {formErrorMessage}
                                                </Alert>
                                            ) : (<>{" "}</>)}
                                            <div className="grid gap-4 sm:grid-cols-2">
                                                <div>
                                                    <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                        Votre nom
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="nom"
                                                            name="nom"
                                                            type="text"
                                                            required
                                                            className="input-clotere"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="prenom" className="block text-sm/6 font-medium text-gray-900">
                                                        Votre prénom
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="prenom"
                                                            name="prenom"
                                                            type="text"
                                                            required
                                                            className="input-clotere"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                                    Votre email
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        required
                                                        autoComplete="email"
                                                        className="input-clotere"
                                                        data-ms-member="email"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex items-center justify-between">
                                                    <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                                                        Mot de passe
                                                    </label>
                                                </div>
                                                <div className="mt-2">
                                                    <input
                                                        id="password"
                                                        name="password"
                                                        type="password"
                                                        required
                                                        autoComplete="current-password"
                                                        className="input-clotere"
                                                        data-ms-member="password"
                                                        minLength="8"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>

                                        <p className="mt-10 text-center text-sm/6 text-gray-500">
                                            En cliquant sur Continuer, vous acceptez <a href="https://clotere.fr/info/cgu" target="blank">les conditions générales d'utilisation </a>de Clotere.{' '}

                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <BlocQuestion />
                        </Row>
                    }

                    {onboard2 === true &&
                        <Row>
                            <Col md="12" xs="12">
                                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-96">
                                        <img
                                            alt="Clotere"
                                            src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg"
                                            className="mx-auto h-10 w-auto"
                                        />
                                        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                                            Entrez le lien de votre annonce immobilière
                                        </h2>
                                    </div>
                                    <div className="rounded-md bg-blue-50 p-4">
                                        <div className="flex">
                                            <div className="shrink-0">
                                            <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                                            </div>
                                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                            <p className="text-sm text-blue-700">Nous allons récupérer les informations de votre annonce.</p>
                                            </div>
                                        </div>
                                        </div>

                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-96">
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitLien}>
                                            {formError ? (
                                                <Alert color="danger">
                                                    {formErrorMessage}
                                                </Alert>
                                            ) : (<>{" "}</>)}
                                            <div>
                                                <img src={logoPlateforme} alt="Plateforme annonce immobilière" />
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                                    URL de votre annonce
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="url"
                                                        name="url"
                                                        type="text"
                                                        required
                                                        className="input-clotere"
                                                        placeholder="https://"
                                                        onChange={checkPlateforme}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <BlocQuestion />
                        </Row>
                    }

                    {onboard3 === true &&
                        <Row>
                            <Col md="12" xs="12">
                                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-96">
                                        <img
                                            alt="Clotere"
                                            src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg"
                                            className="mx-auto h-10 w-auto"
                                        />
                                        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                                            Ajoutez des informations pour votre futur acheteur
                                        </h2>
                                    </div>

                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-96">
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitInformation}>
                                            {formError ? (
                                                <Alert color="danger">
                                                    {formErrorMessage}
                                                </Alert>
                                            ) : (<>{" "}</>)}
                                            <div>
                                                <label className="block text-sm/6 font-medium text-gray-900">
                                                    Taxe foncière annuelle<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        id="fonciere"
                                                        name="fonciere"
                                                        type="number"
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm/6 font-medium text-gray-900">
                                                    Charges annuelles<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        id="charges"
                                                        name="charges"
                                                        type="number"
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm/6 font-medium text-gray-900">
                                                    Vendu
                                                </label>
                                                <div className="mt-2">
                                                    <ul class="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="vide" name="vendu" class="hidden peer" required onClick={() => { setVendu("vide"); }} />
                                                            <label htmlFor="vide" class="radio-button">
                                                                Vide
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="louer" name="vendu" class="hidden peer" required onClick={() => { setVendu("louer"); }} />
                                                            <label htmlFor="louer" class="radio-button">
                                                                Loué
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {vendu === "louer" && (
                                                <>
                                                    <div>
                                                        <label className="block text-sm/6 font-medium text-gray-900">
                                                            Montant du loyer charges comprises
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="loyer"
                                                                name="loyer"
                                                                type="number"
                                                                className="input-clotere"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm/6 font-medium text-gray-900">
                                                            Montant des charges mensuelles
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="locchargesmensuelles"
                                                                name="locchargesmensuelles"
                                                                type="number"
                                                                className="input-clotere"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div>
                                                <label className="block text-sm/6 font-medium text-gray-900">
                                                    Classe énergetique
                                                </label>
                                                <ul class="grid w-full gap-1 grid-cols-7 p-0 mt-2">
                                                    <li>
                                                        <input type="radio" id="ancien" name="classe" class="hidden peer" required onClick={() => { setClasseEnergetique("A"); }} />
                                                        <label htmlFor="ancien" class="radio-button">
                                                            A
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="b" name="classe" class="hidden peer" required onClick={() => { setClasseEnergetique("B"); }} />
                                                        <label htmlFor="b" class="radio-button">
                                                            B
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="c" name="classe" class="hidden peer" required onClick={() => { setClasseEnergetique("C"); }} />
                                                        <label htmlFor="c" class="radio-button">
                                                            C
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="d" name="classe" class="hidden peer" required onClick={() => { setClasseEnergetique("D"); }} />
                                                        <label htmlFor="d" class="radio-button">
                                                            D
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="e" name="classe" class="hidden peer" required onClick={() => { setClasseEnergetique("E"); }} />
                                                        <label htmlFor="e" class="radio-button">
                                                            E
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="f" name="classe" class="hidden peer" required onClick={() => { setClasseEnergetique("F"); }} />
                                                        <label htmlFor="f" class="radio-button">
                                                            F
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="g" name="classe" class="hidden peer" required onClick={() => { setClasseEnergetique("G"); }} />
                                                        <label htmlFor="g" class="radio-button">
                                                            G
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <label className="block text-sm/6 font-medium text-gray-900">
                                                    GES
                                                </label>
                                                <ul class="grid w-full gap-1 grid-cols-7 p-0 mt-2">
                                                    <li>
                                                        <input type="radio" id="gesa" name="ges" class="hidden peer" required onClick={() => { setGes("A"); }} />
                                                        <label htmlFor="gesa" class="radio-button">
                                                            A
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesb" name="ges" class="hidden peer" required onClick={() => { setGes("B"); }} />
                                                        <label htmlFor="gesb" class="radio-button">
                                                            B
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesc" name="ges" class="hidden peer" required onClick={() => { setGes("C"); }} />
                                                        <label htmlFor="gesc" class="radio-button">
                                                            C
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesd" name="ges" class="hidden peer" required onClick={() => { setGes("D"); }} />
                                                        <label htmlFor="gesd" class="radio-button">
                                                            D
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gese" name="ges" class="hidden peer" required onClick={() => { setGes("E"); }} />
                                                        <label htmlFor="gese" class="radio-button">
                                                            E
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesf" name="ges" class="hidden peer" required onClick={() => { setGes("F"); }} />
                                                        <label htmlFor="gesf" class="radio-button">
                                                            F
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesg" name="ges" class="hidden peer" required onClick={() => { setGes("G"); }} />
                                                        <label htmlFor="gesg" class="radio-button">
                                                            G
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <BlocQuestion />
                        </Row>
                    }

                    {onboard4 === true &&
                        <Row>
                            <Col md="12" xs="12">
                                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-96">
                                        <img
                                            alt="Clotere"
                                            src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg"
                                            className="mx-auto h-10 w-auto"
                                        />
                                        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                                            Avez-vous réalisé les diagnostics ?
                                        </h2>
                                    </div>

                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-96">
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitDiagnostic}>
                                            {formError ? (
                                                <Alert color="danger">
                                                    {formErrorMessage}
                                                </Alert>
                                            ) : (<>{" "}</>)}
                                            <div>
                                                <p className="text-sm">
                                                    Certains diagnostics sont obligatoires lors d’une vente immobilière. Nous vous conseillons des les réaliser le plus tôt possible et de les ajouter sur Clotere pour attester du sérieux de votre vente auprès de votre acheteur
                                                </p>
                                            </div>
                                            <div>
                                                <label className="text-sm/6 font-medium text-gray-900 flex flex-row gap-2" onClick={() => setOpenDpe(true)}>
                                                    Diagnostics de performance (DPE) <QuestionMarkCircleIcon className="flex w-6 " />
                                                </label>
                                                <div className="mt-2">
                                                    <ul class="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="ouidpe" name="dpe" class="hidden peer" required onClick={() => { setDpe(true); }} />
                                                            <label htmlFor="ouidpe" class="radio-button">
                                                                Oui
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="nondpe" name="dpe" class="hidden peer" required onClick={() => { setDpe(false); }} />
                                                            <label htmlFor="nondpe" class="radio-button">
                                                                Non
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    {dpe === true &&
                                                        <>
                                                            <label class="block my-2">
                                                                <span class="sr-only">Ajouter votre fichier</span>
                                                                <input onChange={onUploadDpe} accept="pdf" type="file" class="block w-full text-sm text-slate-500
                                                                file:mr-4 file:py-2 file:px-4
                                                                file:rounded-full file:border-0
                                                                file:text-sm file:font-semibold
                                                                file:bg-indigo-50 file:text-indigo-700
                                                                hover:file:bg-indigo-100
                                                                "/>
                                                            </label>
                                                            {loaderDpe === true && <><Loader /></>}
                                                            {erreurUploadDPE === true && <><Erreur /></>}
                                                            {fileDpe && <><p className="flex mb-0 items-center"><CheckIcon aria-hidden="true" className="size-6 text-emerald-600" />Document ajouté</p> </>}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <label className="text-sm/6 font-medium text-gray-900 flex flex-row gap-2" onClick={() => setOpenErp(true)}>
                                                    Etat des risques et pollutions (ERP) <QuestionMarkCircleIcon className="flex w-6 " />
                                                </label>
                                                <div className="mt-2">
                                                    <ul class="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="ouierp" name="erp" class="hidden peer" required onClick={() => { setErp(true); }} />
                                                            <label htmlFor="ouierp" class="radio-button">
                                                                Oui
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="nonerp" name="erp" class="hidden peer" required onClick={() => { setErp(false); }} />
                                                            <label htmlFor="nonerp" class="radio-button">
                                                                Non
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    {erp === true &&
                                                        <>
                                                            <label class="block my-2">
                                                                <span class="sr-only">Ajouter votre fichier</span>
                                                                <input onChange={onUploadErp} accept="pdf" type="file" class="block w-full text-sm text-slate-500
                                                                file:mr-4 file:py-2 file:px-4
                                                                file:rounded-full file:border-0
                                                                file:text-sm file:font-semibold
                                                                file:bg-indigo-50 file:text-indigo-700
                                                                hover:file:bg-indigo-100
                                                                "/>
                                                            </label>
                                                            {loaderErp === true && <><Loader /></>}
                                                            {erreurUploadERP === true && <><Erreur /></>}
                                                            {fileErp && <><p className="flex mb-0 items-center"><CheckIcon aria-hidden="true" className="size-6 text-emerald-600" />Document ajouté</p> </>}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <label className="text-sm/6 font-medium text-gray-900 flex flex-row gap-2" onClick={() => { setOpenCarrez(true); }} >
                                                    Certification Loi Carrez <QuestionMarkCircleIcon className="flex w-6 " />
                                                </label>
                                                <div className="mt-2">
                                                    <ul class="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="ouicarrez" name="carrez" class="hidden peer" required onClick={() => { setCarrez(true); }} />
                                                            <label htmlFor="ouicarrez" class="radio-button">
                                                                Oui
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="noncarrez" name="carrez" class="hidden peer" required onClick={() => { setCarrez(false); }} />
                                                            <label htmlFor="noncarrez" class="radio-button">
                                                                Non
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    {carrez === true &&
                                                        <>
                                                            <label class="block my-2">
                                                                <span class="sr-only">Ajouter votre fichier</span>
                                                                <input onChange={onUploadCarrez} accept="pdf" type="file" class="block w-full text-sm text-slate-500
                                                                file:mr-4 file:py-2 file:px-4
                                                                file:rounded-full file:border-0
                                                                file:text-sm file:font-semibold
                                                                file:bg-indigo-50 file:text-indigo-700
                                                                hover:file:bg-indigo-100
                                                                "/>
                                                            </label>
                                                            {loaderCarrez === true && <><Loader /></>}
                                                            {erreurUploadCARREZ === true && <><Erreur /></>}
                                                            {fileCarrez && <><p className="flex mb-0 items-center"><CheckIcon aria-hidden="true" className="h-8 w-8 text-emerald-600" />Document ajouté</p> </>}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <BlocQuestion />
                        </Row>
                    }

                    {onboard5 === true &&
                        <Row>
                            <Col md="12" xs="12">
                                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-96">
                                        <img
                                            alt="Clotere"
                                            src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg"
                                            className="mx-auto h-10 w-auto"
                                        />
                                        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                                            Au sujet de votre copropriété
                                        </h2>
                                    </div>

                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-96">
                                        <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmitCopro}>
                                            <div>
                                                <p className="text-sm">
                                                    C’est un sujet qui fait peur, vaut mieux être transparent pour rassurer votre futur acheteur.
                                                </p>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                                    Quel est votre syndic de copropriété ?<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="copro"
                                                        name="copro"
                                                        type="text"
                                                        required
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                                    Y a-t-il des procédures en cours ?
                                                </label>
                                                <div className="mt-2">
                                                    <ul class="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="ouiprocedure" name="procedure" class="hidden peer" required onClick={() => { setProcedure(true); }} />
                                                            <label for="ouiprocedure" class="radio-button">
                                                                Oui
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="nonprocedure" name="procedure" class="hidden peer" required onClick={() => { setProcedure(false); }} />
                                                            <label for="nonprocedure" class="radio-button">
                                                                Non
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {procedure === true &&
                                                    <div>
                                                        <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                                            Décrivez la procédure en cours
                                                        </label>
                                                        <div className="mt-2">
                                                            <textarea
                                                                id="procedureEncours"
                                                                name="procedureEncours"
                                                                required
                                                                rows={4}
                                                                className="input-clotere"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <BlocQuestion />
                        </Row>
                    }
                    {onboard6 === true &&
                        <Row>
                            <Col md="12" xs="12">
                                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-96">
                                        <img
                                            alt="Clotere"
                                            src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg"
                                            className="mx-auto h-10 w-auto"
                                        />
                                        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                                            Souhaitez-vous indiquer des créneaux de visite ?
                                        </h2>
                                    </div>

                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-96">
                                        <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmitCreneau}>
                                            <div>
                                                <p className="text-sm">
                                                    Nous informeront l'acheteur de vos préférences au moment ou il souhaite faire une visite.
                                                </p>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                                    Que préférez-vous ?
                                                </label>
                                                <div className="mt-2">
                                                    <ul class="grid w-full gap-1 p-0">
                                                        <li>
                                                            <input type="radio" id="soir" name="creneau" class="hidden peer" required onClick={() => { setCreneau("soir"); }} />
                                                            <label for="soir" class="radio-button">
                                                                Uniquement le soir après 18h
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="weekend" name="creneau" class="hidden peer" required onClick={() => { setCreneau("weekend"); }} />
                                                            <label for="weekend" class="radio-button">
                                                                Uniquement le weekend
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="semaine" name="creneau" class="hidden peer" required onClick={() => { setCreneau("semaine"); }} />
                                                            <label for="semaine" class="radio-button">
                                                                Uniquement la semaine
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="paspreference" name="creneau" class="hidden peer" required onClick={() => { setCreneau("paspreference"); }} />
                                                            <label for="paspreference" class="radio-button">
                                                                Quand vous voulez
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <BlocQuestion />
                        </Row>
                    }
                    {onboardEnd === true &&
                        <>
                            {loaderSubmit === true &&
                                <Loading/>
                            }
                            {loaderSubmit === false &&
                                <Row>
                                    <Col md="12" xs="12">
                                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                            <div className="sm:mx-auto sm:w-full sm:max-w-96">
                                                <img
                                                    alt="Clotere"
                                                    src="https://clotere.fr/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg"
                                                    className="mx-auto h-10 w-auto"
                                                />
                                                <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                                                    Votre lien Clotere est prêt !
                                                </h2>
                                            </div>

                                            <div className="justify-center  sm:mx-auto sm:w-full sm:max-w-screen-md text-center ">
                                                <div>
                                                    <p className="text-sm">
                                                        Il est temps de partager votre lien à vos futurs acheteurs.
                                                    </p>
                                                </div>
                                                {isCopied && <div className="alert-success"><br />👍 Lien copié</div>}
                                                <div className="mt-2 flex rounded-md shadow-sm mb-4">
                                                    <div className="relative flex flex-grow items-stretch focus-within:z-10 bg-teal-600 text-center justify-center rounded-md">
                                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        </div>
                                                        <input
                                                        disabled
                                                            value={lienAnnonce}
                                                            className="text-center text-white w-full font-medium"
                                                        />
                                                    </div>

                                                    <CopyToClipboard text={lienAnnonce} onCopy={handleCopyClick}>
                                                    <button
                                                        type="button"
                                                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    >Copier</button>
                                                </CopyToClipboard>
                                                </div>
                                                
                                                <div>
                                                    <img className="mx-auto w-60" src={previewAnnonce} alt="previsualisation de votre annonce Clotere" />
                                                </div>
                                                <div className="flex flex-row flex-wrap sm:flex-no-wrap justify-center gap-3 text-center mt-10 w-1/2 mx-auto">
                                                    <Link to={lienAnnonce} target="blank" className="btn-indigo no-underline">Voir mon annonce</Link>
                                                    <Link to="/app/dashboard-annonce" className="btn-indigo no-underline">Accédez à mon dashboard</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>

                                </Row>
                            }
                        </>
                    }


                </div>
            </div>
            {/** modal diag dpe */}
            <Dialog open={openDpe} onClose={setOpenDpe} className="relative z-10">
                <ContentModalDiagDpe/>
            </Dialog>
            {/** modal diag erp */}
            <Dialog open={openErp} onClose={setOpenErp} className="relative z-10">
                <ContentModalDiagErp/>
            </Dialog>
            {/** modal diag Carrez */}
            <Dialog open={openCarrez} onClose={setOpenCarrez} className="relative z-10">
            <ContentModalDiagCarrez/>
            </Dialog>
        </>
    );
}

export default Inscription;