import React from "react";
import { CheckIcon, HeartIcon, HomeModernIcon, ShieldCheckIcon } from "@heroicons/react/20/solid";
import { Container } from "../annuaire/Container";
import { useNavigate } from 'react-router-dom';

function HeaderNew() {

    const navigate = useNavigate();

    return (
        <Container>
            <header className="flex flex-column gap-5 items-center justify-center  px-px py-[5px]  bg-transparent">

                {/** title */}
                <div className="flex flex-column items-center gap-[16px] flex-wrap">
                    <h1 className="[font-family:'Inter',Helvetica] font-normal text-transparent text-[50px] tracking-[0] leading-8 text-center">
                        <span className="font-medium text-black">Le notaire. </span>
                        <span className="font-bold text-[#1df36c]">
                            Plus simplement
                        </span>
                    </h1>


                    <p className="[font-family:'Inter',Helvetica] font-normal text-black text-2xl text-center tracking-[0] leading-[normal]">
                        Gérez vos démarches chez le notaire avec une solution
                        <br />
                        <span className="textHighlight">simple</span>, <span className="textHighlight">rapide</span> et <span className="textHighlight">fiable</span>
                    </p>
                    <p className="mb-0 [font-family:'Inter',Helvetica] font-normal text-black text-lg text-center tracking-[0] leading-[normal]">
                        De quoi avez-vous besoin ?
                    </p>
                </div>


                {/** cards */}
                <div className="flex flex-row flex-wrap justify-center items-center gap-8 relative flex-[0_0_auto] mb-3">

                    <div onClick={() => navigate('/faire-une-donation')} className="flex flex-column w-full sm:w-auto  items-start justify-center gap-3 p-6 relative flex-[0_0_auto] bg-[#b4f6c7] rounded-2xl overflow-hidden hover:card-hover ">
                        <div class="absolute top-[-30px] right-[-30px] w-[185px] h-[185px] opacity-20">
                            <svg width="185" height="185" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M31.355 16.6667C31.87 16.6667 32.3567 16.4283 32.6717 16.0217C32.9867 15.615 33.0967 15.085 32.97 14.5867C32.4517 12.5583 31.395 10.705 29.915 9.225L24.1067 3.41667C21.9033 1.21333 18.9733 0 15.8567 0H8.33167C3.73833 0 0 3.73833 0 8.33333V31.6667C0 36.2617 3.73833 40 8.33333 40H13.3333C14.2533 40 15 39.2533 15 38.3333C15 37.4133 14.2533 36.6667 13.3333 36.6667H8.33333C5.57667 36.6667 3.33333 34.4233 3.33333 31.6667V8.33333C3.33333 5.57667 5.57667 3.33333 8.33333 3.33333H15.8583C16.13 3.33333 16.4 3.34667 16.6667 3.37167V11.6667C16.6667 14.4233 18.91 16.6667 21.6667 16.6667H31.355ZM20 11.6667V4.43167C20.6317 4.795 21.22 5.245 21.75 5.775L27.5583 11.5833C28.0817 12.1067 28.53 12.6967 28.8967 13.3333H21.6667C20.7483 13.3333 20 12.585 20 11.6667ZM38.5367 19.7983C36.6467 17.9083 33.3533 17.9083 31.465 19.7983L20.2867 30.9767C19.0283 32.235 18.3333 33.91 18.3333 35.6917V38.335C18.3333 39.255 19.08 40.0017 20 40.0017H22.6433C24.425 40.0017 26.0983 39.3067 27.3567 38.0483L38.535 26.87C39.48 25.925 40 24.67 40 23.3333C40 21.9967 39.48 20.7417 38.5367 19.7983ZM36.1783 24.5117L24.9983 35.69C24.37 36.32 23.5333 36.6667 22.6417 36.6667H21.665V35.69C21.665 34.8 22.0117 33.9633 22.6417 33.3333L33.8217 22.155C34.45 21.525 35.5483 21.525 36.1783 22.155C36.4933 22.4683 36.6667 22.8867 36.6667 23.3333C36.6667 23.78 36.4933 24.1967 36.1783 24.5117Z" fill="black"></path>
                            </svg>
                        </div>
                        <HeartIcon className="size-12 " />

                        <h2 className="relative w-fit  font-semibold text-black text-xl text-left sm:text-center tracking-[0] leading-[normal]">
                            Faire une donation
                        </h2>

                        <p className="relative w-[323.55px] [font-family:'Inter',Helvetica] font-normal text-[#00000099] text-base tracking-[0] leading-[22px]">
                            Transmettre de son vivant la propriété d’un bien.
                        </p>

                        <div className="inline-flex items-center gap-3 relative flex-[0_0_auto]">
                            <div className="inline-flex items-center relative flex-[0_0_auto]">

                                <img
                                    className="relative w-[43.76px] h-[43.76px] mt-[-1.50px] mb-[-1.50px] ml-[-1.50px]"
                                    alt="Ellipse"
                                    src="https://c.animaapp.com/9fTLJkc9/img/ellipse-15-3.svg"
                                />
                                
                                <img
                                    className="relative w-[43.76px] h-[43.76px] mt-[-1.50px] mb-[-1.50px] mr-[-1.50px] -ml-2.5"
                                    alt="Ellipse"
                                    src="https://c.animaapp.com/9fTLJkc9/img/ellipse-16-3.svg"
                                />
                            </div>

                            <div className="relative w-[240.02px] [font-family:'Inter',Helvetica] font-normal text-black text-xs tracking-[0] leading-[22px]">
                                Donateur et bénéficaire(s)
                            </div>
                        </div>
                    </div>

                    <div onClick={() => navigate('/notaire-acheteur')}  className="w-full flex flex-column  sm:w-auto items-start justify-center gap-3 p-6 relative flex-[0_0_auto] bg-[#bcd7c6] rounded-lg hover:card-hover">
                        <HomeModernIcon className="size-12" />

                        <h2 className="relative w-fit  font-semibold text-black text-xl text-left sm:text-center tracking-[0] leading-[normal]">
                            Achetez votre bien immobilier
                        </h2>

                        <p className="relative w-[302.75px] font-normal text-[#00000099] text-base tracking-[0] leading-[22px]">
                            Trouvez votre notaire pour défendre vos intêrets lors de
                            votre achat.
                        </p>

                        <div className="inline-flex items-center gap-3 relative flex-[0_0_auto]">
                            <div className="inline-flex items-center relative flex-[0_0_auto]">
                           
                            <img class="rounded-full relative ring-2 ring-white w-[43.76px] h-[43.76px] mt-[-1.50px] mb-[-1.50px] ml-[-1.50px]" src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHVzZXIlMjBzbWlsZXxlbnwwfHwwfHx8Mg%3D%3D" alt="Notaire pour acheteur immobilier"/>
                            <img class="rounded-full relative ring-2 ring-white w-[43.76px] h-[43.76px] mt-[-1.50px] mb-[-1.50px] ml-[-1.50px]" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHVzZXIlMjBhdmF0YXJ8ZW58MHx8MHx8fDI%3D" alt="Notaire pour acheteur immobilier"/>
                                
                            </div>

                            <div className="relative w-[240.02px] [font-family:'Inter',Helvetica] font-normal text-black text-xs tracking-[0] leading-[22px]">
                                Vous et votre notaire
                            </div>
                        </div>
                    </div>

                    <div  onClick={() => navigate('/inscription/vente')} className="w-full flex flex-column sm:w-auto  items-start justify-center gap-3 p-6 relative flex-[0_0_auto] bg-[#1df3bd] rounded-lg hover:card-hover">
                        <ShieldCheckIcon className="size-12" />

                        <h2 className="relative w-fit  font-semibold text-black text-xl text-left sm:text-center tracking-[0] leading-[normal]">
                            Vendre son bien immobilier
                        </h2>

                        <p className="relative w-[240.02px] [font-family:'Inter',Helvetica] font-normal text-[#00000099] text-base tracking-[0] leading-[22px]">
                            Débutez votre dossier de vente de votre bien immobilier.
                        </p>

                        <div className="inline-flex items-center gap-3 relative flex-[0_0_auto]">
                            <div className="inline-flex items-center relative flex-[0_0_auto]">

                            <img class="rounded-full relative ring-2 ring-white w-[43.76px] h-[43.76px] mt-[-1.50px] mb-[-1.50px] ml-[-1.50px]" src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a751741ddf78d3cc798f24_Lana%20Steiner-p-500.jpg" alt="Notaire pour vente immobiliere"/>
                            <img class="rounded-full relative ring-2 ring-white w-[43.76px] h-[43.76px] mt-[-1.50px] mb-[-1.50px] ml-[-1.50px]" src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a751a0a4117992e1b980d8_Loki%20Bright-p-500.jpg" alt="Notaire pour vente immobiliere"/>
                            
                            </div>

                            <p className="relative w-[240.02px] [font-family:'Inter',Helvetica] font-normal text-black text-xs tracking-[0] leading-[22px]">
                                Vous, votre agent et votre notaire
                            </p>
                        </div>
                    </div>
                </div>

                {/** réassurance */}
                <div className="flex flex-row flex-wrap items-center justify-center gap-[23px] relative flex-[0_0_auto]">
                    <div className="flex items-center justify-center gap-2 relative flex-[0_0_auto]">
                        <CheckIcon className="!relative !w-4 !h-4" color="#1DF36C" />
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                            Un notaire rapidement ⭐
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-2 relative flex-[0_0_auto]">
                        <CheckIcon className="!relative !w-4 !h-4" color="#1DF36C" />
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                            Un suivi de dossier simplifié 👍
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-2 relative flex-[0_0_auto]">
                        <CheckIcon className="!relative !w-4 !h-4" color="#1DF36C" />
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                            Des explications claires 🙂
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-2 relative flex-[0_0_auto]">
                        <CheckIcon className="!relative !w-4 !h-4" color="#1DF36C" />
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                            Un gain de temps et de sérénité 🧘
                        </div>
                    </div>
                </div>

            </header>
        </Container>
    );
}

export default HeaderNew;
