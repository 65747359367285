import React from "react";
import styled from "styled-components";
import { Container } from "./Container";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export const PourquoiS = styled.div`

h2{
    font-size: 64px;
    font-weight: 500;
    line-height: 1.32em;
    }
    .tag-line,ul{
    font-weight: normal;
    font-size: 18px;
    position: relative;
    line-height: 1.7em;
    color: #73737b;
    }
ul{
    list-style-type: none;
    }
`;



function PourquoiClotere(args) {
    return (
        <>
        <Container>
        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          aria-hidden="true"
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
        >
          <defs>
            <pattern
              x="50%"
              y={-1}
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-teal-600">Accédez simplement à votre notaire</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Pourquoi choisir
              Clotere ?</h1>
              <p className="mt-6 text-lg leading-8 text-gray-700">
              Chez Clotere nous avons décidé de <span className="font-bold text-teal-600">digitaliser votre passage chez le notaire</span> pour vous faire gagner du temps dans votre achat immobilier, votre succession...etc. 
              <br/>Avec l'aide des notaires et des agents immobiliers nous vous proposons <span className="font-bold text-teal-600">une solution digitale simple, rapide et sécurisée</span>.
              </p>
            </div>
          </div>
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <ul role="list" className="mt-8 space-y-8 text-gray-600 text-[20px]">
                <li className="flex gap-x-3">
                  <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                  <span>
                  Un notaire rapidement 🙂
                  </span>
                </li>
                <li className="flex gap-x-3">
                <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                  <span>
                  Un suivi de dossier simplifié 👍
                  </span>
                </li>
                <li className="flex gap-x-3">
                <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                  <span>
                  Des explications claires et des mots simples 🙂
                  </span>
                </li>
                <li className="flex gap-x-3">
                <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                  <span>
                  Un gain de temps et de la sérénité 🧘
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
       
        
      </div>
    </div>
        </Container>
        </>
    );
}

export default PourquoiClotere;

