import React from "react";
import { Container, Row, Col} from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';
import CardClotereVendeurSidebar from "./CardClotereVendeurSidebar";
import RecherchesFrequentes from "./RecherchesFrequentes";
import ArticlesSimilaires from "./ArticlesSimilaires";
import HeaderPost from './HeaderPost';
import BlocCtaVendeur from "../components/BlocCtaVendeur";
import { InformationCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";



function ArticleRoleNotaire(args) {

    return (
        <>
            <Helmet>
                <title>Quels sont les documents nécessaires pour vendre un bien immobilier ?</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Découvrez les documents indispensables pour vendre ou acheter un bien immobilier, des diagnostics aux justificatifs, et préparez votre transaction en toute sérénité. ' />
                <meta property="og:url" content="https://clotere.fr/fiches-pratiques-notaire/document-de-vente-notaire" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            <HeaderPost titre="Quels sont les documents nécessaires pour vendre un bien immobilier ?" />

            <div>
                <>

                    <section className="content">
                        <Container>
                            <Row>
                                <Col className="col-sm-9 col-xs-12 article mx-auto max-w-3xl text-base/7 text-gray-700">

                                    <p className="mt-6 text-lg">
                                        La vente d'un bien immobilier implique la préparation de divers documents essentiels pour assurer la transparence et la conformité légale de la transaction.<br />
                                        <br />
                                        La signature d’un compromis ou d’une promesse de vente formalise l’engagement réciproque de l’acheteur et du vendeur. Pour que cet engagement soit éclairé, plusieurs documents sont indispensables. Ils permettent à chaque partie de disposer, avant la transaction, de toutes les informations essentielles sur le bien et les conditions de la vente.
                                        <br />
                                        Mais quels documents doivent être inclus dans ce dossier ?
                                    </p>
                                    <div className="rounded-md bg-blue-50 p-4">
                                        <div className="flex">
                                            <div className="shrink-0">
                                                <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                                            </div>
                                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                                <p className="text-base text-blue-700 m-0"><b>A noter :</b> les démarches et les documents requis diffèrent en fonction du type de bien concerné, qu’il s’agisse d’une maison, d’un appartement ou d’un terrain.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="text-base/7 font-semibold text-indigo-600 mt-6">Document vendeur</p>
                                    <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                        Les documents à fournir par le vendeur pour le compromis de vente
                                    </h2>
                                    <p>
                                        Pour finaliser la vente, le vendeur doit réunir les documents suivants :
                                    </p>
                                    <ul role="list" className="mt-8  space-y-8 text-gray-600">
                                        <li className="flex gap-x-3">
                                            <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                            <span>
                                                <strong className="font-semibold text-gray-900">Document d'identité : </strong> justificatifs d'identité, de situation familiale et d'adresse .
                                            </span>
                                        </li>
                                        <li className="flex gap-x-3">
                                            <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                            <span>
                                                <strong className="font-semibold text-gray-900">Titre de propriété : </strong> Atteste que vous êtes le propriétaire légal du bien.
                                            </span>
                                        </li>
                                        <li className="flex gap-x-3">
                                            <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                            <span>
                                                <strong className="font-semibold text-gray-900">Justificatifs de charges et taxes :</strong> Le dernier avis d'imposition, de taxe foncière ainsi que de taxe d'habitation
                                            </span>
                                        </li>
                                        <li className="flex flex-column gap-x-3">
                                            <div className="flex gap-x-3"><CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                                <span>
                                                    <strong className="font-semibold text-gray-900">Dossier de diagnostics techniques (DDT) .</strong>
                                                </span>
                                            </div>

                                            <p>Ce dossier contient tout un ensemble de diagnostics attestant de l'état du bien, dont le nombre varie en fonction de sa nature, de sa date de construction et de sa localisation.</p>
                                            <ul>
                                                <li>■ le diagnostic de performance énergétique (DPE) ;</li>
                                                <li>■ le constat de risque d'exposition au plomb ;</li>
                                                <li>■ l'état d'amiante ;</li>
                                                <li>■ l'état de l'installation intérieure de l'électricité ;</li>
                                                <li>■ l'état de l'installation intérieure du gaz ;</li>
                                                <li>■ l'état de l'installation d'assainissement non collectif ;</li>
                                                <li>■ l'état parasitaire relatif aux termites ;</li>
                                                <li>■ l'état des risques naturels et technologiques ;</li>
                                                <li>■ l'information sur la présence d'un risque de mérule (champignon) ;</li>
                                                <li>■ le diagnostic bruit. </li>
                                            </ul>
                                        </li>
                                        <li className="flex flex-column gap-x-3">
                                            <div className="flex gap-x-3"><CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                                <span>
                                                    <strong className="font-semibold text-gray-900">Documents relatifs à la copropriété : .</strong>
                                                </span>
                                            </div>

                                            <p>Si le bien vendu est un lot en copropriété, la loi Alur du 27 mars 2014 impose au vendeur de fournir également certains documents relatifs à :.</p>
                                            
                                            <ul>
                                                <li>■ <b>Superficie du lot</b> : le métrage selon la “loi Carrez”. ;</li>
                                                <li>■ <b>Organisation de l’immeuble</b> : inclut le règlement de copropriété, l’état descriptif de division, les procès-verbaux des assemblées générales des trois dernières années, le carnet d’entretien de l’immeuble, ainsi qu’une notice d’information sur les droits et obligations des copropriétaires et le fonctionnement des instances du syndicat de copropriété.</li>
                                            </ul>
                                            <br/><p>En plus, des documents relatifs à la situation financière de la copropriété doivent être fournis :</p>
                                            <ul>
                                                <li>■ <b>Charges courantes</b> : détail des charges du budget prévisionnel et hors budget déjà payées par le vendeur.</li>
                                                <li>■ <b>Sommes dues à la copropriété</b> : montant des éventuelles dettes transférées à l’acquéreur.</li>
                                                <li>■ <b>Impayés et dettes</b> : état général des impayés de charges au sein du syndicat et des dettes envers les fournisseurs.</li>
                                                <li>■ <b>Fonds de travaux</b> : part du fonds de travaux liée au lot vendu et montant de la dernière contribution versée par le vendeur, si applicable.</li>
                                            </ul>
                                            <div className="rounded-md bg-blue-50 p-4">
                                            <div className="flex">
                                                <div className="shrink-0">
                                                    <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                                                </div>
                                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                                    <p className="text-base text-blue-700 m-0"><b>A noter :</b>Si l'appartement que vous souhaitez vendre se trouve dans un immeuble en copropriété, vous devez faire une demande auprès du syndic afin qu’il vous remettre, entre autres, un « état daté ».</p>
                                                </div>
                                            </div>
                                    </div>
                                        </li>
                                        <li className="flex gap-x-3">
                                            <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                            <span>
                                                <small>Si vous avez réalisé des travaux</small><br />
                                                <strong className="font-semibold text-gray-900">Justificatifs de travaux et garanties : </strong>  Factures, certificats de garantie décennale ou biennale, Certificat de conformité ou permis de construire,  autorisations de travaux, procès-verbal de l'assemblée générale des copropriétaires...etc.
                                            </span>
                                        </li>
                                    </ul>

                                    <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Focus sur la demande des documents obligatoires au syndic.</h2>
                                    <p>Les documents relatifs à la copropriété sont indispensables à la signature de vos documents de vente.<br/>
                                        C'est auprès du syndic que vous en ferez la demande.<br/><br/>
                                        Il est fortement recommandé d'envoyer cette lettre par recommander avec accusé de réception. Cela permet d'anticiper les éventuels retard liés à l'obtention de ces documents.
                                        <br/><br/>
                                        <div  className="py-8 lg:flex-none lg:py-0">
                                            <div className="mx-auto flex items-center px-4 lg:px-8">
                                            <InformationCircleIcon aria-hidden="true" className="size-8 shrink-0 text-indigo-600" />
                                            <div className="ml-4 flex flex-auto flex-col">
                                                <h6 className="font-medium text-gray-900">Modèle de lettre gratuit</h6>
                                                <p className="text-sm text-gray-500">Clotere vous met à disposition un modèle de lettre de demande de documents pour votre vente immobilière, précisant toutes les mentions obligatoires pour le bon déroulement de votre dossier.</p>
                                                <Link to="/vendeur/onboard" className="btn-indigo w-content no-underline">Débutez gratuitement votre dossier Clotere</Link> 
                                            </div>
                                            </div>
                                        </div>
                                        </p>

                                        <p className="text-base/7 font-semibold text-indigo-600 mt-10">Document acheteur</p>
                                    <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                        Les documents à fournir par l'acheteur pour le compromis de vente
                                    </h2>
                                    <p>
                                        L'acheteur doit également présenter certains documents pour valider son achat et rassurer le vendeur :
                                    </p>
                                    <ul>
                                        <li><strong>• Pièce d'identité :</strong> Carte nationale d'identité, passeport ou titre de séjour en cours de validité.</li>
                                        <li><strong>• Justificatif de domicile :</strong> Facture récente (électricité, gaz, eau) ou attestation d'hébergement.</li>
                                        <li><strong>• Relevés de comptes bancaires :</strong> Pour prouver la capacité de financement de l'achat.</li>
                                        <li><strong>• Offre de prêt bancaire :</strong> Si l'achat est financé par un crédit immobilier, l'offre de prêt validée est indispensable.</li>
                                        <li><strong>• Livret de famille :</strong> En cas d'achat en couple ou pour préciser la situation familiale (mariage, PACS, enfants).</li>
                                        <li><strong>• Acte de mariage ou contrat de mariage :</strong> Si applicable, pour clarifier le régime matrimonial en cas d'achat conjoint.</li>
                                        <li><strong>• Attestation de situation professionnelle :</strong> Contrat de travail, bulletin de salaire récent ou autre justificatif prouvant la stabilité des revenus.</li>
                                        <li><strong>• RIB :</strong> Pour effectuer les paiements ou virements nécessaires à la transaction.</li>
                                    </ul>
                                    <p>
                                        Clotere rappel à votre acheteur, tous les documents à fournir, et ceux dès la signature de l'offre d'achat. Chaque document est contrôlé et transmis au notaire dans la foulée. 
                                       </p>
                                    <div  className="py-8 lg:flex-none lg:py-0">
                                            <div className="mx-auto flex items-center px-4 lg:px-8">
                                            <InformationCircleIcon aria-hidden="true" className="size-8 shrink-0 text-indigo-600" />
                                            <div className="ml-4 flex flex-auto flex-col">
                                                <h6 className="font-medium text-gray-900">Gagnez en moyenne 20 jours sur la signature de l'acte de vente</h6>
                                                <p className="text-sm text-gray-500">Cette réactivité et ce process vous permet de gagner en moyenne 20 jours sur la signatude l'acte de vente authentique</p>
                                                <Link to="/vendeur/onboard" className="btn-indigo w-content no-underline">Débutez gratuitement votre dossier Clotere</Link> 
                                            </div>
                                            </div>
                                        </div>

                                        <h2 className="mt-10 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                        Conclusion
                                    </h2>
                                    <p>
                                        Une transaction immobilière implique la préparation minutieuse de nombreux documents de la part des deux parties. L'acquéreur comme le vendeur doivent s'assurer de fournir les pièces nécessaires pour faciliter le travail du notaire et garantir la conformité de la transaction.
                                    </p>
                                </Col>
                                <Col className="col-sm-3 col-xs-12 sidebar hidden sm:block">
                                    <CardClotereVendeurSidebar />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </>
                <BlocCtaVendeur />
                <RecherchesFrequentes />
                <ArticlesSimilaires title="Articles similaires" />
                <Footer />
            </div >
        </>
    );
}

export default ArticleRoleNotaire;