import Rapide from "../img/rapide.webp";
import Simple from "../img/simple.webp";
import Gratuit from "../img/gratuit.webp";
import { Container } from "./Container";
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { BoldIcon, BoltIcon, FireIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";



export const Avantages = () => {
 

    return (
        <>
        <Container>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3 justify-center">
              <div className="flex flex-col">
                <dt className="text-[30px] font-semibold leading-7 text-gray-900">
                <div className="mb-6 flex h-20 w-20 items-center justify-center justify-center rounded-lg bg-teal-500">
                  <HandThumbUpIcon className="h-12 w-12 text-white"/>
                  </div>
                  Simple
                </dt>
                <dd className="mt-3  flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto  text-[20px]">Trouvez votre notaire par région ou ville</p>
                  
                </dd>
              </div>
              <div className="flex flex-col">
                <dt className="text-[30px] font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-20 w-20 items-center justify-center justify-center rounded-lg bg-teal-500">
                  <BoltIcon className="h-12 w-12 text-white"/>
                  </div>
                  Rapide
                </dt>
                <dd className="mt-3  flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto  text-[20px]">Prenez contact rapidement avec votre notaire</p>
                  
                </dd>
              </div>
              <div className="flex flex-col justify-center">
                <dt className="text-[30px] font-semibold leading-7 text-gray-900">
                <div className="mb-6 flex h-20 w-20 items-center justify-center justify-center rounded-lg bg-teal-500">
                  <FireIcon className="h-12 w-12 text-white"/>
                  </div>
                  Gratuit
                </dt>
                <dd className="mt-3 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto text-[20px]">Choisissez le notaire idéal gratuitement</p>
                  
                </dd>
              </div>
          </dl>
        </div>
        </Container>
        </>
    );
};