import React from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



function CardClotereVendeurSidebar() {
    const navigate = useNavigate();
    
    return (
        <div className="relative bg-[#b4f6c7] rounded-3xl mt-6 p-2">
            <div aria-hidden="true" className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block">

                <svg
                    fill="none"
                    width={200}
                    height={150}
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                    className="absolute top-full -translate-x-1/3 -translate-y-1/3 transform xl:-translate-y-1/2"
                >
                    <defs>
                        <pattern
                            x={0}
                            y={0}
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-indigo-500" />
                        </pattern>
                    </defs>
                    <rect fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" width={404} height={384} />
                </svg>
            </div>
            <div className="relative mx-auto p-4">
              <h4 className="font-bold tracking-tight text-black mb-1">
              Vendez seul, comme un pro
              </h4>
              <p className="text-sm pt-2">
              Obtenez gratuitement, tous les outils dont vous avez besoin pour vendre votre bien immobilier seul 🚀
              </p>
              <Link to="/vendeur/onboard" className="btn-main no-underline text-white mb-2">
                Commencer votre dossier de vente
            </Link>
              </div>
            
        </div>
    );
}

export default CardClotereVendeurSidebar;