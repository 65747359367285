import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Row, Col } from "reactstrap";
import { ArrowRightIcon, ArrowUpRightIcon, ChatBubbleBottomCenterIcon, CheckIcon, DocumentTextIcon, QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { DocumentCurrencyEuroIcon } from "@heroicons/react/24/outline";
import { Link } from 'react-router-dom';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import BlocAnnonce from "./components/BlocAnnonce";
import ContentModalDiagGes from "./components/annonce/ModalDiagGes";
import ContentModalDiagDpe from "./components/annonce/ModalDiagDpe";
import ContentModalDiagErp from "./components/annonce/ModalDiagErp";
import ContentModalDiagCarrez from "./components/annonce/ModalDiagCarrez";
import CardAcheteur from "./blog/CardClotereAcheteurAnnonce";
//image 
import backAnnonce from './img/annonce/back-annonce.webp';
import { CheckCircleIcon } from "@heroicons/react/24/solid";



function HeaderAnnonce() {
    const [open, setOpen] = useState(false);
    const handleContact = (event) => {
        console.log("envoyer");
    }
    const [openCertifiee, setOpenCertifiee] = useState(false);
    return (
        <Col md="12" xs="12" className="text-center">
            <div>
                <div>
                    <img alt="" src={backAnnonce} className="h-32 w-full object-cover lg:h-48" />
                </div>
                <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                        <div className="flex">
                            <span className="flex justify-center size-28 overflow-hidden items-center rounded-full bg-gradient-main border-2 border-teal-300 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                </svg>
                            </span>
                        </div>
                        <div className="mt-5 sm:flex  sm:flex-1 sm:items-start sm:justify-end sm:space-x-6 sm:pb-1">
                            <div className="min-w-0 flex-1 sm:hidden md:block">
                                <p className="text-2xl sm:text-xl font-bold text-gray-900 text-left sm:mb-0 hover:underline hover:cursor-pointer" onClick={() => setOpenCertifiee(true)}>Annonce certifiée par Clotere <QuestionMarkCircleIcon className="text-slate-300 inline-flex w-6" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/** modal diag dpe classe */}
            <Dialog open={openCertifiee} onClose={setOpenCertifiee} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                        Annonce certifiée
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Les informations et les documents de vente de cette annonce ont été vérifiés par Clotere.fr.
                                            <br /><br /><small>Vous êtes acheteurs ? Voila ce que Clotere peut faire pour vous</small>
                                        </p>
                                    </div>
                                    <div>
                                        <ul role="list" className="divide-y divide-gray-100 text-left">
                                            <li className="flex gap-x-2 py-3">
                                                <CheckCircleIcon className="text-emerald-500 size-8" />
                                                <div className="min-w-0">
                                                    <p className="text-sm/5 font-semibold text-gray-900 m-0">Trouvez toutes les réponses à vos questions</p>
                                                </div>
                                            </li>
                                            <li className="flex gap-x-2 py-3">
                                                <CheckCircleIcon className="text-emerald-500 size-8" />
                                                <div className="min-w-0">
                                                    <p className="text-sm/5 font-semibold text-gray-900 m-0">Réservez un créneau de visite simplement</p>
                                                </div>
                                            </li>
                                            <li className="flex gap-x-2 py-3">
                                                <CheckCircleIcon className="text-emerald-500 size-8" />
                                                <div className="min-w-0">
                                                    <p className="text-sm/5 font-semibold text-gray-900 m-0">Faites une offre d'achat conforme et sécurisée</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <button
                                    type="button"
                                    onClick={() => setOpenCertifiee(false)}
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Fermer
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </Col>
    );
}

function Locatif(args) {
    return (
        <>
            <div className="flex flex-row justify-between align-middle  mb-2 mt-10">
                <p className="text-sm text-slate-500 mb-0">Revenus locatifs</p>
                <span className="inline-flex items-center rounded-md text-sm font-bold text-[#15b8a6]">
                    Vendu loué 🥳
                </span>
            </div>

            <div className="grid grid-flow-col justify-stretch  gap-2">
                <div className="bg-teal-500 flex flex-wrap items-baseline justify-between gap-0 p-4 rounded-xl text-white">
                    <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight mb-0">{args.loyer} €<span className="text-sm ml-2">/mois</span></dd>
                    <dt className="text-sm/6 font-medium">Loyer ( avec charges)</dt>
                </div>
                <div className="bg-teal-500 flex flex-wrap items-baseline justify-between gap-0 p-4 rounded-xl text-white">
                    <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight mb-0">{args.charges} €<span className="text-sm ml-2">/mois</span></dd>
                    <dt className="text-sm/6 font-medium">Charges mensuelle</dt>
                </div>
            </div>

        </>
    )
}
function Charges(args) {
    return (
        <>
            <p className="text-sm text-slate-500 mb-2 mt-10">Charges</p>
            <div className="grid grid-flow-col justify-stretch gap-2">
                <div className="bg-slate-200 flex flex-wrap items-center justify-between gap-0 p-4 rounded-xl text-black">
                    <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight mb-0">{args.fonciere} €<span className="text-sm ml-2">/an</span></dd>
                    <dt className="text-sm/6 font-medium">Taxe foncière</dt>
                </div>
                <div className="bg-slate-200 flex flex-wrap items-baseline justify-between gap-0 p-4 rounded-xl text-black">
                    <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight">{args.copro} €<span className="text-sm ml-2">/an</span></dd>
                    <dt className="text-sm/6 font-medium">Charges copropriété</dt>
                </div>
            </div>

        </>
    )
}
function Copropriete(args) {
    return (
        <>
            <p className="text-sm text-slate-500 mb-2 mt-10">Copropriété</p>
            <ul className="flex flex-column p-0 rounded-lg gap-1">
                <li className="bg-[#fff] flex flex-row justify-between p-2 rounded-lg items-center">
                    <p className="m-0 text-base font-regular">Syndic de copropriété</p>
                    <p className="m-0 text-lg font-regular">{args.copro}</p>
                </li>
                <li className="bg-[#fff] flex flex-row justify-between p-2 rounded-lg  items-center">
                    <p className="m-0 text-base font-medium">Procédure(s) en cours</p>
                    <p className="m-0 text-lg font-medium">{args.procedure === "non" && <>Aucune</>} {args.procedure === "oui" && <>Oui</>}</p>
                </li>
                {args.procedure === "oui" && <>
                    <li className="bg-[#fff] flex flex-column justify-start p-2 rounded-lg  items-start">
                        <p className="m-0 text-base font-medium">Description</p>
                        <p className="m-0 text-sm font-regular">{args.description_procedure}</p>
                    </li>

                </>}

            </ul>

        </>
    )
}
function Performance(args) {
    //modal diag
    const [openClasse, setOpenClasse] = useState(false);
    const [openGes, setOpenGes] = useState(false);
    return (
        <>
            <p className="text-sm text-slate-500 mb-2 mt-10">Performances énergétique</p>
            <ul className="flex flex-column p-0 rounded-lg gap-1">
                <li className="bg-[#ECFCF7] flex flex-row justify-between items-center p-2 rounded-lg">
                    <p className="m-0 text-base font-medium hover:underline hover:cursor-pointer" onClick={() => setOpenClasse(true)}>Classe énergie <QuestionMarkCircleIcon className="text-slate-300 inline-flex w-6" /></p>
                    <div className="text-lg bg-[#CDFD33] text-black px-3 py-2 text-center rounded-lg font-bold border-4 border-white">
                        {args.classe}
                    </div>
                </li>
                <li className="bg-[#ECFCF7] flex flex-row justify-between items-center p-2 rounded-lg">
                    <p className="m-0 text-base font-medium  hover:underline hover:cursor-pointer" onClick={() => setOpenGes(true)}>GES <QuestionMarkCircleIcon className="text-slate-300 inline-flex w-6" /></p>
                    <div className="text-lg bg-[#D2ADF1] text-black px-3 py-2 text-center rounded-lg font-bold border-4 border-white">
                        {args.ges}
                    </div>
                </li>
            </ul>
            {/** modal diag dpe classe */}
            <Dialog open={openClasse} onClose={setOpenClasse} className="relative z-10">
                <ContentModalDiagDpe />
            </Dialog>
            {/** modal diag erp */}
            <Dialog open={openGes} onClose={setOpenGes} className="relative z-10">
                <ContentModalDiagGes />
            </Dialog>
        </>
    )
}
function Diag(args) {
    //modal diag
    const [openDpe, setOpenDpe] = useState(false);
    const [openErp, setOpenErp] = useState(false);
    const [openCarrez, setOpenCarrez] = useState(false);
    return (
        <>
            <p className="text-sm font-medium text-gray-500 mb-2 mt-10">Diagnostics</p>
            <div className="col-span-1 flex rounded-md  mb-2">
                <div className="bg-[#2AC1AF] flex w-20 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                    <DocumentTextIcon className="size-10" />
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-[#ECFCF7] bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                        <a className="text-base font-medium text-gray-900 hover:text-gray-600  no-underline  hover:underline hover:cursor-pointer" onClick={() => setOpenDpe(true)}>
                            Diagnostics de performance (DPE) <QuestionMarkCircleIcon className="text-slate-300 inline-flex w-6" />
                        </a>
                        <br />
                        {args.dpe &&
                            <p className="text-gray-500 inline-flex mt-2 m-0"><CheckIcon className="size-5" /> Réalisé et conforme </p>
                        }
                        {!args.dpe &&
                            <p className="text-gray-500 inline-flex mt-2 m-0"><XMarkIcon className="size-5" /> Non réalisé </p>
                        }
                    </div>
                    <div className="truncate px-4 py-2 text-sm">
                        {args.dpe &&
                            <>
                                <a href={args.dpe[0].url} target="blank" type="button" className="text-base font-medium text-gray-900 hover:text-gray-600  underline">Voir</a>
                            </>
                        }
                    </div>
                </div>

            </div>
            <div className="col-span-1 flex rounded-md mb-2 ">
                <div className="bg-[#2AC1AF] flex w-20 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                    <DocumentTextIcon className="size-10" />
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-[#ECFCF7] bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                        <a className="text-base font-medium text-gray-900 hover:text-gray-600 no-underline  hover:underline hover:cursor-pointer" onClick={() => setOpenErp(true)}>
                            Etat des risques et pollutions (ERP) <QuestionMarkCircleIcon className="inline-flex w-6  " />
                        </a>
                        <br />
                        {args.erp &&
                            <p className="text-gray-500 inline-flex mt-2 m-0"><CheckIcon className="size-5" /> Réalisé et conforme </p>
                        }
                        {!args.erp &&
                            <p className="text-gray-500 inline-flex mt-2 m-0"><XMarkIcon className="size-5" /> Non réalisé </p>
                        }
                    </div>
                    <div className="truncate px-4 py-2 text-sm">
                        {args.erp &&
                            <>
                                <a href={args.erp[0].url} target="blank" type="button" className="text-base font-medium text-gray-900 hover:text-gray-600  underline">Voir</a>
                            </>
                        }
                    </div>

                </div>
            </div>
            <div className="col-span-1 flex rounded-md  ">
                <div className="bg-[#2AC1AF] flex w-20 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                    <DocumentTextIcon className="size-10" />
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-[#ECFCF7] bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                        <a className="text-base font-medium text-gray-900 hover:text-gray-600 no-underline  hover:underline hover:cursor-pointer" onClick={() => setOpenCarrez(true)}>
                            Certification Loi Carrez <QuestionMarkCircleIcon className="inline-flex w-6" />
                        </a>
                        <br />
                        {args.carrez &&
                            <p className="text-gray-500 inline-flex mt-2 m-0"><CheckIcon className="size-5" /> Réalisé et conforme </p>
                        }
                        {!args.carrez &&
                            <p className="text-gray-500 inline-flex mt-2 m-0"><XMarkIcon className="size-5" /> Non réalisé </p>
                        }
                    </div>
                    <div className="truncate px-4 py-2 text-sm">
                        {args.carrez &&
                            <>
                                <a href={args.carrez[0].url} target="blank" type="button" className="text-base font-medium text-gray-900 hover:text-gray-600  underline">Voir</a>
                            </>
                        }
                    </div>

                </div>
            </div>
            {/** modal diag dpe */}
            <Dialog open={openDpe} onClose={setOpenDpe} className="relative z-10">
                <ContentModalDiagDpe />
            </Dialog>
            {/** modal diag erp */}
            <Dialog open={openErp} onClose={setOpenErp} className="relative z-10">
                <ContentModalDiagErp />
            </Dialog>
            {/** modal diag carrez */}
            <Dialog open={openCarrez} onClose={setOpenCarrez} className="relative z-10">
                <ContentModalDiagCarrez />
            </Dialog>
        </>

    )
}

function FraisNotaire(args) {
    return (
        <>
            <p className="text-sm text-slate-500 mt-10">Estimation de vos frais de notaire</p>

            <div className="overflow-hidden rounded-xl border border-gray-200">
                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                    <div className="bg-[#ECFCF7] flex w-20 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <DocumentCurrencyEuroIcon className="text-[#2AC1AF] size-10" />
                    </div>
                    <div className="text-3xl font-bold text-gray-900">{args.frais} €</div>
                    {/**
                    <Menu as="div" className="relative ml-auto">
                        <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                            <button href="#" className="text-base font-medium text-gray-900 hover:text-gray-600  underline">
                                Calculer
                            </button>
                        </MenuButton>
                    </Menu>
                     */}
                </div>
                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
                    <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-700">Quand devrais-je payer ses frais ?</dt>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-700">Comment réduire mes frais de notaire ?</dt>
                    </div>
                </dl>
                <Link to="/fiches-pratiques-notaire" target="_blank" className="btn-indigo no-underline inline-flex">Suivez nos conseils <ArrowUpRightIcon className="size-6" /></Link>
            </div>
        </>

    )
}
function Visite(args) {
    return (
        <>
            <p className="text-sm text-slate-500 mt-10">Informations pour visiter ce bien</p>
            <div className="flex items-center justify-between truncate rounded-r-md border-b border-r border-t border-[#ECFCF7] bg-white">
                <div className="truncate px-4 py-2 text-sm">
                    <a className="text-base font-medium text-gray-900 no-underline">
                        Préférence de visite
                    </a>
                </div>
                <div className="truncate px-4 py-2">
                    <span className="inline-flex items-center rounded-full bg-purple-50 px-2 py-1 text-sm font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                        {args.creneau}
                    </span>
                </div>
            </div>
            <div className="flex justify-center gap-3 mt-4">

            </div>
        </>

    )
}
function CtaFinDePage(args) {
    const [open, setOpen] = useState(false);
    const [messageSucces, setMessageSucces] = useState(false);
    
    const handleContact = (event) => {
        event.preventDefault();
        console.log("envoyer");
        const nom = event.target.nom.value;
        const prenom = event.target.prenom.value;
        const email = event.target.email.value;
        const telephone = event.target.telephone.value;
        const message = event.target.message.value;
        const emailVendeur = args.emailVendeur;
        const idAnnonce = args.idAnnonce;

        const sendToZapier = async () => {
            const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2iq4jsb/"; // 👈 Specify your Zapier Webhook URL here
            try {
              const response = await fetch(zapierURL, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({nom,prenom,email,telephone,message,emailVendeur,idAnnonce})
              });
              const res = await response.json();
              console.log(res);
              if(res){
                setMessageSucces(true);
              }
            } catch (e) {
              console.log(e);
            }
          };
          sendToZapier();
    }
    return (
        <>
        {open === false &&
        
        <>
        <div className="z-[10000000000000000000000] text-center pointer-events-none fixed inset-x-0 bottom-0 sm:flex justify-center sm:px-6 sm:pb-5 lg:px-8">
                <div className="shadow-md pointer-events-auto flex items-center justify-center gap-x-6 bg-gradient-to-r from-teal-200 to-teal-500 p-3 rounded-xl">
                    <p className="text-sm/6 text-slate-800 font-bold m-0">
                    Vous souhaitez visiter ce bien ou poser une question ?
                        <button href="#" className="btn-indigo no-underline mt-2 flex items-center" onClick={() => setOpen(true)}>
                            Je veux visiter
                            <ArrowRightIcon aria-hidden="true" className="ml-2  mr-1.5 size-5 text-gray-400" />
                        </button>
                    </p>
                </div>
            </div>
        </>
        }
            
            {/** modal contact */}
            <Dialog open={open} onClose={setOpen} className="relative z-100000000000000000000000">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-screen-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                    <ChatBubbleBottomCenterIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                        Contacter le propriétaire
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Vous pouvez demander à visiter ce bien depuis notre formulaire de contact
                                        </p>
                                        {setMessageSucces === true && <>
                                            <div className="rounded-md bg-green-50 p-4">
                                            <div className="flex">
                                                <div className="shrink-0">
                                                <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
                                                </div>
                                                <div className="ml-3">
                                                <p className="text-sm font-medium text-green-800">Votre message a bien été envoyé</p>
                                                </div>
                                                <div className="ml-auto pl-3">
                                                <div className="-mx-1.5 -my-1.5">
                                                    <button
                                                    type="button"
                                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                                    >
                                                    <span className="sr-only">Fermer</span>
                                                    <XMarkIcon aria-hidden="true" className="size-5" />
                                                    </button>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </>}
                                            <form action="#" method="POST" className="space-y-3 text-left" onSubmit={handleContact}>

                                            <div className="grid gap-4 sm:grid-cols-2">
                                                    <div>
                                                        <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                            Votre nom
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="nom"
                                                                name="nom"
                                                                type="text"
                                                                required
                                                                className="input-clotere"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="prenom" className="block text-sm/6 font-medium text-gray-900">
                                                            Votre prénom
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="prenom"
                                                                name="prenom"
                                                                type="text"
                                                                required
                                                                className="input-clotere"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                        Votre email
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            name="email"
                                                            type="email"
                                                            required
                                                            className="input-clotere"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                        Votre téléphone
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            name="telephone"
                                                            type="phone"
                                                            required
                                                            className="input-clotere"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                        Votre message
                                                    </label>
                                                    <div className="mt-2">
                                                    <textarea
                                                    id="message"
                                                    name="message"
                                                    rows={4}
                                                    className="input-clotere"
                                                    required
                                                    />
                                                    </div>
                                                </div>

                                                <div>
                                                    <button
                                                        type="submit"
                                                        className="btn-indigo"
                                                    >
                                                        Envoyer
                                                    </button>
                                                </div>
                                            </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>

    )
}




function AnnonceVendeur(args, props) {
    const [annonceData, setAnnonceData] = useState(null);
    const { id } = useParams();
    //console.log("id", id);
    useEffect(() => {
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/annonce/${id}`;

        fetch(
            URL,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    'content-type': 'application/x-www-form-urlencoded',
                    "Accept": "application/json, text/plain, /"
                },
            })
            .then((res) => res.json())
            .then((res) => {
                console.log("res", res);
                setAnnonceData(res.fields);

            })
            .catch((error) => console.log(error));

    }, []);
    return (
        <>
            {annonceData && (
                <div>
                    <div className="bg-gradient-to-br from-gray-50 to-fuchsia-50 pb-32">
                        <Row className="relative isolate">
                            <Col md="12" xs="12">
                                <HeaderAnnonce />
                            </Col>
                        </Row>
                        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 mt-10" >
                            <BlocAnnonce prix={annonceData.prix} plateforme={annonceData.plateforme} url={annonceData.url} />
                            {annonceData.vendu === "louer" && (
                                <Locatif loyer={annonceData.loyer_mensuel} charges={annonceData.charges_loc} />
                            )}
                            <Charges fonciere={annonceData.taxe_fonciere} copro={annonceData.charges_copro} />
                            <Copropriete copro={annonceData.copropriete} procedure={annonceData.procedure} description_procedure={annonceData.description_procedure} />
                            <Performance classe={annonceData.classe_energie} ges={annonceData.ges} />
                            <Diag dpe={annonceData.dpe} erp={annonceData.erp} carrez={annonceData.carrez} />
                            <FraisNotaire frais={annonceData.frais_notaire} />
                            <CtaFinDePage idAnnonce={id} emailVendeur={annonceData.email_from_user} />
                            <CardAcheteur idAnnonce={id} />
                        </div>
                    </div>
                </div>
            )}
            {!annonceData && (
                <>

                </>
            )}
        </>
    );
}

export default AnnonceVendeur;