import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {Container,Col,FormGroup,Label,Input,Tooltip,} from "reactstrap";
import Loading from "../../components/Loading.js";
import FindePage from "../FindePage.js";
import Footer from "../../components/Footer.js";
import RechercheParVille from "../RechercheParVille.js";
import CardNotaire from "../CardNotaire.js";
import CtaNotaire from "../CtaNotaire.js";
import axios from "axios";
import { Navbar } from '../../Navbar.js';
import {ColNotaire,Content,FormS,Pagination,RowFiltres,} from "../../style/Annuaire.js";
import { EmptyData } from "./component/EmptyData.js";
import { ChevronRightIcon } from '@heroicons/react/20/solid'

function NotairesVilleLille(args) {
  const [notaires, setNotaires] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [state, setState] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [data, setData] = useState([]);
  const [dataNotaires, setDataNotaires] = useState([]);
  const [itemsPage, setItemsPage] = useState([]);

  const namePage = "Lille";

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 30;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const totalPages = Math.ceil(notaires?.length / itemsPerPage);

  const SPREADSHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const RANGE = "Haut de France";

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setItemsPage(notaires?.slice(indexOfFirstItem, indexOfLastItem));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Cette option permet d'avoir un défilement fluide
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
        );
        const rows = response.data.values;
        //console.log(rows);
        setData(rows);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 1) {
      const headers = data[0];
      const rows = data.slice(1);
      const formatted = rows.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index] || "";
        });
        return obj;
      });

      if (formatted != null) {
        var filteredData = formatted.filter(
          (item) => item.ville === namePage.toUpperCase()
        );

        filteredData.sort((a, b) => {
          if (a.utilise_clotere === "oui" && b.utilise_clotere === "non") {
            return -1;
          }
          if (a.utilise_clotere === "non" && b.utilise_clotere === "oui") {
            return 1;
          }
          return 0;
        });

        setNotaires(filteredData);
        setDataNotaires(filteredData);

        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (notaires != null) {
      setItemsPage(notaires?.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [notaires]);

  useEffect(() => {
    if (notaires != null) {
      var newItems = notaires?.slice(indexOfFirstItem, indexOfLastItem);
      //console.log(newItems);
      setItemsPage(newItems);
    }

    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    //console.log(dataNotaires);
    if (state == true) {
      var filteredData = dataNotaires.filter(
        (item) => item.utilise_clotere === "oui"
      );
      //console.log(filteredData);
      setNotaires(filteredData);
    } else {
      var filteredData = dataNotaires.filter(
        (item) => item.ville === namePage.toUpperCase()
      );
      //console.log(filteredData);
      setNotaires(filteredData);
    }

    setCurrentPage(1);
  }, [state]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
    <Helmet>
      <title>Trouver votre notaire à Lille</title>
      <meta name="robots" content="index,follow"/>
      <meta name="googlebot" content="index,follow"/>
      <meta name='description' content='Trouvez votre Notaire à Lille grâce à Clotere, la solution qui simplifie votre passage chez le notaire.' />
      <meta property="og:url" content="https://clotere.fr/notaires-a-lille"/>
      <meta property="og:site_name" content="Clotere"/>
      <meta property="og:image" content="https://clotere.fr/facebook-share-webp"/>
      <meta property="og:type" content="website"/>
      </Helmet> 
      <div className="bg-gradient-to-t from-teal-500 to-teal-50">
        <Container>
          <Navbar
            user={args.user}
          />

          <div className="px-6 py-8 sm:pt-12 pb-12 lg:px-8">
            <div className="mx-auto text-center">
              <nav aria-label="Breadcrumb" className="justify-center hidden sm:flex">
                <ol role="list" className="flex items-center space-x-4">
                  <li>
                    <div>
                      <Link to="/" className="text-gray-400 hover:text-gray-500">
                        Annuaire des notaires de France
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                      <Link
                        to="/fr/notaires"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Notaires Haut de France
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                      <Link
                        to="/fr/notaires"
                        className="ml-4 text-sm font-medium text-emerald-800 hover:text-gray-700"
                      >
                        Notaire à {namePage}
                      </Link>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl">
                Trouvez

                votre notaire

                à <span className="text-bold text-[#1cf36c]">{namePage}</span>
              </h1>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Retrouvez dans l'annuaire des notaires de {namePage}, le notaire idéal pour vous accompagner dans vos démarches
              </p>
            </div>
          </div>
        </Container>
        <Content>
          <Container>
            <RowFiltres>
              <Col md="6" align="left">
                <h3>
                  <small>
                    {notaires.length} notaires à{" "}
                    <span className="textHighlight">{namePage}</span>
                  </small>
                </h3>
              </Col>
              <Col md="6" align="right">
                <FormS>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={state}
                      onClick={() => {
                        setState(!state);
                      }}
                    />
                    <Label check>
                      <a href="#" rel="noreferrer" id="TooltipExample">
                        Gère votre dossier en ligne
                      </a>
                    </Label>
                  </FormGroup>
                </FormS>
                <Tooltip
                  {...args}
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggle}
                >
                  Ces notaires vous assurent un suivi de votre dossier en ligne
                  facilement.
                </Tooltip>
              </Col>
            </RowFiltres>

            {itemsPage.length > 0 ? (
              <ColNotaire>
                {itemsPage.map((col, i) => (
                  <>
                    <CardNotaire
                      tag="a"
                      fiche={itemsPage[i].lien_fiche}
                      key={i}
                      nom={itemsPage[i].nom}
                      adresse={itemsPage[i].adresse}
                      cp={itemsPage[i].cp}
                      ville={itemsPage[i].ville}
                      site={itemsPage[i].site}
                      use={itemsPage[i].utilise_clotere}
                      photo={itemsPage[i].photo_profil}
                    />
                  </>
                ))}
              </ColNotaire>
            ) : (
              <EmptyData />
            )}

            <Pagination>
              <button
                onClick={() => handlePrevious()}
                disabled={currentPage === 1}
              >
                &lt; Précédent
              </button>
              <span>
                Page {currentPage} sur {totalPages}
              </span>
              <button
                className="no-underline inline-flex items-center justify-center px-5 py-[calc(theme(spacing.3)-1px)] rounded-full  border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-[#1DF36C] data-[hover]:bg-gray-800 data-[disabled]:opacity-40"
                onClick={() => handleNext()}
                disabled={currentPage === totalPages}
              >
                Suivant &gt;
              </button>
            </Pagination>

            <RechercheParVille />
            <FindePage ville={namePage} />
          </Container>
          <CtaNotaire />
        </Content>
        <Footer />
      </div>
    </>
  );
}

export default NotairesVilleLille;
