import React, { useState} from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { CheckCircleIcon, ChatBubbleBottomCenterIcon, XMarkIcon } from "@heroicons/react/20/solid";


function CardClotereAcheteurAnnonce(args) {
    const idAnnonce = args.idAnnonce
    const [open, setOpen] = useState(false);
    const [messageSucces, setMessageSucces] = useState(false);
    
    const handleContact = (event) => {
        event.preventDefault();
        //console.log("envoyer");
        const nom = event.target.nom.value;
        const prenom = event.target.prenom.value;
        const email = event.target.email.value;
        const telephone = event.target.telephone.value;

        const sendToZapier = async () => {
            const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2sh5p0m/"; // 👈 Specify your Zapier Webhook URL here
            try {
              const response = await fetch(zapierURL, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({nom,prenom,email,telephone,idAnnonce})
              });
              const res = await response.json();
              console.log(res);
              if(res.status === "success"){
                //console.log("succès");
                setMessageSucces(true);
              }
            } catch (e) {
              console.log(e);
            }
          };
          sendToZapier();
    }

    return (
        <div className="relative bg-[#b4f6c7] rounded-3xl mt-6 p-2">
            <div aria-hidden="true" className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block">
                <svg
                    fill="none"
                    width={200}
                    height={150}
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                    className="absolute top-full -translate-x-1/3 -translate-y-1/3 transform xl:-translate-y-1/2"
                >
                    <defs>
                        <pattern
                            x={0}
                            y={0}
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-indigo-500" />
                        </pattern>
                    </defs>
                    <rect fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" width={404} height={384} />
                </svg>
            </div>
            <div className="relative mx-auto p-4">
                <h4 className="font-bold tracking-tight text-black mb-1">
                    Vous achetez ? Sécurisez votre achat avec un notaire
                </h4>
                <p className="text-lg pt-2">
                    Confiez votre affaire à un notaire pour défendre vos interêts, suivez votre dossier en ligne, réaliser votre offre d'achat certifiée par un notaire, le tout gratuitement 🚀
                </p>
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                    <div>
                        <div className="flex -space-x-2 overflow-hidden">
                            <img
                                alt="trouver mon notaire acheteur"
                                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                className="inline-block size-10 rounded-full ring-2 ring-white"
                            />
                            <img
                                alt="notaire pour votre achat immobilier"
                                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                className="inline-block size-10 rounded-full ring-2 ring-white"
                            />
                            <img
                                alt="trouver mon notaire"
                                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                className="inline-block size-10 rounded-full ring-2 ring-white"
                            />
                            <img
                                alt="trouver un notaire"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                className="inline-block size-10 rounded-full ring-2 ring-white"
                            />
                        </div>
                        <p className="text-lg font-bold m-0">Parlez à un conseiller</p>
                        <p className="text-sm font-light">On vous rapelle dans la journée</p>
                    </div>
                    <div>

                        <a onClick={() => setOpen(true)} className="btn-main no-underline text-white mb-2">
                            Parlez gratuitement à un conseiller
                        </a>
                    </div>
                </div>
            </div>
            {/** modal contact */}
        <Dialog open={open} onClose={setOpen} className="relative z-100000000000000000000000">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-screen-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                    <div>
                        <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                            <ChatBubbleBottomCenterIcon aria-hidden="true" className="size-6 text-green-600" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                Parlez à un expert Clotere
                            </DialogTitle>
                            <div className="mt-2">
                                {messageSucces === true && <>
                                    <div className="rounded-md bg-green-50 p-4">
                                    <div className="flex">
                                        <div className="shrink-0">
                                        <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
                                        </div>
                                        <div className="ml-3">
                                        <p className="text-sm font-medium text-green-800">Merci, on vous rappelle dans 24h 😉.</p>
                                        </div>
                                        <div className="ml-auto pl-3">
                                        <div className="-mx-1.5 -my-1.5">
                                            <button
                                            type="button"
                                            className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                            >
                                            <span className="sr-only">Fermer</span>
                                            <XMarkIcon aria-hidden="true" className="size-5" />
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </>}
                                    <form action="#" method="POST" className="space-y-3 text-left" onSubmit={handleContact}>

                                    <div className="grid gap-4 sm:grid-cols-2">
                                            <div>
                                                <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                    Votre nom
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="nom"
                                                        name="nom"
                                                        type="text"
                                                        required
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="prenom" className="block text-sm/6 font-medium text-gray-900">
                                                    Votre prénom
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="prenom"
                                                        name="prenom"
                                                        type="text"
                                                        required
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                Votre email
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    name="email"
                                                    type="email"
                                                    required
                                                    className="input-clotere"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="nom" className="block text-sm/6 font-medium text-gray-900">
                                                Votre téléphone
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    name="telephone"
                                                    type="phone"
                                                    required
                                                    className="input-clotere"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                className="btn-indigo"
                                            >
                                                Envoyer
                                            </button>
                                        </div>
                                    </form>
                                
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </div>
    </Dialog>
        </div>
        
    );
}

export default CardClotereAcheteurAnnonce;