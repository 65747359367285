import React from "react";
import { Card, CardTitle } from "reactstrap";
import styled from "styled-components";
import { PopupButton } from '@typeform/embed-react';
import { ArrowRightIcon } from '@heroicons/react/20/solid'

export const CardS = styled(Card)`
max-width:70%;
border-radius: 20px;
border: 0;
background-color:transparent;

 color:#fff;
 display:flex;
 flex-direction:row!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:18px;
    text-align:left;
    color:#000;
 }
 @media all and (max-width: 768px) {
    max-width:90%;
    flex-direction:column!important;
    max-width:100%;
  }
`;


function UseClotereCard(args) {
    //console.log("args fiche",args.idnotaire)
    return (
        <CardS className="bg-gradient-to-r from-teal-200 to-teal-500">
            <CardTitle>
                <span role="img">👋</span> Ce notaire vous propose le suivi de dossier en ligne
            </CardTitle>
            <PopupButton
                id="cDphxVXu"
                size={100}
                hidden={{ idnotaire: args.idnotaire }}
                autoClose={false}
                style={{ border: '0',background:"transparent" }}
            >
                <button className="btn-main ml-3">Contacter ce notaire<ArrowRightIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" /></button>
            </PopupButton>


        </CardS>
    );
}

export default UseClotereCard;