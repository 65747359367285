import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';
import { Navbar } from '../Navbar.js';
import { ArrowDownCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import LivreBlanc from '../img/blog/checklist-etapes-vente-seul-clotere.png';




function ArticleRoleNotaire(args) {
    const [messageDownload, setMessageDownload] = useState(false);

    const handleDownload = (event) => {
        event.preventDefault();
        const email = event.target.email.value;


        if (email) {
            setMessageDownload(true);
            const sendToZapier = async () => {
                const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2i0zwqc/"; // 👈 Specify your Zapier Webhook URL here
                try {
                    const response = await fetch(zapierURL, {
                        method: "POST",
                        mode: "cors",
                        body: JSON.stringify({ email })
                    });
                } catch (e) {
                    console.log(e);
                }
            };
            sendToZapier();

        }
    }

    return (
        <>
            <Helmet>
                <title>Checklist des étapes de vente indispensables de son bien immobilier</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Vendre un bien immobilier par soi-même peut être complexe. Entre démarches, obligations légales et préparation, chaque étape est cruciale pour une vente réussie. Pour vous guider, découvrez notre check-list complète des étapes essentielles.' />
                <meta property="og:url" content="https://clotere.fr/ressources/vendre-seul-son-bien-checklist-etapes-de-vente" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Navbar user={args.user} />

            <div>
                <>

                    <section className="content min-h-screen">
                        <div className="relative isolate overflow-hidden bg-whitelg:overflow-visible lg:px-0">
                            <div className="absolute inset-0 -z-10 overflow-hidden">
                                <svg
                                    aria-hidden="true"
                                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                                >
                                    <defs>
                                        <pattern
                                            x="50%"
                                            y={-1}
                                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                                            width={200}
                                            height={200}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                                        </pattern>
                                    </defs>
                                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                                        <path
                                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                                            strokeWidth={0}
                                        />
                                    </svg>
                                    <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
                                </svg>
                            </div>
                            <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-3 gap-y-3 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                                    <div className="lg:pr-4">
                                        <div className="lg:max-w-lg">
                                            <p className="text-base/7 font-semibold text-indigo-600">Checklist des étapes de ventes</p>
                                            <h1 className="mt-2 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
                                                Vendez votre bien immobilier seul et n'oubliez rien !
                                            </h1>
                                            <p className="mt-6 text-lg text-gray-700">
                                                Vendre un bien immobilier par soi-même peut être complexe. Entre démarches, obligations légales et préparation, chaque étape est cruciale pour une vente réussie. Pour vous guider, découvrez notre check-list complète des étapes essentielles.
                                            </p>
                                            <p>Cette check-list détaillée est un guide précieux qui vous permettra :</p>
                                            <ul role="list" className="mt-2 space-y-3 text-gray-600">
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                                    <span>
                                                        <strong className="font-semibold text-gray-900">De ne rien oublier à chaque étape du processus de vente.</strong>
                                                    </span>
                                                </li>
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                                    <span>
                                                        <strong className="font-semibold text-gray-900">De préparer tous les documents nécessaires pour éviter les imprévus.</strong>
                                                    </span>
                                                </li>
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
                                                    <span>
                                                        <strong className="font-semibold text-gray-900">D’organiser efficacement vos démarches, de la mise en vente à la signature finale chez le notaire.</strong>
                                                    </span>
                                                </li>
                                            </ul>

                                            <p className="mt-8 text-lg">
                                                Que vous soyez un vendeur expérimenté ou que ce soit votre première vente immobilière, cette liste est conçue pour simplifier vos démarches et optimiser vos chances de réussite. Découvrez ci-dessous l’ensemble des étapes essentielles à suivre pour vendre votre bien rapidement et dans les meilleures conditions.
                                            </p>

                                        </div>

                                    </div>
                                </div>

                                <div className="p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                                    <div className="bg-white py-4 border-2 rounded-lg">

                                        <img
                                            alt=""
                                            src={LivreBlanc}
                                            className="mx-auto mt-3 mb-3 w-2/5 max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 rotate-2"
                                        />
                                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                            <h2 className="max-w-2xl text-balance text-xl font-semibold tracking-tight text-gray-900">
                                                Téléchargez gratuitement votre checklist
                                            </h2>
                                            <form className="mt-2 max-w-md" onSubmit={handleDownload}>
                                                {messageDownload === false && <>
                                                    <div className="flex gap-x-4">
                                                        <label htmlFor="email-address" className="sr-only">
                                                            Email
                                                        </label>
                                                        <input
                                                            id="email-address"
                                                            name="email"
                                                            type="email"
                                                            required
                                                            placeholder="Votre email"
                                                            autoComplete="email"
                                                            maxlength="40"
                                                            className="min-w-0 flex-auto rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            Téléchargez
                                                        </button>
                                                    </div>
                                                </>}
                                                {messageDownload === true && <>
                                                    <div className="flex gap-x-4">
                                                        <Link to="/file/checklist_etapes_ventes_clotere.pdf" target="_blank" download
                                                            className="inline-flex items-center flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >Téléchargez la checklist <ArrowDownCircleIcon className="size-8" /></Link>
                                                    </div>
                                                </>}

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </>
                <Footer />
            </div >
        </>
    );
}

export default ArticleRoleNotaire;