import React from "react";
import {  DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { DocumentIcon } from "@heroicons/react/24/solid";




function ModalDiagCarrez() {

    return (
        <>
            <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                <div className="fixed inset-0 z-10  overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                    <DocumentIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-left sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                    Le diagnostic loi Carrez 
                                    </DialogTitle>
                                    <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                    Le diagnostic loi Carrez est un document obligatoire lors de la vente d’un bien immobilier en copropriété (appartement ou maison divisée). 
                                    <br/>Il vise à garantir la transparence sur la surface privative du bien vendu. 
                                    <br/><br/>Ce mesurage précise <b>la superficie exacte des parties privatives, exprimée en mètres carrés, en excluant certaines zones comme les murs, cloisons, escaliers, caves, garages, et les espaces d’une hauteur inférieure à 1,80 m</b>.
                                    <br/>Réalisé par un professionnel, ce diagnostic permet d’éviter les litiges entre vendeur et acheteur. 
                                    <br/><br/>Si une erreur de plus de 5 % est constatée après la vente, l’acquéreur peut demander une réduction du prix. Valide indéfiniment tant que des modifications n’affectent pas la surface, il est essentiel pour sécuriser la transaction.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </DialogPanel>
                    </div>
                </div>
        </>
    );
}

export default ModalDiagCarrez;