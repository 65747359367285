import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SearchBar } from "./SearchBar";
/** style */
import { SearchForm } from '../style/Annuaire';
/** images */
import Background from "../img/back-clotere.png"
import { Gradient } from "./Gradient";
import { Container } from "./Container";
import { Navbar } from '../Navbar';
import { ChevronRightIcon } from '@heroicons/react/16/solid';


export const HeaderBannerThree = styled.div`
background-image: url(${Background});
background-position:top center;
background-size:cover;
  padding: 60px 0 0;
  position: relative;


h1 {
  font-size: 85px;
  line-height: 1.05em;
  text-align: center;
}
.sub-text {
  font-size: 28px;
  line-height: 1.39em;
  font-weight: 300;
  color: #2a2a2a;
  text-align: center;
  padding: 25px 0 45px;
}
.sing-in-call {
  font-size: 16px;
  padding-top: 25px;
  color: #2c2c2c;
  text-align: center;
}
.sing-in-call a {
  color: var(--blue-light);
  transition: all 0.25s ease-in-out;
}
.sing-in-call a:hover {
  text-decoration: underline;
}
.illustration {
  margin: 95px auto 0;
}


`;



const HeaderAnnuaire2 = (args) => {
  const [results, setResults] = useState([]);
  return (
    <>
      <div className="relative rounded-4xl ">
        <Gradient className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5" />
        <Container className="relative">
          <Navbar
            user={args.user}
            banner={
              <a
                href="https://clotere.com?utm_source=www"
                target="blank"
                className="no-underline flex items-center gap-1 rounded-full bg-fuchsia-950/35 px-3 py-0.5 text-sm/6 font-medium text-white data-[hover]:bg-fuchsia-950/30"
              >
                👋 Votre notaire en clair !
                <ChevronRightIcon className="size-4" />
              </a>
            }
          />

          <div className="pb-0 pt-14 sm:pb-22 sm:pt-14 md:pb-38 md:pt-22 text-center">
            <h1 className="text-[40px] sm:text-[80px] md:text-[80px] font-display  font-medium tracking-tight text-gray-950">
              Trouvez votre notaire
            </h1>
            <p className="text-[20px] sm:text-[23px]  mt-8 font-medium text-gray-950/75 ">
              Partout en France et disponible
            </p>
            <div className="mt-0 mb-5 flex flex-col gap-x-6 gap-y-4 sm:flex-row text-center justify-center" >
              <SearchForm>
                <SearchBar />
              </SearchForm>
            </div>
          </div>

            {/** section liens rapides */}
          <div className="pb-14 pt-0 sm:pb-22 sm:pt-14 md:pb-38 md:pt-22 text-center">
            
            <p className="text-[20px] sm:text-[23px]  mt-8 font-medium text-gray-950/75 ">
            Trouvez votre notaire dans les grandes villes de France
            </p>
            <div class="flex flex-row gap-4 flex-wrap justify-center">
            <Link className="tag-recherche-notaire" to="/fr/notaires/ville/paris">Notaires à Paris</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/lyon">Notaires à Lyon</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/lille">Notaires à Lille</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/bordeaux">Notaires à Bordeaux</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/rennes">Notaires à Rennes</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/montpellier">Notaires à Montpellier</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/toulouse">Notaires à Toulouse</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/marseille">Notaires à Marseille</Link>
                  <Link className="tag-recherche-notaire"  to="/fr/notaires/ville/strasbourg">Notaires à Strasbourg</Link>

            </div>
          </div>
        </Container>
      </div>
    </>
    // End hero-banner-three
  );
};

export default HeaderAnnuaire2;
