import React from "react";
import {  DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { DocumentIcon } from "@heroicons/react/24/solid";
import gesNiveau from '../../img/annonce/ges-niveau.webp';




function ModalDiagGes() {

    return (
        <>
            <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10  overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                    <DocumentIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-left sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                    Emissions de Gaz à Effet de Serre (GES)
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Le Diagnostic de Performance Energétique (DPE) doit être réalisé <b>lors de la mise en vente ou de la location d’un bien</b>, il permet de réaliser un bilan complet sur la consommation énergétique de ce bien et d’en informer les futurs acquéreurs ou locataires.
                                            <br/><br/>
                                            Dans le cadre de ce DPE, un technicien évalue notamment :
                                            <ul className="p-0">
                                            <li>- Le bon état des systèmes de chauffage fixes et de climatisation du bien</li>
                                            <li>- L’isolation du bien</li>
                                            <li>- La consommation d’énergie du bien (en kWh/m²/an), grâce au rapport de la quantité d’énergie primaire du bien sur la surface habitable du lot</li>
                                            <li>- L’émission de gaz à effet de serre</li>
                                            </ul>
                                             <img src={gesNiveau} alt="dpe"/>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </DialogPanel>
                    </div>
                </div>
        </>
    );
}

export default ModalDiagGes;