import React, { useState } from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";
import {
  TitlePage,
  TitlePageApp,
} from "../style/Layout";
import { DocumentCheckIcon, FaceSmileIcon, LifebuoyIcon } from "@heroicons/react/24/solid";

const items = [
  {
    name: 'Gagnez du temps, signez plus vite !',
    description: 'Constituez votre dossier de vente avant de recevoir une offre d\'achat pour obtenir votre compromis de vente plus vite !',
    iconColor: 'bg-pink-500',
    icon: DocumentCheckIcon,
  },
  {
    name: 'Bénéficiez d\'un suivi premium',
    description: 'Votre conseiller Clotere réponds à toutes vos questions juridiques tout au long de votre vente.',
    iconColor: 'bg-purple-500',
    icon: LifebuoyIcon,
  },
  {
    name: 'Suivez simplement l\'avancement de votre dossier',
    description: 'Un tableau de bord unique pour tous les intervenants de votre vente. ',
    iconColor: 'bg-yellow-500',
    icon: FaceSmileIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function NotaireAnnonce(args) {
  return (
    <>
      <Container>
        <Row className="d-flex align-self-start">
          <TitlePageApp>
            <Col md="7">
              <TitlePage>Notaire</TitlePage>
              <p>
                Retrouvez ici toutes les informations concernant votre notaire
              </p>
            </Col>
          </TitlePageApp>

          <Col md="12" className="mt-3">
            <div className="mx-auto max-w-lg">
              <div>
                <div className="text-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                    className="mx-auto size-12 text-gray-400"
                  >
                    <path
                      d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h2 className="mt-2 text-lg font-bold text-gray-900">Confiez gratuitement votre affaire à un notaire Clotere</h2>
                </div>
                <ul role="list" className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
                  {items.map((item, itemIdx) => (
                    <li>
                      <div className="group relative flex items-start space-x-3 py-4">
                        <div className="shrink-0">
                          <span
                            className={classNames(item.iconColor, 'inline-flex size-10 items-center justify-center rounded-lg')}
                          >
                            <item.icon aria-hidden="true" className="size-6 text-white" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-lg font-bold text-gray-900">
                            <a href={item.href}>
                              <span aria-hidden="true" className="absolute inset-0" />
                              {item.name}
                            </a>
                          </div>
                          <p className="text-sm text-gray-500">{item.description}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                
                <a className="btn-main no-underline mx-auto" href="#">Choisir mon notaire</a>
                
              </div>
            </div>
          </Col>

        </Row>
      </Container>
    </>
  );

}
export default NotaireAnnonce;
