import React, { useState } from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";
import {
  TitlePage,
  TitlePageApp,
} from "../style/Layout";
import { DocumentCheckIcon, DocumentIcon, FaceSmileIcon, LifebuoyIcon, TagIcon } from "@heroicons/react/24/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

const items = [
  {
    name: 'Gagnez du temps, signez plus vite !',
    description: 'Constituez votre dossier de vente avant de recevoir une offre d\'achat pour obtenir votre compromis de vente plus vite !',
    iconColor: 'bg-pink-500',
    icon: DocumentCheckIcon,
  },
  {
    name: 'Bénéficiez d\'un suivi premium',
    description: 'Votre conseiller Clotere réponds à toutes vos questions juridiques tout au long de votre vente.',
    iconColor: 'bg-purple-500',
    icon: LifebuoyIcon,
  },
  {
    name: 'Suivez simplement l\'avancement de votre dossier',
    description: 'Un tableau de bord unique pour tous les intervenants de votre vente. ',
    iconColor: 'bg-yellow-500',
    icon: FaceSmileIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function DocumentAnnonce(args) {
  return (
    <>
      <Container>
        <Row className="d-flex align-self-start">
          <TitlePageApp>
            <Col md="7">
              <TitlePage>Documents</TitlePage>
            </Col>
          </TitlePageApp>

          <Col md="12" className="mt-3">
            <span className="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-base font-bold text-purple-700 mb-2">
            Diagnostics techniques
            </span>
            <div className="overflow-hidden rounded-lg bg-white shadow p-3">
              <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                <li className="flex items-center justify-between gap-x-6 py-3">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex"/>
                      <p className="text-sm/6 font-semibold text-gray-900 m-0">Diagnostics de performance (DPE)</p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    {args.annonce.dpe &&
                    <>
                      <button
                      type="button"
                      className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                      Voir
                    </button>
                    <button
                    type="button"
                    className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                  >
                    Supprimer
                  </button>
                  </>
                  }
                  {!args.annonce.dpe &&
                    <>
                      <button
                      type="button"
                      className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                      + Ajouter
                    </button>
                    </>
                  }
                  
                  </div>
                </li>
                <li className="flex items-center justify-between gap-x-6 py-3">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex"/>
                      <p className="text-sm/6 font-semibold text-gray-900 m-0">Etat des risques et pollutions (ERP)</p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    {args.annonce.erp &&
                    <>
                      <button
                      type="button"
                      className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                      Voir
                    </button>
                    <button
                    type="button"
                    className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                  >
                    Supprimer
                  </button>
                  </>
                  }
                  {!args.annonce.erp &&
                    <>
                      <button
                      type="button"
                      className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                      + Ajouter
                    </button>
                    </>
                  }
                  
                  </div>
                </li>
                <li className="flex items-center justify-between gap-x-6 py-3">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex"/>
                      <p className="text-sm/6 font-semibold text-gray-900 m-0">Certification Loi Carrez</p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    {args.annonce.carrez &&
                    <>
                      <button
                      type="button"
                      className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                      Voir
                    </button>
                    <button
                    type="button"
                    className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                  >
                    Supprimer
                  </button>
                  </>
                  }
                  {!args.annonce.carrez &&
                    <>
                      <button
                      type="button"
                      className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                      + Ajouter
                    </button>
                    </>
                  }
                  
                  </div>
                </li>
              </ul>
            </div>
          </Col>

          <Col md="12" className="mt-5">
          
            <span  className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-base font-bold text-yellow-700 mb-2">
            Identité
            </span>
            <div className="overflow-hidden rounded-lg bg-white shadow p-3">
              <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                <li className="flex items-center justify-between gap-x-6 py-3">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex"/>
                      <div className="flex flex-column">
                      <p className="text-sm/6 font-semibold text-gray-900 m-0">Pièce(s) identité</p>
                      <p className="text-sm font-normal text-gray-600 m-0">Carte d'identité (recto/verso) ou passeport ou titre de séjour</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex items-center justify-between gap-x-6 py-3">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex"/>
                      <div className="flex flex-column">
                      <p className="text-sm/6 font-semibold text-gray-900 m-0">Acte de naissance</p>
                      <p className="text-sm font-normal text-gray-600 m-0">Document complet</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex items-center justify-between gap-x-6 py-3">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex"/>
                      <div className="flex flex-column">
                      <p className="text-sm/6 font-semibold text-gray-900 m-0">Livret de famille</p>
                      <p className="text-sm font-normal text-gray-600 m-0">Copie intégrale</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Col>

        </Row>
      </Container>
    </>
  );

}
export default DocumentAnnonce;
