import React from "react";
import logoLeboncoin from '../img/annonce/leboncoin.svg';
import logoSeloger from '../img/annonce/logo-seloger.svg';
import logoPap from '../img/annonce/logo-pap.svg';

function BlocAnnonce(args) {
    return (
        <>
        <p className="text-sm text-slate-500 mb-2">Annonce</p>
            <div className="overflow-hidden rounded-lg bg-white">

                <div className="bg-white p-6">
                    <div className="flex items-center justify-between">
                        <div className="flex space-x-5">
                           
                            <div className=" sm:mt-0 sm:pt-1 sm:text-left">
                                <p className="text-xl font-bold text-gray-900 m-0">Appartement T3 proche mairie saint-ouen</p>
                                <p className="text-xl font-medium text-gray-600 m-0">{args.prix}€</p>
                                {args.plateforme === "leboncoin" && (<img src={logoLeboncoin} alt="leboncoin" className="h-6" />)}
                                {args.plateforme === "seloger" && (<img src={logoSeloger} alt="seloger" className="h-6" />)}
                                {args.plateforme === "pap" && (<img src={logoPap} alt="pap" className="h-6" />)}
                            </div>

                        </div>
                        <div className="mt-5  justify-center sm:mt-0 hidden sm:flex">
                            <a
                                href={args.url}
                                target="_blank"
                                className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Voir l'annonce
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlocAnnonce;