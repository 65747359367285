import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { ButtonPrimary, LinkS } from "../style/Button";
import { CheckIcon, QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { DocumentIcon } from "@heroicons/react/24/solid";




function Test() {
    const [openDpe, setOpenDpe] = useState(false);
    const handleContact = (event) => {
        event.preventDefault();
        console.log("envoyer");
    }

    return (
        <div>
            <div class="group relative m-12 flex justify-center">
                <label className="text-sm/6 font-medium text-gray-900 flex flex-row gap-2 group">Emissions de Gaz à Effet de Serre (GES) <QuestionMarkCircleIcon className="flex w-6 " /></label>
            </div>

            <label onClick={() => setOpenDpe(true)} className="text-sm/6 font-medium text-gray-900 flex flex-row gap-2 group">Diagnostics de performance (DPE) <QuestionMarkCircleIcon className="flex w-6 " /></label>



            <Dialog open={openDpe} onClose={setOpenDpe} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10  overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                    <DocumentIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-left sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                    Emissions de Gaz à Effet de Serre (GES)
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                        L'acronyme GES signifie Gaz à Effet de Serre. Le classement de ces GES est affichée sur une partie du DPE.
                                        <br/>
                                        Ce classement de GES indique la quantité de gaz à effet de serre qu'un logement émet par an. Il permet donc d'évaluer son impact environnemental ainsi que ses performances énergétiques.
                                        <img src="https://www.leboncoin.fr/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fenergy_schemas.7326f1dc.png&w=1920&q=75" alt="dpe"/>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default Test;