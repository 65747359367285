import React from "react";
import { Card } from "reactstrap";
import { Link } from 'react-router-dom';
import styled from "styled-components";


export const CardS = styled(Card)`
background: rgb(234 244 243) !important;
border-radius: 20px;
border: 0;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:row!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:24px;
    color:black;
 }
 @media all and (max-width: 768px) {
    flex-direction:column!important;
    .card-title{
        font-size:18px;
     }
  }
`;


function CardClotere1() {
    return (
        <div className="text-center border-4 p-4 rounded-lg border-emerald-100">
        <h5 className="font-bold text-lg"><b> Clotere vous accompagne dans vos démarches chez le notaire : faire une <span className="font-bold text-[#1DF36C]">donation</span>, trouver un <span className="font-bold text-[#1DF36C]">notaire acheteur</span>, confier <span className="font-bold text-[#1DF36C]">la vente de son bien immobilier à un notaire</span>, etc </b></h5>
        <p>Constituez et suivez simplement votre dossier notaire avec Clotere.</p>
        <div className="flex flex-row justify-center gap-4 flex-wrap">
        <Link to="/faire-une-donation" className="btn-main no-underline text-white mb-2">
                Faire une donation
            </Link>
            <Link to="/inscription/achat" className="btn-main no-underline text-white  mb-2">
                Acheter son logement
            </Link>
            <Link to="/inscription/vente" className="btn-main no-underline text-white mb-2">
                Vendre son bien
            </Link>
            </div>
        </div>
    );
}

export default CardClotere1;