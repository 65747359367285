import React, { useState, useEffect } from "react";
import { useMemberstack } from "@memberstack/react";
import { Container, Row, Col, ListGroupItem, ListGroup, Badge} from "reactstrap";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { AlertNotif } from "../style/Layout";
import icnMagic from '../img/icn-magic.svg';
import { faLink, faLocationDot, faPhone, faArrowLeftLong, faBoltLightning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from "../img/back-clotere.png";
import axios from "axios";
import icnNotaire from '../img/icn-notaire.svg';
import { CardNotaires, BadgeUse, UseComponentCard } from "../style/Annuaire";
import { ButtonPrimarySmall } from "../style/Button";
import CardNotaire from "../annuaire/CardNotaire";


import Loading from '../components/Loading';


const NotairesS = styled.div`
 background-image: url(${Background});
background-position:top center;
background-size:cover;
padding:3rem 2rem;
.row-notaire-reco{
margin-top:2rem;
margin-bottom:5rem;
}
`;


const ColNotaire = styled(Col)`
display:flex;
flex-direction:column;
gap:1.5rem;
flex-wrap:wrap;
flex-direction:row;
justify-content:center;
@media all and (max-width: 768px) {
  padding-right:.5rem;
  padding-left:.5rem;
  .card{
  width:100%;
  img{width:75px;}
  }
  }
`;


function Notaires(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [user, setUser] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [recommandations, setRecommandations] = useState();
    const [isLoading, setLoading] = useState(true);
    const [notairesVille, setNotairesVille] = useState(null);
    const [data, setData] = useState([]);
    


    //get info memberstack
    useEffect(() => {
        const fetchDataMemberstack = () => {
            setLoading(true);
            memberstack.getCurrentMember()
                .then(({ data: member }) => setMember(member))
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        };

        fetchDataMemberstack();
    }, []);

    //get info user airtable
    useEffect(() => {
        //avec les info memberstack on recupere les info user airtable
        //console.log("seulement si member est update");
        if (member !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/user/${member.metaData.airtable_id}`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("this user info", res);
                    setUser(res.fields);
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [member]);

    //get info transaction
    useEffect(() => {
        const fetchDataTransaction = async () => {
            //La transaction
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/transaction/${user.transaction_id}`;
            await fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    setTransaction(res.fields);
                    console.log("transac", res.fields)
                })
                .catch((error) => console.log("transaction info error", error),);

        };

        if (user !== null && member.metaData.airtable_id !== undefined) {
            fetchDataTransaction();
        }
        // eslint-disable-next-line
    }, [user]);

    //on recupère les notaires depuis le CSV et les reco notaire depuis airtable
    useEffect(() => {
        const SPREADSHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID;
        const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
        const RANGE = "France";
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
                );
                const rows = response.data.values;
                //console.log("rows",rows);
                setData(rows);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        const fetchRecoNotaire = async () => {
            //on recupere les notaires recommander si il y en a
            //on check si 1 ou plusieurs reco.
            if (transaction.recommandations_notaire.length === 1) {
                //console.log("pas table");
                const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/recommandations_notaire/${transaction.recommandations_notaire}`;
                await fetch(
                    URL, {method: "GET",headers: {Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",'content-type': 'application/x-www-form-urlencoded',"Accept": "application/json, text/plain, /"},})
                    .then((res) => res.json())
                    .then((res) => {setRecommandations(res.fields);})
                    .catch((error) => console.log("transaction info error", error),);
            } else {
                //console.log("table");

                    const url = `https://api.airtable.com/v0/appD48APNaGA4GN0B/recommandations_notaire?filterByFormula=SEARCH("${transaction.id}",{transaction})`;
            
                    await fetch(
                        url, {method: "GET",headers: {Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",'content-type': 'application/x-www-form-urlencoded',"Accept": "application/json, text/plain, /"},})
                        .then((res) => res.json())
                        .then((res) => {setRecommandations(res.records);})
                        .catch((error) => console.log("transaction info error", error),);
            }
        };

        fetchData();
        if (transaction !== null) {
            fetchRecoNotaire();
        }

    }, [transaction]);

    //on filtre les notaires de la ville et du département de la transaction
    useEffect(() => {
        if (transaction !== null & recommandations!== null) {
            const ville = transaction.code_postal_from_bien[0];
            //console.log("ici", dep)

            if (data.length > 1) {
                const headers = data[0];
                const rows = data.slice(1);
                const formatted = rows.map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        obj[header] = row[index] || "";
                    });
                    return obj;
                });

                if (formatted != null) {
                    

                    var filteredDataVille = formatted.filter(
                        (item) => item.cp === ville
                    );

                    filteredDataVille.sort((a, b) => {
                        if (a.utilise_clotere === "oui" && b.utilise_clotere === "non") {
                            return -1;
                        }
                        if (a.utilise_clotere === "non" && b.utilise_clotere === "oui") {
                            return 1;
                        }
                        return 0;
                    });
                    //console.log("filtered",filteredData)
                    setNotairesVille(filteredDataVille);
                    setLoading(false);
                }
            }
        }
    }, [data]);


    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
        {recommandations &&
            <NotairesS>
                <Container>
                    <Row>
                        <Col md='12'>
                            <Link to="/app/dashboard" className="back d-flex flex-row">
                                <p className="m-0"><FontAwesomeIcon icon={faArrowLeftLong} className='mr-3' /> {"        "} Retour</p>
                            </Link>
                        </Col>
                    </Row>
                    <Row className="row-notaire-reco">
                        <Col md="12" align="center">
                            <AlertNotif color="primary" className="justify-content-center">
                                <span role="img">👋</span>
                                <img src={icnMagic} alt='ia' />
                                <div>
                                    <h6>Voici le(s) notaire(s) que nous vous recommandons </h6>
                                </div>
                            </AlertNotif>
                        </Col>
                        <Col md="12" className="mb-3" align="center">
                        <p>Confiez votre dossier à un notaire Clotere vous permet des échanges fluides sur une palteforme sécurisée.</p>
                        </Col>
                        <Col md="12">
                            <ColNotaire>
                                {transaction.recommandations_notaire.length > 1 ?
                                    <>
                                    {recommandations.map((col, i) => (
                                            <>
                                    <CardNotaire className="reco"
                                    nom={recommandations[i].fields.prenom + " "+ recommandations[i].fields.nom}
                                    adresse={recommandations[i].fields.adresse}
                                    cp={recommandations[i].fields.cp}
                                    ville={recommandations[i].fields.ville}
                                    site={recommandations[i].fields.site}
                                    telephone={recommandations[i].fields.telephone}
                                    type_reco={recommandations[i].fields.type_reco}
                                    reco="oui"
                                    use="oui"
                                    photo={recommandations[i].fields.photo_profil ? recommandations[i].fields.photo_profil[0].url : 'https://firebasestorage.googleapis.com/v0/b/clotere-33ee1.appspot.com/o/icn-notaire.svg?alt=media&token=598450ff-1cca-4afe-b05d-c8d7ecc8f1cd'}
                                    lien_fiche={recommandations[i].fields.lien_fiche ? recommandations[i].fields.lien_fiche[0] : 'non'}
                                    idTransaction={transaction.id}
                                    idReco={recommandations[i].id}
                                    idNotaire={recommandations[i].fields.notaire}
                                    />
                                            </>
                                    ))}
                                    </> :
                                    <>
                                    <CardNotaire className="reco"
                                    nom={recommandations.prenom + " "+ recommandations.nom}
                                    adresse={recommandations.adresse}
                                    cp={recommandations.cp}
                                    ville={recommandations.ville}
                                    site={recommandations.site}
                                    telephone={recommandations.telephone}
                                    reco="oui"
                                    use="oui"
                                    photo={recommandations.photo_profil ? recommandations.photo_profil[0].url : 'https://firebasestorage.googleapis.com/v0/b/clotere-33ee1.appspot.com/o/icn-notaire.svg?alt=media&token=598450ff-1cca-4afe-b05d-c8d7ecc8f1cd'}
                                    type_reco={recommandations.type_reco}
                                    lien_fiche={recommandations.lien_fiche ? recommandations.lien_fiche[0] : 'non'}
                                    idTransaction={transaction.id}
                                    idReco={recommandations.id}
                                    idNotaire={recommandations.notaire}
                                    />
                                    </>
                                }
                            </ColNotaire>
                        </Col>
                        
                    </Row>


                    {/** liste des autres notaires dans la ville et dans la région */}
                    <Row className="mt-5">
                    <Col md='8' align="left"><h6>Voici la liste des {notairesVille.length} notaires à {transaction.ville_from_bien} <span role="img">👇</span></h6></Col>
                    <Col md='4' align="right"><ButtonPrimarySmall color="primary" target="blank" href="https://clotere.fr/fr/notaires">Trouvez d'autres notaires</ButtonPrimarySmall></Col>
                            
                            <Col md='12' className="mt-3">
                            <ColNotaire>
                                        {notairesVille.map((col, i) => (
                                            <>
                                                <CardNotaires key={i}>
                                                    <img src={icnNotaire} alt="notaire" />
                                                    <div>
                                                        <div className="head">
                                                            <Link to={`https://clotere.fr/notaire/${notairesVille[i].lien_fiche}`} target="blank">
                                                                <h4>{notairesVille[i].nom}</h4>
                                                            </Link>
                                                        </div>
                                                        {notairesVille[i].utilise_clotere === "oui" ? <BadgeUse className="use">Notaire</BadgeUse> : <Badge>Notaire</Badge>}

                                                        {notairesVille[i].utilise_clotere === "oui" &&
                                                            <>
                                                                <UseComponentCard>
                                                                    <FontAwesomeIcon icon={faBoltLightning} className='mr-3 rounded use' />
                                                                    <p>Dossier en ligne</p>
                                                                </UseComponentCard>
                                                            </>
                                                        }
                                                        <ListGroup horizontal>

                                                            <ListGroupItem className='p-0'>
                                                                <FontAwesomeIcon icon={faLocationDot} className='mr-3 rounded' />
                                                                <p>{notairesVille[i].adresse} {" "} <br /><b>{notairesVille[i].cp}{" "} {notairesVille[i].ville}</b></p>
                                                            </ListGroupItem>
                                                            {notairesVille[i].telephone &&
                                                                <>
                                                                    <ListGroupItem className='p-0'>
                                                                        <FontAwesomeIcon icon={faPhone} className='mr-3 rounded' />
                                                                        <p className="text-muted">non renseigné</p>
                                                                    </ListGroupItem>
                                                                </>
                                                            }
                                                            {notairesVille[i].site &&
                                                                <>
                                                                    <ListGroupItem className='p-0'>
                                                                        <FontAwesomeIcon icon={faLink} className='mr-3 rounded' />
                                                                        <a href={`${notairesVille[i].site}?utm_source=clotere`} target="blank">Consulter le site internet</a>
                                                                    </ListGroupItem>
                                                                </>
                                                            }
                                                            {notairesVille[i].utilise_clotere === "oui" &&
                                                                <>
                                                                    <>
                                                                        <ListGroupItem className='p-0'>
                                                                            <Link to={`/notaire/${notairesVille[i].fiche}`}>
                                                                                <ButtonPrimarySmall color="primary">Contactez ce notaire</ButtonPrimarySmall>
                                                                            </Link>
                                                                        </ListGroupItem>
                                                                    </>
                                                                </>
                                                            }

                                                        </ListGroup>
                                                    </div>
                                                </CardNotaires>
                                            </>
                                        ))}
                                    </ColNotaire>


                        </Col>
                    </Row>
                </Container>
            </NotairesS>
        }
        </>
        
    );

}

export default Notaires;