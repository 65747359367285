import React from "react";

const timeline = [
    {
      name: 'Offre d\'achat',
      date: 'J-0'
    },
    {
      name: 'Compromis de vente',
      date: 'J+15',
    },
    {
      name: 'Acte authentique de vente',
      date: 'J+90',
    },
  ]

function TimelineChronologie() {
    return (
        
        <div className="py-4 sm:py-4">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                        {timeline.map((item) => (
                            <div key={item.name}>
                            <time
                                dateTime={item.dateTime}
                                className="flex items-center text-sm font-semibold leading-6 text-indigo-600"
                            >
                                <svg viewBox="0 0 4 4" aria-hidden="true" className="mr-4 h-1 w-1 flex-none">
                                <circle r={2} cx={2} cy={2} fill="currentColor" />
                                </svg>
                                {item.date}
                                <div
                                aria-hidden="true"
                                className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                                />
                            </time>
                            <p className="mt-3 text-md font-semibold leading-8 tracking-tight text-gray-900">{item.name}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
    );
}

export default TimelineChronologie;