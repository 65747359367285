import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { Container, ListGroup} from "reactstrap";
import styled from "styled-components";
import { Widget } from '@typeform/embed-react';
import { useParams } from "react-router";
import { NavbarOnboard } from '../NavbarOnboard';


const ListGroupAvantages = styled(ListGroup)`
border:0;
li.list-group-item{
    border:0;
    margin:.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-weight:bold;
    span{
        margin-right:10px;
        background-color:#FFF;
        padding:1rem;
        border-radius:10px;
        margin-right:15px;
    }
}
`;
const WidgetTypeform = styled(Widget)`
width:100%;
height:80%;
`;

function Onboard(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const navigate = useNavigate();
    const { type } = useParams();
    console.log("type",type);

    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    //console.log("member",member)

    return (
        <>
            {member && (
                <div className="bg-gradient-to-t from-teal-100 to-teal-50 min-h-px h-screen ">
                     <NavbarOnboard user={args.user} />
                     <Container  className="h-full">
                        {type === "donation" && (
                            <WidgetTypeform id="Vtk0ABOY" hidden={{ email: member.auth.email,airtable_id: member.metaData.airtable_id }}  />
                        )}
                        {type === "achat" && (
                            <WidgetTypeform id="JM2MTsDD" hidden={{ email: member.auth.email,airtable_id: member.metaData.airtable_id }}  />
                        )}
                     
                     </Container>
                </div>
                
            )}
            {!member && (
                <p className="text-sm text-center">Vous devez être connecté pour accéder à cette page</p>
            )}
        </>
    );
}

export default Onboard;