import React from "react";
import { Card } from "reactstrap";
import styled from "styled-components";
import { Link } from 'react-router-dom';


export const CardS = styled(Card)`
&.card{
border-radius: 20px;
border: 0;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:column!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:18px;
    text-align:left;
 }
 @media all and (max-width: 768px) {
    max-width:90%;
  }
}
`;


function WantUseClotereCard(args) {
    return (
        <CardS className="bg-gradient-to-r from-teal-200 to-teal-500">
            <h3 className="text-2xl font-medium tracking-tight text-white sm:text-4xl text-center">
                Vous êtes <br/>Maître {args.prenom} {" "} {args.nom} ?
            </h3>
            <Link to="https://notaire.clotere.fr?utm_source=annuaire" className="btn-main mt-3 mb-3 no-underline"  target="blank" color="primary">Découvrez Clotere Notaire</Link>
            <p><small>Si vous souhaitez supprimer votre fiche notaire, écrivez nous à <a href="mailto:cecile@clotere.fr">contact@clotere.fr</a></small></p>


        </CardS>
    );
}

export default WantUseClotereCard;