import React from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
const faqs = [
  {
    question: "Quelles sont les différents type de donation ?",
    answer:
      "Il existe 4 types de donations en France. La donation en pleine propriété ; La donation en nue-propriété avec réserve d’usufruit. La Donation-partage. La donation entre époux ou donation au dernier vivant. Chaque type de donation a des implications fiscales et patrimoniales spécifiques, adaptées aux différents objectifs de transmission.",
    link: "https://clotere.com/quelles-sont-les-differentes-formes-de-donation/"
  },

  {
    question: "Quelle est la différence entre une donation et une succession ?",
    answer:
      "La succession et la donation sont deux façons de transmettre un patrimoine, mais elles diffèrent principalement par leur moment et leur intention. La succession a lieu après le décès, et les biens sont partagés entre les héritiers selon la loi ou un testament. En revanche, la donation se fait de son vivant, permettant au donateur de choisir les bénéficiaires et de profiter d’avantages fiscaux. La donation permet aussi de transmettre progressivement le patrimoine et d’éviter certains conflits familiaux en planifiant à l’avance.",
    link: "https://clotere.com/donation-ou-succession-quel-est-le-meilleur-choix/"
  },
  {
    question: "Quels sont les avantages fiscaux d’une donation ?",
    answer:
      "La donation offre plusieurs avantages fiscaux, notamment des abattements spécifiques selon le lien de parenté (jusqu’à 100 000 € pour un parent à un enfant), renouvelables tous les 15 ans. En donnant de son vivant, on peut ainsi optimiser la transmission de patrimoine en profitant de ces abattements de façon périodique. La donation en nue-propriété permet aussi de réduire la base taxable, car seule la valeur de la nue-propriété est prise en compte. Enfin, les donations entre conjoints ou partenaires de PACS bénéficient également d’abattements avantageux.",
    link: "https://clotere.com/es-avantages-fiscaux-de-la-donation/"
  },
  {
    question: "Dois-je payer des impôts sur une donation ?",
    answer:
      "Oui, les donations peuvent être soumises à des droits de donation, mais il est possible de bénéficier d’abattements fiscaux selon le lien de parenté et le montant donné. Par exemple, un parent peut donner jusqu’à 100 000 € par enfant tous les 15 ans sans droits de donation. Au-delà de ces abattements, des taux progressifs s’appliquent, variant entre 5 % et 60 % selon le lien de parenté. Les donations entre conjoints ou partenaires de PACS bénéficient aussi d’abattements spécifiques, réduisant l’impôt à payer.",
  },
  // More questions...
]


function FaqDonateur(args) {

  return (
    <>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <div className="mx-auto max-w-7xl px-6 py-4">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <dl className="space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure key={faq.question} as="div" className="pt-4">
                  <dt>
                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                        <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                      </span>
                    </DisclosureButton>
                  </dt>
                  <DisclosurePanel as="dd" className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    {faq.link &&
                      <a className="btn-main no-underline" href={faq.link} target="blank">En savoir plus sur ce sujet</a>
                    }
                  </DisclosurePanel>
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <p className="mt-3">Trouvez vos réponses  dans la 👉  <a href="https://clotere.com/comprendre-le-notaire/" target="_blank">FAQ des notaires</a> par Clotere</p>
    </>
  );
}

export default FaqDonateur;