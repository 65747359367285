'use client'
import React, { useState, useEffect } from "react";
import { useMemberstack } from "@memberstack/react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { Bars2Icon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom';
import Logo from './img/icn-logo.svg'
import { PlusGrid, PlusGridItem, PlusGridRow } from './annuaire/Plus-grid'
import { Container } from "./annuaire/Container";


function DesktopNav() {
  const memberstack = useMemberstack();
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (member === null) {
      memberstack.getCurrentMember()
        .then(({ data: member }) => setMember(member))
    }
  }, []);

  return (
    <nav className="relative hidden lg:flex">
      <PlusGridItem className="relative flex">
        <p className="flex items-center px-4 py-3 m-0 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]">
      <span role="img">👋</span> Besoin d'aide ? 
      </p>
        <Link
          to="tel:0757831364"
          target="blank"
          className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
        >
           07 57 83 13 64 
        </Link>
      </PlusGridItem>
    </nav>
  )
}

function MobileNavButton() {
  return (
    <DisclosureButton
      className="flex size-12 items-center justify-center self-center rounded-lg data-[hover]:bg-black/5 lg:hidden"
      aria-label="Open main menu"
    >
      <Bars2Icon className="size-6" />
    </DisclosureButton>
  )
}

function MobileNav() {
  return (
    <DisclosurePanel className="lg:hidden">
      <div className="flex flex-col gap-6 py-4">
      <Link
              to="https://clotere.webflow.io"
              target="blank"
              className="text-base font-medium text-gray-950"
            >
              Votre notaire en clair 
            </Link>
            <PlusGridItem className="relative flex">
        <p className="flex items-center px-4 py-3 m-0 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]">
      <span role="img">👋</span> Besoin d'aide ? 
      </p>
        <Link
          to="tel:0757831364"
          target="blank"
          className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
        >
           07 57 83 13 64 
        </Link>
      </PlusGridItem>
      </div>
      <div className="absolute left-1/2 w-screen -translate-x-1/2">
        <div className="absolute inset-x-0 top-0 border-t border-black/5" />
        <div className="absolute inset-x-0 top-2 border-t border-black/5" />
      </div>
    </DisclosurePanel>
  )
}

export function NavbarOnboard({ banner }) {
  return (
    <Container>
    <Disclosure as="nav" className="pb-12">
      <PlusGrid>
        <PlusGridRow className="relative flex justify-between">
          <div className="relative flex gap-6">
            <PlusGridItem className="py-3">
              <Link to="/" title="Home">
                <img src={Logo} alt="clotere" />
              </Link>
            </PlusGridItem>
            
          </div>
          <DesktopNav />
          <MobileNavButton />
        </PlusGridRow>
      </PlusGrid>
      <MobileNav />
    </Disclosure>
    </Container>
  )
}
