import React from "react";
/** composants **/
import { Navbar } from './Navbar';
import Footer from './components/Footer';
import { Container } from "./annuaire/Container";
import Faq from './components/Faq';
import { CheckIcon } from "@heroicons/react/20/solid";
import { useNavigate } from 'react-router-dom';
import MainIllu from "./img/annonce/main-illu-2.png";
import Visite from "./img/annonce/visite.png";
import Centraliser from "./img/annonce/centrale.png";
import Offre from "./img/annonce/offre.png";
import Notaire from "./img/annonce/notaire.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { LockClosedIcon, CheckCircleIcon } from '@heroicons/react/20/solid'

const posts = [
    {
        id: 3,
        title: 'Quel est le rôle du notaire dans un achat immobilier ?',
        href: 'https://clotere.fr/fiches-pratiques-notaire/role-du-notaire-en-immobilier',
        description: "",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/clauses-suspensives.webp?resize=600%2C400&ssl=1',
        category: { title: 'Etapes achat immobilier', href: '#' },
    },
    {
        id: 2,
        title: 'Quels sont les documents nécessaires pour vendre un bien immobilier ?',
        href: 'https://clotere.fr/fiches-pratiques-notaire/document-de-vente-notaire',
        description: "",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2023/03/comment-choisir-son-notaire.webp?fit=751%2C500&ssl=1',
        category: { title: 'Documents de vente', href: '#' },
    },
    {
        id: 1,
        title: 'Quelles questions se posent les acheteurs ?',
        href: 'https://clotere.fr/fiches-pratiques-notaire/un-ou-deux-notaires-immobilier',
        description: "",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/Frame-54.png?w=775&ssl=1',
        category: { title: 'Notaire acheteur', href: '#' },
    },
    // More posts...
]


function Vendeur(args) {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Vendez seul votre bien immobilier, comme un pro</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Vendez votre bien immobilier comment un pro grâce à Clotere, la solution qui simplifie votre passage chez le notaire.' />
                <meta property="og:url" content="https://clotere.fr/vendeur" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Navbar user={args.user} />

            <Container>
                {/** header */}
                <section class="flex flex-row flex-wrap justify-center bg-gradient-to-r from-teal-200 to-teal-500 p-4 sm:p-10 rounded-lg items-center">
                    <div className="w-full sm:basis-1/2 p-2 sm:p-4">
                        <h1 className="text-[40px] sm:text-[60px]  font-display  font-medium tracking-tight text-gray-950 leading-[4rem] mb-4">
                            <span className="font-medium text-black">Le seul outils pour vendre votre bien <span className="font-bold ">seul</span><br /> et <span className="font-bold ">sans frais d'agence</span></span>
                        </h1>
                        <p className="text-[18px] mb-3">
                            Grâce à Clotere vous pouvez gérer votre vente sereinement, recevoir des acheteurs sérieux, et gagner du temps sur votre passage chez le notaire.
                        </p>
                        <div className="hidden sm:flex flex-column justify-start gap-3">
                            <div className="flex items-center gap-2 relative flex-[0_0_auto]">

                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0 text-lg">🥳 100% gratuit, pas de com pas de frais d'agence</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap gap-3 items-center mt-4">
                            <Link to="/vendeur/onboard"
                                type="button"
                                className="box-content  no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Démarrer, c'est gratuit
                                <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                            </Link>
                            <a href="#section1" className="text-slate-800">Comment ça marche ?</a>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 p-0 lg:p-5 bg-slate-200 rounded-lg">
                        <div className="overflow-hidden">
                            <img className="w-full rounded-lg" src={MainIllu} alt="Vendez seul comme un pro" />
                        </div>
                    </div>
                </section>

                {/** ccm bis */}
                <div className="bg-white py-16">
                    <p className="text-center text-4xl font-extrabold tracking-tight text-slate-900">Démarrez votre vente en un instant</p>
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto mt-16 max-w-2xl  lg:max-w-none">
                            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                                <div className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-gray-900">
                                        <span className="inline-flex items-center rounded-full bg-emerald-100 p-4 text-sm font-medium text-emerald-600">1</span>
                                        Ajoutez votre annonce et partagez votre lien
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-600">
                                        <p className="flex-auto">
                                            Publiez votre annonce sur les plateformes de diffusion (seloger, pap...etc), et obtenez votre lien Clotere certifiée pour le partager à vos acheteurs. Ne perdez plus de temps à répondre toujours aux mêmes questions, envoyez votre lien Clotere.
                                        </p>
                                    </dd>
                                </div>
                                <div className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-gray-900">
                                        <span className="inline-flex items-center rounded-full bg-indigo-100 p-4 text-sm font-medium text-indigo-600">2</span>
                                        Obtenez instantanément la liste des documents obligatoires
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-600">
                                        <p className="flex-auto">
                                            La liste des documents à fournir à l'acheteur et au notaire est longue ! Clotere vous permet de débuter rapidement la récolte des documents et de les centraliser.
                                        </p>
                                    </dd>
                                </div>
                                <div className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-gray-900">
                                        <span className="inline-flex items-center rounded-full bg-orange-100 p-4 text-sm font-medium text-orange-600">3</span>
                                        Recevez une offre d'achat en ligne conforme et certifiée par un notaire
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-600">
                                        <p className="flex-auto">
                                            🥳 Félicitations, vous avez un acheteur intéressé ! Clotere vous permet de recevoir une offre d’achat conforme, qui contient toutes les informations indispensables pour sécuriser votre vente.
                                        </p>
                                    </dd>
                                </div>
                            </dl>
                            
                        </div>
                    </div>
                    <div className="text-center">
                    <Link to="/vendeur/onboard"
                                type="button"
                                className=" text-center box-content  no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Démarrer votre vente
                                <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                            </Link>
                    </div>
                </div>

                {/** ccm
                <div className="flex flex-row flex-wrap items-center justify-center gap-[30px] relative flex-[0_0_auto] mt-10 mb-10">
                    <div className="flex items-center justify-center gap-2 relative flex-[0_0_auto]">
                        <CheckIcon className="!relative !w-4 !h-4 text-emerald-600" />
                        <div className="relative w-fit mt-[-1.00px]  font-bold text-black text-md tracking-[0] leading-[normal]">
                            Ajoutez votre annonce (leboncoin, seloger, pap)
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-2 relative flex-[0_0_auto]">
                        <CheckIcon className="!relative !w-4 !h-4 text-emerald-600" />
                        <div className="relative w-fit mt-[-1.00px]  font-bold text-black text-md tracking-[0] leading-[normal]">
                            Obtenez votre lien certifié Clotere 👍
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-2 relative flex-[0_0_auto]">
                        <CheckIcon className="!relative !w-4 !h-4 text-emerald-600" />
                        <div className="relative w-fit mt-[-1.00px] font-bold text-black text-md tracking-[0] leading-[normal]">
                            Partagez le à tous les acheteurs 🚀
                        </div>
                    </div>
                </div>
                **/}


                {/** section feature */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="overflow-hidden bg-white py-9">
                        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
                                <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
                                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                                        <h2 className="text-base/7 font-semibold text-indigo-600">Gagnez du temps</h2>
                                        <p className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-900 leading-10 ">
                                            Regroupez au même endroit tout ce qui est important
                                        </p>
                                        <p className="mt-6 text-lg/8 text-gray-600">
                                            Montant des charges, copropriété, performance énergétique, diagnostics tout y est !
                                            Ne perdez plus de temps à répondre toujours aux mêmes questions, envoyez votre lien Clotere.
                                        </p>
                                        <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">

                                            <div className="relative pl-9">
                                                <dt className="inline font-semibold text-gray-900">
                                                    <CheckIcon className="absolute left-1 top-1 size-5 text-indigo-600" aria-hidden="true" />
                                                    Et en plus, on vous donne la liste des documents à fournir pour votre passage chez le notaire.
                                                </dt>{' '}
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                                <div className="sm:px-6 lg:px-0">
                                    <img className="w-full rounded-lg" src={Centraliser} alt="trouver un notaire pour votre achat" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/** section feature */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="overflow-hidden bg-white py-9">
                        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
                                <img className="w-full rounded-lg" src={Visite} alt="trouver un notaire pour votre achat" />
                                <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
                                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                                        <h2 className="text-base/7 font-semibold text-indigo-600">Gagnez du temps</h2>
                                        <p className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
                                            Gérez vos visites comme un pro
                                        </p>
                                        <p className="mt-6 text-lg/8 text-gray-600">
                                            Indiquez simplement vos disponibilités pour les visites.
                                            Et pour vous faire gagner du temps, Clotere vérifie si le dossier de l'acheteur est adapté à votre bien.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                {/** section feature */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="overflow-hidden bg-white py-9">
                        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
                                <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
                                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                                        <h2 className="text-base/7 font-semibold text-indigo-600"> Documents conformes et sécurisés</h2>
                                        <p className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-900 leading-10">
                                            Obtenez une offre d’achat en ligne et sécurisée
                                        </p>
                                        <p className="mt-6 text-lg/8 text-gray-600">
                                            🥳 Félicitations, vous avez un acheteur intéressé ! Clotere vous permet de recevoir une offre d’achat conforme juridiquement et de la faire signer en ligne, sans frais.
                                        </p>
                                        <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">

                                            <div className="relative pl-9">
                                                <dt className="inline font-semibold text-gray-900">
                                                    <LockClosedIcon className="absolute left-1 top-1 size-5 text-indigo-600" aria-hidden="true" />
                                                    L'offre d'achat Clotere contient toutes les informations indispensables pour sécuriser votre vente.
                                                </dt>{' '}
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                                <div className="sm:px-6 lg:px-0">
                                    <img className="w-full rounded-lg" src={Offre} alt="trouver un notaire pour votre achat" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/** section feature */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="overflow-hidden bg-white py-9">
                        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
                                <div className="sm:px-6 lg:px-0">
                                    <img className="w-full rounded-lg" src={Notaire} alt="trouver un notaire pour votre achat" />
                                </div>
                                <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
                                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                                        <h2 className="text-base/7 font-semibold text-indigo-600">Votre compromis rapidement</h2>
                                        <p className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
                                            Transférez votre vente en 1 clic chez le notaire
                                        </p>
                                        <p className="mt-6 text-lg/8 text-gray-600">
                                            Gagnez en moyenne 20 jours sur la signature de l’acte de vente authentique grâce aux notaires Clotere. Suivez l’avancement facilement, échangez en ligne ou en présentiel, et gagnez du temps !
                                        </p>
                                        <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">

                                            <div className="relative pl-9">
                                                <dt className="inline font-semibold text-gray-900">
                                                    <CheckIcon className="absolute left-1 top-1 size-5 text-indigo-600" aria-hidden="true" />
                                                    Clotere connecte vendeur, acheteur et notaire pour une transaction fluide, transparente et rapide.
                                                </dt>{' '}
                                            </div>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                {/** gratuite */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <Container>
                        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                            <div className="absolute inset-0 -z-10 overflow-hidden">
                                <svg
                                    aria-hidden="true"
                                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                                >
                                    <defs>
                                        <pattern
                                            x="50%"
                                            y={-1}
                                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                                            width={200}
                                            height={200}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                                        </pattern>
                                    </defs>
                                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                                        <path
                                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                                            strokeWidth={0}
                                        />
                                    </svg>
                                    <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
                                </svg>
                            </div>
                            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-10">

                                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                                    <div className="lg:pr-4">
                                        <div className="lg:max-w-lg">
                                            <p className="text-base font-semibold leading-7 text-indigo-600">100% gratuit</p>
                                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl leading-10">La gratuité, c’est une règle, <span className="font-bold text-indigo-800">pas une promo</span>.</h1>
                                            <p className="mt-6 text-lg leading-8 text-gray-700">
                                                Chez Clotere, <span className="font-bold text-teal-600">simplifier votre vente est notre priorité</span>, sans frais pour vous. Ce sont nos notaires partenaires qui financent le service.
                                                <br />Profitez d’un accompagnement expert, de la gestion des documents à la signature en ligne, gratuitement. Et ça, c’est garanti.
                                            </p>
                                        </div>
                                        <div class="flex flex-col gap-3 mt-5">
                                            <div className="flex -space-x-2 overflow-hidden">
                                                <img
                                                    alt="vendez votre bien immobilier seul, comme un pro"
                                                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                                />
                                                <img
                                                    alt="vendez votre bien immobilier seul, comme un pro"
                                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                                />
                                                <img
                                                    alt="vendre sans agent immobilier"
                                                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                                />
                                                <img
                                                    alt="vendre sans agent immobilier"
                                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                                />
                                            </div>
                                            <div className="">
                                                <p className="text-lg">Notre équipe vous accompagne tout au long de votre vente. </p>
                                            </div>

                                        </div>
                                        <Link to="/vendeur/onboard"
                                            type="button"
                                            className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Créer mon dossier (gratuitement)
                                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                        </Link>
                                    </div>
                                    <div className="lg:pr-4">
                                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                                            <ul role="list" className="mt-8 space-y-8 text-gray-600 text-[20px]">
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                                                    <span>
                                                        Conçu pour vendre plus vite 🚀
                                                    </span>
                                                </li>
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                                                    <span>
                                                        Conseillers réactifs et disponibles 7j/7 👍
                                                    </span>
                                                </li>
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                                                    <span>
                                                        100% gratuit, sans frais cachés 🙂
                                                    </span>
                                                </li>
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                                                    <span>
                                                        Conforme et sécurisé 💪
                                                    </span>
                                                </li>
                                                <li className="flex gap-x-3">
                                                    <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-teal-600" />
                                                    <span>
                                                        Un gain de temps et de la sérénité 🧘
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Container>
                </section>

                {/** section ccm 
                <section id='section1' className="mt-10 mb-10">
                    <Container>
                        <div class="container mx-auto px-[15px] h-full">
                            <div class="flex flex-col justify-between items-start gap-[30px]">
                                <div class="py-2 px-4 rounded-[20px] text-center bg-emerald-500/30 text-emerald-500">
                                    Vendre son bien immobilier seul
                                </div>
                                <h3 class="text-black leading-[48px] text-[40px] lg:leading-[50px] lg:text-[45px] font-bold sm:max-w-[60%]">Vendre avec Clotere, comment ça marche ?</h3>

                                <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Publiez votre annonce</h3>
                                            <p>Publiez votre annonce de vente sur les plateformes de vente entre particuliers comme leboncoin, seloger...etc.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <LinkIcon className="size-6" />
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Créez votre lien Clotere</h3>
                                            <p>Créez simplement votre annonce Clotere, en répondant à quelques questions et en ajoutant vos documents de vente.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <ShareIcon className="size-6" />
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Partagez votre lien</h3>
                                            <p>Envoyez votre lien Clotere à tous les acheteurs potentiels. Ils trouveront forcément les réponses à leurs questions.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Trouvez un acheteur</h3>
                                            <p>Gérez simplement votre vente avec Clotere et recevez votre offre d'achat conforme directement en ligne.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/vendeur/onboard"
                                type="button"
                                className="mt-5 box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Démarrer, c'est gratuit
                                <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                            </Link>
                        </div>
                    </Container>
                </section>
                **/}

                {/** section Blog */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="py-24 sm:py-20 bg-gradient-to-r from-teal-200 to-teal-500  rounded-lg">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl text-center">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Gagnez du temps avec les astuces de vente Clotere </h2>

                            </div>
                            <div className="mx-auto mt-5 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                                {posts.map((post) => (
                                    <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
                                        <div className="relative w-full">
                                            <img
                                                alt=""
                                                src={post.imageUrl}
                                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                            />
                                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="max-w-xl">
                                            <div className="mt-8 flex items-center gap-x-4 text-sm">
                                                <p
                                                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                                                >
                                                    {post.category.title}
                                                </p>
                                            </div>
                                            <div className="group relative">
                                                <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                                                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                                                        <span className="absolute inset-0" />
                                                        {post.title}
                                                    </a>
                                                </h3>
                                                <p className="hidden sm:block mt-0 text-md leading-6 text-gray-600">{post.description}</p>
                                                <a href={post.href} target='blank' className="btn-main no-underline">Lire l'article</a>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                            </div>
                            <div className="mx-auto max-w-2xl text-center">
                                <Link to="/fiches-pratiques-notaire"
                                    type="button"
                                    className="mt-5 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Consultez nos fiches pratiques Notaire et immobilier
                                    <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                </Link>
                            </div>


                        </div>

                    </div>

                </section>




            </Container>
            <Faq />


            <Footer />
        </>
    );
}

export default Vendeur;